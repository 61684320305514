import React from 'react'

const CableIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='38'
    strokeWidth='0.5'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path
      d='M34.602 10.958V2.885c0-.876-.226-1.582-1.102-1.582s-1.11.706-1.11 1.582v8.073h-7.803V2.885c0-.876-.194-1.582-1.07-1.582s-1.081.706-1.081 1.582v8.073H20.66a1.58 1.58 0 0 0-1.582 1.582v7.282c0 4.66 3.358 8.717 8.412 9.314.249 3.961-3.566 6.524-6.838 6.524-3.474 0-7.283-3.256-7.038-7.121V7.065C13.614 3.187 11.144 0 7.181 0S0 3.156 0 7.034v21.474c0 .876.317 1.524 1.193 1.524s1.18-.648 1.18-1.524V7.034c0-2.133 1.526-4.912 4.808-4.912s4.01 2.787 4.01 4.912v21.504c0 5.22 4.25 9.365 9.469 9.365 5.017 0 9.092-3.531 9.365-8.767C34.529 28.877 38 24.221 38 19.822V12.54c0-.869-.706-1.582-1.582-1.582h-1.816zM35.8 19.76c0 4.044-3.24 7.336-7.219 7.336h-.062c-3.98 0-7.219-3.292-7.219-7.336v-6.664h14.491v6.664h.009z'
      fill='#fff'
    />
  </svg>
)

export default CableIcon
