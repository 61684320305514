import React, { useEffect } from 'react'
import { Equivalence } from './components/Equivalence/Equivalence'
import { WelcomeBackSummary } from './components/WelcomeBackSummary/WelcomeBackSummary'
import Centering from '../../layout/Centering/Centering'
import { InfoModal } from '../Result/components/InfoIcon'
import './WelcomeBack.scss'

export const WelcomeBack = () => {
  const userData = JSON.parse(window.localStorage.getItem('userResponse'))
  const name = userData?.displayName
  const firstName = name?.split(' ')[0]

  // fade-in effect when load the page
  useEffect(() => {
    const element = document.querySelector('.welcome-back__content')
    setTimeout(() => element.classList.add('welcome-back__fade-in'), 100)
  }, [])

  return (
    <Centering addClass='result' hideFooterActions>
      <div className='welcome-back__content'>
        <div className='welcome-back__header'>
          <h1 className='welcome-back__header--title'>Welcome back, {firstName}!</h1>
          <div className='welcome-back__header--subtitle'>
            <h3>Your last recorded carbon footprint</h3>
            <InfoModal />
          </div>
        </div>
        <WelcomeBackSummary />
        <Equivalence totalEmissions={userData?.TotalEmissions} />
      </div>
    </Centering>
  )
}

export default WelcomeBack
