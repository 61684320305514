import React from 'react'

const BikeIcon = ({ pathFill }) => (
  <svg
    data-testid='BikeIcon'
    viewBox='0 0 79 52'
    width='79'
    height='52'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <defs>
      <path id='prefix__a' d='M0 0L78.073 0 78.073 52.016 0 52.016z' />
    </defs>
    <g>
      <path
        d='M61.974 50c-7.732-.025-13.98-6.314-13.954-14.046.019-5.928 3.77-11.2 9.364-13.164l3.59 13.47c.12.45.535.756 1 .74.086.01.174.01.26 0 .539-.12.878-.655.757-1.194-.005-.023-.01-.044-.017-.066l-3.66-13.48c.876-.173 1.767-.26 2.66-.26 7.732 0 14 6.268 14 14 0 7.731-6.268 14-14 14zm-23.56-15h-6.44c-.354-5.343-3.356-10.155-8-12.82L29.594 11h23.59l-14.77 24zm-20.82 0l5.51-11c3.953 2.321 6.519 6.43 6.87 11h-12.38zm-1.62 15c-7.732 0-14-6.269-14-14 0-7.732 6.268-14 14-14 1.836-.002 3.655.362 5.35 1.07l-6.24 12.48c-.25.493-.05 1.095.443 1.343.139.07.292.106.447.107h14c-.525 7.34-6.642 13.02-14 13zm46-30c-1.071 0-2.14.106-3.19.32l-2.81-10.45V2h8c.553 0 1-.448 1-1s-.447-1-1-1h-9c-.553 0-1 .448-1 1v8h-24V6h5c.552 0 1-.448 1-1s-.448-1-1-1h-12c-.552 0-1 .448-1 1s.448 1 1 1h5v3.76l-5.75 11.51c-8.142-3.435-17.526.38-20.961 8.522-3.435 8.142.38 17.526 8.522 20.961 8.142 3.435 17.526-.38 20.96-8.522.703-1.662 1.118-3.43 1.229-5.23h7c.348-.003.669-.185.85-.48l14.79-24 2.25 8.35c-8.356 2.874-12.8 11.978-9.925 20.333 2.875 8.357 11.979 12.8 20.335 9.925 8.356-2.874 12.8-11.979 9.925-20.334C74.965 24.303 68.839 19.96 61.974 20z'
        mask='url(#prefix__b)'
        fill={pathFill}
        strokeWidth='0.1'
      />
    </g>
  </svg>
)

BikeIcon.defaultProps = {
  pathFill: 'white',
}

export default BikeIcon
