import React from 'react'
import { useHistory } from 'react-router-dom'
import Centering from '../../layout/Centering/Centering'
import Jumbotron from '../../components/Jumbotron/Jumbotron'
import Button from '../../components/Button/Button'
import Strings from '../../constants/Strings'

import './AddressNotFound.scss'
import { IconComponent } from '../../utils/iconType'

const AddressNotFound = () => {
  const history = useHistory()

  const handleNextButton = () => {
    history.push('/')
  }

  return (
    <Centering hideFooterActions hideHeaderMenu>
      <div className='address-not-found--icon'>{IconComponent('addressnotfound')}</div>
      <Jumbotron title={Strings.ADDRESS_NOT_FOUND_TITLE} addClass='address-not-found--jumbotron'>
        {Strings.ADDRESS_NOT_FOUND_DESCRIPTION}
      </Jumbotron>
      <Button onClick={handleNextButton}>{Strings.ADDRESS_NOT_FOUND_BACK_BUTTON}</Button>
    </Centering>
  )
}

export default AddressNotFound
