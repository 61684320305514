import React, { useState, useContext, useEffect } from 'react'
import Strings from '../../../../constants/Strings/index'

import Heading from '../../../../components/Heading/Heading'
import Question from '../../../../components/new-components/Question/Question'
import ButtonList from '../../../../components/new-components/ButtonList/ButtonList'
import OptionButtons from '../../../../components/new-components/OptionButtons/OptionButtons'
import StoreContext from '../../../../providers/StoreContext'
import { homeHeatingAnswesChanged } from '../../../../store/actions'

const HouseHeatingSection = ({ setDisableButton }) => {
  const {
    state: {
      homeConsumption: { houseHeating },
    },
    dispatch,
  } = useContext(StoreContext)

  const { HOME_CONSUMPTION } = Strings
  const { HOUSE_HEATING } = HOME_CONSUMPTION.SECTIONS

  const AOptions = HOUSE_HEATING.A.OPTIONS.map((option) => ({
    label: option,
    value: option,
  }))

  const checkqAResponse = (res) => res && res.qHeatingDevice === 'I don’t use'

  const [responses, setResponses] = useState({
    ...houseHeating,
  })

  const handlerOnChange = (event) => {
    let changes = { [event.currentTarget.name]: event.currentTarget.value }
    if (checkqAResponse(changes)) {
      changes = {
        ...changes,
        qMonthsUsingDevice: '',
        qhoursAtDayUsingDevice: '',
      }
    }
    assignResponses(changes)
    homeHeatingAnswesChanged(changes, dispatch)
  }

  const assignResponses = (response) => {
    setResponses({
      ...responses,
      ...response,
    })
  }

  const disabledNextButton = () => {
    let disabled = true
    if (!houseHeating.qHeatingDevice) disabled = true
    if (checkqAResponse(houseHeating)) disabled = false
    if (!checkqAResponse(houseHeating) && houseHeating.qMonthsUsingDevice && houseHeating.qhoursAtDayUsingDevice)
      disabled = false
    setDisableButton(disabled)
  }

  useEffect(() => {
    const element = document.querySelector('.house-heating-section__content')
    element.classList.add('house-heating-section__fade-in')
  }, [])

  useEffect(() => {
    disabledNextButton()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responses])

  const ContentAWithValue = () => {
    return (
      <div className='house-heating-section__sub-content'>
        <Question title={HOUSE_HEATING.AB.TITLE} description={HOUSE_HEATING.AB.DESCRIPTION}>
          <ButtonList
            name='qMonthsUsingDevice'
            counter={HOUSE_HEATING.AB.OPTIONS.COUNTER_OPTIONS}
            initValue={1}
            valueSelected={responses.qMonthsUsingDevice ?? '1'}
            handlerValueSelected={handlerOnChange}
          ></ButtonList>
        </Question>
        <Question title={HOUSE_HEATING.AC.TITLE} description={HOUSE_HEATING.AC.DESCRIPTION}>
          <ButtonList
            name='qhoursAtDayUsingDevice'
            counter={HOUSE_HEATING.AC.OPTIONS.COUNTER_OPTIONS}
            initValue={1}
            valueSelected={responses.qhoursAtDayUsingDevice ?? '1'}
            handlerValueSelected={handlerOnChange}
          ></ButtonList>
        </Question>
      </div>
    )
  }

  return (
    <div className='house-heating-section__content'>
      <Heading addClass='house-heating-section__title' level='h1'>
        {HOME_CONSUMPTION.SECTIONS.HOUSE_HEATING.TITLE}
      </Heading>
      <p className='house-cooling-section__description'>{HOUSE_HEATING.DESCRIPTION}</p>

      <form id='houseHeatingForm' className='house-heating-section__form'>
        <Question title={HOUSE_HEATING.A.TITLE} description={HOUSE_HEATING.A.DESCRIPTION}>
          <OptionButtons
            name='qHeatingDevice'
            row
            value={responses.qHeatingDevice ?? 'Electronic heating'}
            handlerOnChange={handlerOnChange}
            options={AOptions}
            limitedWidth={true}
          />
        </Question>

        {!checkqAResponse(responses) && responses.qHeatingDevice && responses.qHeatingDevice && <ContentAWithValue />}
      </form>
    </div>
  )
}

export default HouseHeatingSection
