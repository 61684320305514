import {
  SELECTION_VEHICLES,
  AUTHENTICATED,
  SELECTION_CITY,
  VEHICLE_TRIP_DATA,
  ADD_SECOND_TRIP,
  REMOVE_SECOND_TRIP,
  ESTIMATE_CARBON_FOOTPRINT,
  SURVEY_COMPLETED,
  WEEKLY_JOURNEYS_MADE_DISABLED,
  RESET_STORE,
  INITIAL_STATE,
  RESET_MAGIC_STORE,
  SENDING_REQUEST,
  ERROR_REQUEST,
  SELECTION_COUNTRY,
  RESET_COUNTRY,
  HOME_CONSUMPTION_CURRENT_SECTION,
  HOME_OFFICE_ANSWERS,
  HOME_HEATING_ANSWERS,
  HOUSE_COOLING_GLOBAL_ANSWERS,
  DEVICES_ENERGY_ANSWERS,
  HOUSE_COOLING_INDIA_ANSWERS,
  ESTIMATE_WORK_CARBON_FOOTPRINT,
  WORK_CARBON_FOOTPRINT_EQUIVALENCES,
  ELECTRICITY_USE_ANSWERS,
} from './types'
import {
  setVehiclesSelectedData,
  tripOptionsChange,
  addDefaultSecondTrip,
  removeSecondTrip,
  estimateCarbonFootprint,
  updateWeeklyJourneysMade,
} from './helpers'
import { initialState, magicState } from '../utils/generalHelper'

export default function reducer(state, action) {
  switch (action.type) {
    case INITIAL_STATE: {
      return {
        ...initialState,
      }
    }
    case AUTHENTICATED: {
      return { ...state, isAuthenticated: action.payload }
    }
    case SURVEY_COMPLETED: {
      return { ...state, isSurveyComplete: action.payload }
    }
    case SELECTION_VEHICLES: {
      return {
        ...state,
        vehiclesSelected: action.payload,
        vehiclesSelectedData: setVehiclesSelectedData(action.payload),
      }
    }
    case WEEKLY_JOURNEYS_MADE_DISABLED:
      return {
        ...state,
        vehiclesSelectedData: updateWeeklyJourneysMade(action.payload, state.vehiclesSelectedData),
      }
    case VEHICLE_TRIP_DATA:
      return {
        ...state,
        vehiclesSelectedData: tripOptionsChange(action.payload, state.vehiclesSelectedData),
      }
    case ESTIMATE_CARBON_FOOTPRINT:
      /* eslint-disable no-case-declarations */
      const updateState = estimateCarbonFootprint(state.vehiclesSelectedData, state.countrySelected)

      return {
        ...state,
        ...updateState,
        requestStatus: {
          ...state.requestStatus,
          sending: true,
        },
      }
    case ADD_SECOND_TRIP: {
      return {
        ...state,
        vehiclesSelectedData: addDefaultSecondTrip(action.payload, state.vehiclesSelectedData),
      }
    }
    case REMOVE_SECOND_TRIP: {
      return {
        ...state,
        vehiclesSelectedData: removeSecondTrip(action.payload, state.vehiclesSelectedData),
      }
    }
    case RESET_COUNTRY:
      return {
        ...state,
        countrySelected: {
          ...initialState.countrySelected,
        },
      }
    case SELECTION_CITY:
      return {
        ...state,
        countrySelected: {
          ...state.countrySelected,
          city: action.payload,
        },
      }
    case SELECTION_COUNTRY:
      return {
        ...state,
        countrySelected: {
          ...state.countrySelected,
          country: action.payload,
        },
      }
    case SENDING_REQUEST:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          sending: true,
        },
      }
    case ERROR_REQUEST:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          sending: false,
        },
      }
    case RESET_STORE:
      return {
        ...initialState,
        totalGHGEmissions: state.totalGHGEmissions,
        totalWFHEmissions: state.totalWFHEmissions,
        equivalences: state.equivalences,
      }
    case RESET_MAGIC_STORE:
      return {
        ...magicState,
      }
    case HOME_CONSUMPTION_CURRENT_SECTION:
      return {
        ...state,
        homeConsumption: {
          ...state.homeConsumption,
          currentSection: action.payload,
        },
      }
    case HOME_OFFICE_ANSWERS:
      /* eslint-disable no-case-declarations */
      const qWorkingFullTime = state.homeConsumption.homeOffice.qWorkingFullTime
      return {
        ...state,
        totalGHGEmissions: qWorkingFullTime === 'yes' ? 0 : state.totalGHGEmissions,
        homeConsumption: {
          ...state.homeConsumption,
          homeOffice: {
            ...state.homeConsumption.homeOffice,
            ...action.payload,
          },
        },
      }
    case DEVICES_ENERGY_ANSWERS:
      return {
        ...state,
        homeConsumption: {
          ...state.homeConsumption,
          devicesEnergy: {
            ...state.homeConsumption.devicesEnergy,
            ...action.payload,
          },
        },
      }
    case HOME_HEATING_ANSWERS:
      return {
        ...state,
        homeConsumption: {
          ...state.homeConsumption,
          houseHeating: {
            ...state.homeConsumption.houseHeating,
            ...action.payload,
          },
        },
      }
    case HOUSE_COOLING_GLOBAL_ANSWERS:
      return {
        ...state,
        homeConsumption: {
          ...state.homeConsumption,
          houseCooling: {
            ...state.homeConsumption.houseCooling,
            global: {
              ...state.homeConsumption.houseCooling.global,
              ...action.payload,
            },
          },
        },
      }
    case HOUSE_COOLING_INDIA_ANSWERS:
      return {
        ...state,
        homeConsumption: {
          ...state.homeConsumption,
          houseCooling: {
            ...state.homeConsumption.houseCooling,
            india: {
              ...state.homeConsumption.houseCooling.india,
              ...action.payload,
            },
          },
        },
      }
    case ESTIMATE_WORK_CARBON_FOOTPRINT:
      return {
        ...state,
        ...action.payload,
      }
    case WORK_CARBON_FOOTPRINT_EQUIVALENCES:
      return {
        ...state,
        equivalences: {
          ...state.equivalences,
          ...action.payload,
        },
      }
    case ELECTRICITY_USE_ANSWERS:
      return {
        ...state,
        homeConsumption: {
          ...state.homeConsumption,
          electricityUse: {
            ...state.homeConsumption.electricityUse,
            ...action.payload,
          },
        },
      }
    default:
      return state
  }
}
