import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import './HomeConsumption.scss'

import Strings from '../../constants/Strings'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

import { homeConsumptionSections } from '../../utils/generalHelper'

import HomeOfficeSection from './sections/HomeOffice/HomeOffice'
import DevicesEnergySection from './sections/DevicesEnergy/DevicesEnergy'
import HouseCoolingSection from './sections/HouseCooling/HouseCooling'
import HouseHeatingSection from './sections/HouseHeating/HouseHeating'
import ElectricityUseSection from './sections/ElectricityUse/ElectricityUse'
import Centering from '../../layout/Centering/Centering'
import StoreContext from '../../providers/StoreContext'
import Loading from '../Loading/Loading'
import {
  errorRequest,
  estimateWorkCarbonFootprint,
  homeConsumptionCurrentSection,
  sendingRequest,
  workCarbonFootprintEquivalencesChanged,
} from '../../store/actions'
import { createProject } from '../../auth/auth'
import calculateCarbonFootprintEquivalences from '../../utils/workCarbonFootprintEquivalences'
import { getTotalEmission } from '../../utils/carbonEmission'

const HomeConsumption = () => {
  const {
    CONTINUE_BUTTON,
    CARBON_FOOTPRINT_BUTTON,
    HOME_CONSUMPTION: {
      SECTIONS: { HOME_OFFICE },
    },
  } = Strings
  const {
    QUESTIONS: {
      WORKING_FULL_TIME: {
        OPTIONS: { YES, NO },
      },
    },
  } = HOME_OFFICE

  const {
    state,
    state: {
      homeConsumption: { homeOffice },
      requestStatus: { sending },
    },
    dispatch,
  } = useContext(StoreContext)
  const qWorkingFullTime = homeOffice?.qWorkingFullTime
  const userData = JSON.parse(localStorage.getItem('userResponse'))

  const [section, setSection] = useState(0)
  const [disableButton, setDisableButton] = useState(true)
  const history = useHistory()
  const { addToast } = useToasts()

  const nextPage = () => {
    let page = section + 1
    setSection(page)
    homeConsumptionCurrentSection(page, dispatch)
  }
  const prevPage = () => {
    let page = section - 1
    setSection(page)
    homeConsumptionCurrentSection(page, dispatch)
  }

  const savingCarbonFootprintData = async (sending) => {
    if (sending) {
      try {
        await createProject(state)
        history.push('/result')
      } catch (err) {
        errorRequest(dispatch)
        addToast('Could you please try again, something happened', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  }

  useEffect(() => {
    const newState = {
      ...state,
      displayName: userData.displayName,
      totalWFHEmissions: userData.totalWFHEmissions,
    }
    savingCarbonFootprintData(sending)
    localStorage.setItem('userResponse', JSON.stringify(newState))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sending, state, qWorkingFullTime])

  const handleBackButton = () => {
    window.scrollTo(0, 0)
    section === 0 ? history.push('/country-selection') : prevPage()
  }

  const handleNextButton = async () => {
    window.scrollTo(0, 0)
    if (section === 4) {
      let totalWFHEmissions = getTotalEmission(state)
      estimateWorkCarbonFootprint({ totalWFHEmissions }, dispatch)

      if (qWorkingFullTime === YES.value) {
        const e = calculateCarbonFootprintEquivalences(totalWFHEmissions)
        workCarbonFootprintEquivalencesChanged(e, dispatch)
        window.scrollTo(0, 0)
        sendingRequest(dispatch)
      }
      if (qWorkingFullTime === NO.value) {
        history.push('/trips')
      }
    } else {
      nextPage()
    }
  }

  return sending ? (
    <Loading />
  ) : (
    <Centering
      addClass='home-consumption__centering'
      backFooterButton={{
        handleOnClick: () => {
          handleBackButton()
        },
      }}
      nextFooterButton={{
        disable: disableButton,
        handleOnClick: () => {
          handleNextButton()
        },
        text: section === 4 && qWorkingFullTime === YES.value ? CARBON_FOOTPRINT_BUTTON : CONTINUE_BUTTON,
      }}
    >
      <div className='home-consumption__page'>
        <section className='home-consumption__breadcrumb-zone'>
          <Breadcrumb
            addClass='home-consumption__breadcrumb-item'
            vehicles={homeConsumptionSections}
            activeVehicle={homeConsumptionSections[section]}
          />
        </section>
        <section className='home-consumption__sections'>
          {section === 0 && <HomeOfficeSection setDisableButton={setDisableButton} />}
          {section === 1 && <DevicesEnergySection setDisableButton={setDisableButton} />}
          {section === 2 && <HouseCoolingSection setDisableButton={setDisableButton} />}
          {section === 3 && <HouseHeatingSection setDisableButton={setDisableButton} />}
          {section === 4 && <ElectricityUseSection setDisableButton={setDisableButton} />}
        </section>
      </div>
    </Centering>
  )
}

export default HomeConsumption
