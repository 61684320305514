import React from 'react'

const ColdIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='41' height='40'>
    <g stroke='#fff' fill='none' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'>
      <circle cx='20.21' cy='20.054' r='3.769' />
      <path d='M20.363 15.73V1.625m-5.938 2.089l5.938 5.158m6.461-5.158l-5.938 5.158' />
      <path d='M20.24 24.24v14.105m5.938-2.089l-5.938-5.158m-6.462 5.158l5.938-5.158' />
      <g>
        <path d='M16.656 18.084L4.441 11.032m-1.16 6.187l7.436-2.563' />
        <path d='M6.461 2.089l5.938 5.158' transform='matrix(-.5 .866025 .866025 .5 10.902237 -.159657)' />
      </g>
      <g>
        <path d='M24.288 21.886l12.215 7.053m1.16-6.187l-7.436 2.563' />
        <path d='M6.461 2.089l5.938 5.158' transform='matrix(.5 -.866025 -.866025 -.5 30.041763 40.129604)' />
      </g>
      <g>
        <path d='M16.918 22.339L4.703 29.392m4.778 4.098l1.498-7.721' />
        <path d='M6.461 2.089l5.938 5.158' transform='matrix(.5 .866025 .866025 -.5 -1.758643 18.200316)' />
      </g>
      <g>
        <path d='M24.026 17.631l12.215-7.052M31.463 6.48l-1.498 7.721' />
        <path d='M6.461 2.089l5.938 5.158' transform='matrix(-.5 -.866025 -.866025 .5 42.702643 21.76963)' />
      </g>
    </g>
  </svg>
)

export default ColdIcon
