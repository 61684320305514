import React, { useReducer, useEffect } from 'react'
import reducer from '../store/reducer'
import StoreContext from './StoreContext'
import { initialState } from '../utils/generalHelper'

function StoreProvider({ children }) {
  const beKindLocalStorage = JSON.parse(localStorage.getItem('beKindWithThisWorld'))
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...beKindLocalStorage,
  })

  useEffect(() => {
    localStorage.setItem('beKindWithThisWorld', JSON.stringify(state))
  }, [state])

  const value = {
    state,
    dispatch,
  }

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export default StoreProvider
