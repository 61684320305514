import React from 'react'
import Modal from 'react-modal'
import { ModalResultInfoIcon } from '../../../components/Icons'
import XIcon from '../../../components/Icons/XIcon/XIcon'
import Image from './ico.png'
import './InfoIcon.scss'

Modal.setAppElement('#root')

export const InfoModal = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <button className='buttonInfoIcon' onClick={handleOpen}>
        <span className='tooltip'>What is a carbon footprint?</span>
        <ModalResultInfoIcon />
      </button>
      <Modal
        isOpen={open}
        portalClassName='modal-portal'
        onClose={handleClose}
        className='InfoModal__Content'
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '1000',
          },
        }}
      >
        <div className='InfoModal__Content__whiteBoard'>
          <button className='InfoModal__Content__modal-button' onClick={handleClose}>
            <XIcon />
          </button>
          <div className='InfoModal__Content__whiteBoard--container'>
            <img className='co2icon' src={Image} alt='Co2image' />
            <h1 className='InfoModal__Content__whiteBoard--container__title'>What is carbon footprint?</h1>
            <p className='InfoModal__Content__whiteBoard--container__text'>
              {' '}
              A carbon footprint is the total amount of greenhouse gas emissions that come from the production, use and
              end-of-life of a product or service. It includes carbon dioxide — the gas most commonly emitted by humans
              — and others, including methane, nitrous oxide, and fluorinated gases, which trap heat in the atmosphere,
              causing global warming. Usually, the bulk of an individual’s carbon footprint will come from
              transportation, housing and food.{' '}
            </p>

            <h6 className='InfoModal__Content__whiteBoard--container__footer'>
              Source:
              <br></br>
              Livia Albeck-Ripka. How to Reduce Your Carbon Footprint. The New York Times
            </h6>
          </div>
        </div>
      </Modal>
    </div>
  )
}
