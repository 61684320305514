import React from 'react'

const CarIcon = () => (
  <svg viewBox='0 0 79 48' width='79' height='48' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      <path id='prefix__a' d='M0 0L78.007 0 78.007 47.963 0 47.963z' />
    </defs>
    <g>
      <path
        strokeWidth='0.1'
        d='M76.007 38h-7.06c-.573-4.937-5.04-8.476-9.977-7.902-4.15.481-7.422 3.754-7.903 7.902h-28.12c-.573-4.937-5.04-8.476-9.978-7.902-4.148.481-7.42 3.754-7.902 7.902h-3.06V25.12l5.21-20.85C7.552 2.936 8.751 2 10.127 2h2.6l-4.44 17.76c-.076.3-.009.616.18.86.191.241.482.381.79.38h58.49l8.26 4.59V38zm-16 8c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7zm-46 0c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7zm22.37-44v17h-25.84l4.25-17h21.59zm2 0h12.69c.92-.002 1.79.418 2.36 1.14L65.947 19h-27.57V2zm39.12 22.13l-8.83-4.91L55.007 1.9C54.055.695 52.603-.005 51.067 0h-40.94c-2.295 0-4.295 1.563-4.85 3.79L.007 24.76c-.01.08-.01.16 0 .24v14c0 .552.448 1 1 1h4.06c.573 4.937 5.04 8.476 9.978 7.902 4.148-.48 7.42-3.754 7.902-7.902h28.12c.573 4.937 5.04 8.476 9.977 7.902 4.15-.48 7.422-3.754 7.903-7.902h8.06c.553 0 1-.448 1-1V25c0-.36-.195-.693-.51-.87z'
        mask='url(#prefix__b)'
      />
    </g>
  </svg>
)

export default CarIcon
