import React, { useContext, Suspense, useEffect, useRef } from 'react'
import Measure from '../../../components/Measure/Measure'
import Strings from '../../../constants/Strings'
import StoreContext from '../../../providers/StoreContext'
import { Chart } from 'react-google-charts'
import DonutChart from './DonutChart'
import { CircularProgress } from '@material-ui/core'
import emissionsData from '../../../constants/emissonsData.json'
import useScreenSize from '../../../utils/useScreenSize'

export const Summary = () => {
  const userData = JSON.parse(localStorage.getItem('userResponse'))
  const userTotalEmissions = parseInt(localStorage.getItem('userTotalEmissions'))

  const { countrySelected } = userData
  const country = countrySelected
    ? countrySelected.country
    : userData && userData.location
    ? userData.location.country
    : userData.countrySelected.country

  const {
    state: { totalGHGEmissions, totalWFHEmissions },
  } = useContext(StoreContext)

  const totalPreviousEmissions =
    !!userTotalEmissions && typeof userTotalEmissions === 'number'
      ? userTotalEmissions
      : userData.totalGHGEmissions + userData.totalWFHEmissions

  const globantEmissionsRef = useRef(0)
  const countryEmissionsRef = useRef(0)
  const emissionsDifference = useRef(totalGHGEmissions + totalWFHEmissions - totalPreviousEmissions)
  const mobileRef = useScreenSize()

  const {
    RESULT: {
      EMISSIONS: {
        GREAT_JOB_TITLE,
        GREAT_JOB_DESCRIPTION,
        DECREASED_EMISSIONS_BASE,
        DECREASED_EMISSIONS_TITLE,
        DECREASED_EMISSIONS_DESCRIPTION,
        INCREASED_EMISSIONS_TITLE,
        INCREASED_EMISSIONS_DESCRIPTION,
        EMISSIONLABEL,
        EMISSION_BAR_CHART,
        EMISSION_DONUT_CHART,
      },
    },
  } = Strings
  const [totalGloberPreviousEmissionKgCO2, previousResultDate] = [
    userData?.TotalEmissions ? userData.TotalEmissions.toFixed(2) : null,
    (totalWFHEmissions + totalGHGEmissions).toFixed(2),
    'July 2022',
  ]

  const hasIncreasedCarbonFoorptint = parseInt(emissionsDifference.current) > 0

  const messages = (previousEmissions, increasedCarbonFoorptint) => {
    if (!previousEmissions && !userData) {
      return {
        title: GREAT_JOB_TITLE,
        description: GREAT_JOB_DESCRIPTION,
      }
    }
    if (increasedCarbonFoorptint) {
      return {
        title: INCREASED_EMISSIONS_TITLE,
        description: `${DECREASED_EMISSIONS_BASE} ${emissionsDifference.current.toFixed(
          2
        )} ${INCREASED_EMISSIONS_DESCRIPTION}`,
      }
    } else {
      return {
        title: DECREASED_EMISSIONS_TITLE,
        description: `${DECREASED_EMISSIONS_BASE} ${Math.abs(
          emissionsDifference.current.toFixed(2)
        )} ${DECREASED_EMISSIONS_DESCRIPTION}`,
      }
    }
  }

  const { title, description } = messages(totalGloberPreviousEmissionKgCO2, hasIncreasedCarbonFoorptint)

  const barChartData = [
    [
      'Element',
      'Density',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
    [
      'Globant average ',
      parseInt(globantEmissionsRef.current),
      '#51B89F',
      parseInt(globantEmissionsRef.current) + 'kg',
    ],
    [
      `${country} average`,
      parseInt(countryEmissionsRef.current),
      '#86FFBD',
      parseInt(countryEmissionsRef.current) + 'kg',
    ],
    [
      'Your emissions ',
      (totalWFHEmissions + totalGHGEmissions).toFixed(0),
      '#84AAF8',
      `${(totalWFHEmissions + totalGHGEmissions).toFixed(2)}kg`,
    ],
  ]

  const barChartOptions =
    mobileRef.width < 772
      ? {
          title: '',
          width: '100%',
          height: '100%',
          border: 'none',
          bar: { groupWidth: '40%', height: '10%' },
          legend: 'none',
          slices: {
            0: { color: '#5D87DA' },
            1: { color: '#84AAF8' },
          },
          backgroundColor: 'transparent',
          hAxis: {
            textStyle: {
              color: 'white',
              fontSize: 10,
              fontName: 'Lato',
              textAnchor: 'start',
            },
            gridlines: {
              color: '#67c4ae',
              count: 10,
            },
            minValue: 0,
            chartArea: { width: '50%' },
          },
          vAxis: {
            textStyle: {
              color: 'white',
              fontSize: 10,
              textAnchor: 'start',
            },
            gridlines: {
              color: '#67c4ae',
              count: 3,
            },
            ticks: [{ v: 0, f: '0kg' }, ...barChartData.map((item) => ({ v: item[1], f: `${item[1]} kg` }))],
          },
        }
      : {
          title: '',
          width: '100%',
          height: '100%',
          border: 'none',
          bar: { groupWidth: '40%', height: '10%' },
          legend: 'none',
          slices: {
            0: { color: '#5D87DA' },
            1: { color: '#84AAF8' },
          },
          backgroundColor: 'transparent',
          hAxis: {
            textStyle: {
              color: 'white',
              fontSize: 14,
              fontName: 'Lato',
              textAnchor: 'start',
            },
            gridlines: {
              color: '#67c4ae',
              count: 10,
            },
            minValue: 0,
            chartArea: { width: '50%' },
            ticks: [ { v: 0, f: '0kg' },...barChartData.map((item) => {
              return { v: item[1], f: `${item[1]} kg` }
            })],
          },
          vAxis: {
            textStyle: {
              color: 'white',
              fontSize: 14,
              textAnchor: 'start',
            },
            gridlines: {
              color: '#67c4ae',
              count: 3,
            },
            ticks: [{ v: 0, f: '0kg' }, ...barChartData.map((item) => ({ v: item[1], f: `${item[1]} kg` }))],
          },
        }

  const donutChartData = [
    { color: '#AFCFFF', value: totalWFHEmissions, label: 'Home office' },
    { color: '#84AAF8', value: totalGHGEmissions, label: 'Trip (Commuting)' },
  ]
  const formatStringCo2 = Number(totalWFHEmissions + totalGHGEmissions).toFixed(0)
  const formatNumber = (number) => {
    if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'k'
    } else {
      return number.toString()
    }
  }

  useEffect(() => {
    const totalEmissions = Number(totalWFHEmissions + totalGHGEmissions)
    localStorage.setItem('userTotalEmissions', totalEmissions)
  }, [])

  const EmissionsDonutChart = () => {
    const qWorkingFullTime = userData.homeConsumption.homeOffice.qWorkingFullTime

    return (
      <div className='result__charts' style={{ height: 'auto' }}>
        <div className='result__charts__emissions-per-period'>
          <div className='result__charts__total-emissions'>
            {/*ABSTRAERRRR*/}
            <div className='donut-container'>
              <DonutChart data={qWorkingFullTime === 'no' ? donutChartData : [donutChartData[0]]} width={310} />
              <div className='donut-hole'>
                <div className='amount-co2e'>{formatNumber(formatStringCo2)}</div>
                <div className='text-co2e'>kg CO₂e </div>
              </div>
            </div>
          </div>
        </div>
        <div className='donut-bottom-legend'>{EMISSIONLABEL}</div>
      </div>
    )
  }

  const EmissionsBarChart = () => (
    <div className='result__charts'>
      <div className='result__charts__emissions-comparison'>
        <Chart
          chartType={mobileRef.width < 772 ? 'ColumnChart' : 'BarChart'}
          width={mobileRef.width < 772 ? '330px' : '400px'}
          height='400px'
          data={barChartData}
          options={barChartOptions}
        />
      </div>
    </div>
  )

  useEffect(() => {
    const globantEmissions =
      emissionsData.reduce((prev, current) => {
        return prev + current.emissions
      }, 0) / emissionsData.length
    const country = userData?.location?.country || userData.countrySelected.country

    const emissionsByCountry = emissionsData.filter((item) => item.country === country)
    const countryEmissions =
      emissionsByCountry.reduce((prev, current) => {
        return prev + current.emissions
      }, 0) / emissionsByCountry.length

    if (
      Boolean(globantEmissions) &&
      Boolean(countryEmissions) &&
      // eslint-disable-next-line use-isnan
      globantEmissions !== NaN &&
      // eslint-disable-next-line use-isnan
      countryEmissions !== NaN
    )
      globantEmissionsRef.current = globantEmissions
    countryEmissionsRef.current = countryEmissions
  }, [])

  return (
    <Suspense fallback={<CircularProgress style={{ color: '#ffffff' }} />}>
      <div className='result__summary'>
        <div className='result__banner-left'>
          <h3 className='result__charts__title'>{EMISSION_DONUT_CHART}</h3>
          <div className='emission__charts'>
            <EmissionsDonutChart />
            {/* these above should be optional depends of positive result */}
            <div className='result__banner-left-note-message'>
              <Measure
                addClass='result__value'
                emissionsDifference={emissionsDifference.current}
                totalGloberPreviousEmissionKgCO2={totalWFHEmissions}
                previousResultDate={previousResultDate}
              />
              <div className='result__banner-left-note-message__description-text-container'>
                <h1 className='result__banner-left-note-message__description-text-container__title'>{title} </h1>
                <p className='result__banner-left-note-message__description-text-container__description'>
                  {description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='banner-right-wrapper'>
          <div className='result__banner-right'>
            <h3 className='result__charts__title'>{EMISSION_BAR_CHART}</h3>
            <EmissionsBarChart />
          </div>
        </div>
      </div>
    </Suspense>
  )
}
