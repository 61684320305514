import React from 'react'

const MotorcycleIcon = () => (
  <svg viewBox='0 0 78 48' width='78' height='48' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      <path id='prefix__a' d='M0 0L78 0 78 48 0 48z' />
    </defs>
    <g>
      <path
        strokeWidth='0.1'
        d='M61 5.6v9.8c-2.706-.55-4.454-3.189-3.905-5.895C57.494 7.537 59.032 6 61 5.6zM32 20H19c-1.69.004-3.372.236-5 .69v-1.93c0-.972.788-1.76 1.76-1.76H32v3zm0 18h-6.06c-.573-4.938-5.04-8.476-9.978-7.902-4.148.481-7.42 3.753-7.902 7.902H2c.53-8.996 7.988-16.016 17-16h13v16zm-15 8c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7zm49-27c-3.093.012-6.051 1.266-8.21 3.48l-.57-6.88c1.295 1.217 3.004 1.896 4.78 1.9.553 0 1-.448 1-1v-12c0-.552-.447-1-1-1-2.183.003-4.238 1.023-5.56 2.76L56 .92c-.042-.52-.478-.922-1-.92h-9c-.553 0-1 .448-1 1s.447 1 1 1h8.08L56 24.69 45.51 38H34V16c0-.552-.448-1-1-1H15.76c-2.074.005-3.755 1.685-3.76 3.76v2.59C4.761 24.22.005 31.214 0 39c0 .552.448 1 1 1h7.06c.573 4.937 5.04 8.475 9.978 7.902 4.148-.48 7.42-3.754 7.902-7.902H46c.308.002.6-.139.79-.38l10.94-13.93s0-.06.05-.08c1.77-2.843 4.871-4.582 8.22-4.61 5.522 0 10 4.476 10 10 0 .552.447 1 1 1 .553 0 1-.448 1-1 0-6.628-5.373-12-12-12zm-1 27c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7zm0-16c-4.971 0-9 4.03-9 9 0 4.971 4.029 9 9 9s9-4.029 9-9c0-4.97-4.029-9-9-9z'
        mask='url(#prefix__b)'
      />
    </g>
  </svg>
)

export default MotorcycleIcon
