import React from 'react'

const ScooterIcon = () => (
  <svg width='78' height='48' viewBox='0 0 48 53' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        d='M30.89 0.5C30.6139 0.5 30.39 0.723858 30.39 1C30.39 1.27614 30.6139 1.5 30.89 1.5V0.5ZM38.751 1L39.2491 0.956598C39.2266 0.698257 39.0103 0.5 38.751 0.5V1ZM42 38.288L42.3594 38.6356C42.4601 38.5315 42.5107 38.3889 42.4981 38.2446L42 38.288ZM33.573 47V47.5C33.7085 47.5 33.8382 47.445 33.9324 47.3476L33.573 47ZM16.188 47L15.7767 47.2843C15.8701 47.4194 16.0238 47.5 16.188 47.5V47ZM0.730674 40.4387C0.498016 40.5875 0.429991 40.8967 0.578736 41.1293C0.727481 41.362 1.03667 41.43 1.26933 41.2813L0.730674 40.4387ZM42.783 0.5C42.5068 0.5 42.283 0.723858 42.283 1C42.283 1.27614 42.5068 1.5 42.783 1.5V0.5ZM47 1.5C47.2761 1.5 47.5 1.27614 47.5 1C47.5 0.723858 47.2761 0.5 47 0.5V1.5ZM30.89 1.5H38.751V0.5H30.89V1.5ZM38.2529 1.0434L41.5019 38.3314L42.4981 38.2446L39.2491 0.956598L38.2529 1.0434ZM41.6406 37.9404L33.2136 46.6524L33.9324 47.3476L42.3594 38.6356L41.6406 37.9404ZM33.573 46.5H16.188V47.5H33.573V46.5ZM16.5993 46.7157C14.043 43.0173 11.4421 40.5818 8.77953 39.5053C6.0814 38.4145 3.38123 38.7442 0.730674 40.4387L1.26933 41.2813C3.67877 39.7408 6.0401 39.4765 8.40472 40.4324C10.8049 41.4027 13.268 43.6547 15.7767 47.2843L16.5993 46.7157ZM9.5 48.5C9.5 50.433 7.933 52 6 52V53C8.48528 53 10.5 50.9853 10.5 48.5H9.5ZM6 52C4.067 52 2.5 50.433 2.5 48.5H1.5C1.5 50.9853 3.51472 53 6 53V52ZM2.5 48.5C2.5 46.567 4.067 45 6 45V44C3.51472 44 1.5 46.0147 1.5 48.5H2.5ZM6 45C7.933 45 9.5 46.567 9.5 48.5H10.5C10.5 46.0147 8.48528 44 6 44V45ZM46.5 48.5C46.5 50.433 44.933 52 43 52V53C45.4853 53 47.5 50.9853 47.5 48.5H46.5ZM43 52C41.067 52 39.5 50.433 39.5 48.5H38.5C38.5 50.9853 40.5147 53 43 53V52ZM39.5 48.5C39.5 46.567 41.067 45 43 45V44C40.5147 44 38.5 46.0147 38.5 48.5H39.5ZM43 45C44.933 45 46.5 46.567 46.5 48.5H47.5C47.5 46.0147 45.4853 44 43 44V45ZM42.783 1.5H47V0.5H42.783V1.5Z'
        fill='white'
      />
    </g>
  </svg>
)

export default ScooterIcon
