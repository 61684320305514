import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Jumbotron.scss'

/* eslint-disable-next-line no-unused-vars */
const Jumbotron = ({ title, addClass, children, ...props }) => {
  const wrapProps = classNames('jumbotron', { [addClass]: addClass })

  return (
    <div className={wrapProps} data-testid='jumbotron'>
      {title && <h1 data-testid='jumbotron-title'>{title}</h1>}
      {children && <p className='jumbotron--subtitle'>{children}</p>}
    </div>
  )
}

Jumbotron.propTypes = {
  title: PropTypes.string,
  addClass: PropTypes.string,
  children: PropTypes.node,
}

Jumbotron.defaultProps = {
  title: '',
  addClass: '',
}

export default Jumbotron
