import React from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { IconComponent } from '../../utils/iconType'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './Dropdown.scss'
/* eslint-disable no-unused-vars */
const Dropwdown = ({ options, placeholder, value, defaultValue, onChange, addClass, ...rest }) => {
  const className = classNames('dropdown', { [addClass]: addClass })

  return (
    <Autocomplete
      {...rest}
      autoHighlight
      ListboxProps={{ style: { maxHeight: '15rem' } }}
      fullWidth
      options={options}
      onChange={onChange}
      className={className}
      popupIcon={IconComponent('dropdownarrow')}
      value={value}
      classes={{
        listbox: 'dropdown--listbox',
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant='outlined'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  )
}

Dropwdown.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  addClass: PropTypes.string,
  onChange: PropTypes.func,
}

Dropwdown.defaultProps = {
  placeholder: 'Choose an option',
  options: [],
  defaultValue: null,
  addClass: '',
  onChange: () => {},
}

export default Dropwdown
