import React from 'react'
import Strings from '../../../../constants/Strings/index'
import { Card } from '../Card/Card'

import './Equivalence.scss'

export const Equivalence = ({ totalEmissions }) => {
  const {
    HOME: { EQUIVALENCES },
  } = Strings

  const cards = [
    {
      title: EQUIVALENCES.ITEMS.PIANO.calculateEquivalent(totalEmissions),
      subtitle: EQUIVALENCES.ITEMS.PIANO.SUBTITLE,
      urlImage: '/assets/piano-icon.png',
    },
    {
      title: EQUIVALENCES.ITEMS.CAR.calculateEquivalent(totalEmissions),
      subtitle: EQUIVALENCES.ITEMS.CAR.SUBTITLE,
      urlImage: '/assets/car-icon.png',
    },
    {
      title: EQUIVALENCES.ITEMS.CHARGER.calculateEquivalent(totalEmissions),
      subtitle: EQUIVALENCES.ITEMS.CHARGER.SUBTITLE,
      urlImage: '/assets/charger-icon.png',
    },
    {
      title: EQUIVALENCES.ITEMS.FOREST.calculateEquivalent(totalEmissions),
      subtitle: EQUIVALENCES.ITEMS.FOREST.SUBTITLE,
      urlImage: '/assets/trees-icon.png',
    },
  ]

  return (
    <div className='equivalence'>
      <h1 className='equivalence__title'>{EQUIVALENCES.TITLE}</h1>
      <h4 className='equivalence__subtitle'>{EQUIVALENCES.SUBTITLE}</h4>
      <div className='equivalence__container'>
        {cards.map((card) => (
          <Card {...card} key={card.title}></Card>
        ))}
      </div>
      <small>
        Source:{' '}
        <a
          className='equivalence__link'
          href='https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator'
          target='_blank'
          rel='noopener noreferrer'
        >
          EPA Greenhouse Gas Equivalencies Calculator
        </a>
      </small>
    </div>
  )
}
