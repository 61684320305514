import React, { useState } from 'react'

import IconButton from '../IconButton/IconButton'
import Strings from '../../constants/Strings'
import { ecoList } from '../../utils/generalHelper'

import './SelectionSection.scss'

const addItemsToTheList = (listOfIcons, iconSelected) => {
  const itemsSelected = [...listOfIcons, iconSelected]

  const sortItems = itemsSelected.sort((a, b) => ecoList.includes(a) - ecoList.includes(b))

  return sortItems
}

const SelectionSection = ({ onHandleSelection = () => {}, iconsSelected = [], limitExceeded = false }) => {
  const [listOfIcons, setListOfIcons] = useState(iconsSelected)

  const handleIconButton = (type) => {
    const selectedIcons = listOfIcons.includes(type)
      ? listOfIcons.filter((item) => item !== type)
      : addItemsToTheList(listOfIcons, type)

    setListOfIcons(selectedIcons)
    onHandleSelection(selectedIcons)
  }

  return (
    <ul className='selection-section'>
      <li className='selection-section--item'>
        <IconButton
          iconName='car'
          label={Strings.SELECTION_SECTION_CAR}
          onPress={handleIconButton}
          isSelected={listOfIcons.includes('car')}
          isDisabled={limitExceeded && !listOfIcons.includes('car')}
        />
      </li>
      <li className='selection-section--item'>
        <IconButton
          iconName='taxi'
          label={Strings.SELECTION_SECTION_TAXI}
          onPress={handleIconButton}
          isSelected={listOfIcons.includes('taxi')}
          isDisabled={limitExceeded && !listOfIcons.includes('taxi')}
        />
      </li>
      <li className='selection-section--item'>
        <IconButton
          iconName='bus'
          label={Strings.SELECTION_SECTION_BUS}
          onPress={handleIconButton}
          isSelected={listOfIcons.includes('bus')}
          isDisabled={limitExceeded && !listOfIcons.includes('bus')}
        />
      </li>
      <li className='selection-section--item'>
        <IconButton
          iconName='combi'
          label={Strings.SELECTION_SECTION_COMBI}
          onPress={handleIconButton}
          isSelected={listOfIcons.includes('combi')}
          isDisabled={limitExceeded && !listOfIcons.includes('combi')}
        />
      </li>
      <li className='selection-section--item'>
        <IconButton
          iconName='subway'
          label={Strings.SELECTION_SECTION_TRAIN}
          onPress={handleIconButton}
          isSelected={listOfIcons.includes('subway')}
          isDisabled={limitExceeded && !listOfIcons.includes('subway')}
        />
      </li>
      <li className='selection-section--item'>
        <IconButton
          iconName='motorcycle'
          label={Strings.SELECTION_SECTION_MOTORCYCLE}
          onPress={handleIconButton}
          isSelected={listOfIcons.includes('motorcycle')}
          isDisabled={limitExceeded && !listOfIcons.includes('motorcycle')}
        />
      </li>
      <li className='selection-section--item'>
        <IconButton
          iconName='scooter'
          label={Strings.SELECTION_SECTION_SCOOTER}
          onPress={handleIconButton}
          isSelected={listOfIcons.includes('scooter')}
          isDisabled={limitExceeded && !listOfIcons.includes('scooter')}
        />
      </li>
      <li className='selection-section--item'>
        <IconButton
          iconName='bike'
          label={Strings.SELECTION_SECTION_BICYCLE}
          onPress={handleIconButton}
          isSelected={listOfIcons.includes('bike')}
          isDisabled={limitExceeded && !listOfIcons.includes('bike')}
        />
      </li>
      <li className='selection-section--item'>
        <IconButton
          iconName='walking'
          label={Strings.SELECTION_SECTION_WALKING}
          onPress={handleIconButton}
          isSelected={listOfIcons.includes('walking')}
          isDisabled={limitExceeded && !listOfIcons.includes('walking')}
        />
      </li>
    </ul>
  )
}

export default SelectionSection
