import React from 'react'
import Button from '../../../../components/Button/Button'
import Measure from '../../../../components/Measure/Measure'
import './WelcomeBackSummary.scss'

export const WelcomeBackSummary = () => {
  const userData = JSON.parse(localStorage.getItem('userResponse'))

  function redirectToSurvey() {
    window.location.href = '/country-selection'
  }
  // if (userData.TotalEmissions === 0) {
  //   redirectToSurvey()
  // }
  return (
    <div className='welcome-back-wrapper'>
      {/* <div className='welcome-back__summary'> */}
      <div className='welcome-back__banner-left'>
        <Measure addClass='result__value' showBigMeasure totalGloberCurrentEmissionKgCO2={userData?.TotalEmissions} />
        {/* <Measure addClass='result__value' totalGloberCurrentEmissionKgCO2={userData?.TotalEmissions} showBigMeasure/> */}
      </div>
      <div className='welcome-back__banner-right'>
        <h3 className='welcome-back__banner-right__title'>Have you changed your working habits in the last months?</h3>
        <p className='welcome-back__banner-right__subtitle'>
          Update your previous answers and see if your carbon footprint has evolved!
        </p>
        <Button onClick={redirectToSurvey}>Update my answers</Button>
      </div>
      {/* </div> */}
    </div>
  )
}
