import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Login from './pages/Login/Login'
import CountrySelection from './pages/CountrySelection/CountrySelection'
import HomeConsumption from './pages/HomeConsumption/HomeConsumption'
import Selection from './pages/Selection/Selection'
import Loading from './pages/Loading/Loading'
import Result from './pages/Result/Result'
import Trips from './pages/Trips/Trips'
import Page404 from './pages/Page404/Page404'
import AddressNotFound from './pages/AddressNotFound/AddressNotFound'
import SplashScreen from './pages/SplashScreen/SplashScreen'
import WelcomeBack from './pages/WelcomeBack/WelcomeBack'

function App() {
  return (
    <Switch>
      <Route exact path='/' component={SplashScreen} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/country-selection' component={CountrySelection} />
      <Route exact path='/result' component={Result} />
      <Route exact path='/trips' component={Selection} />
      <Route exact path='/home-consumption' component={HomeConsumption} />
      <Route exact path='/trips/:vehicle?' component={Trips} />
      <Route exact path='/loading' component={Loading} />
      <Route exact path='/address-not-found' component={AddressNotFound} />
      <Route exact path='/welcome-back' component={WelcomeBack} />
      <Route path='/*' component={Page404} />
    </Switch>
  )
}

export default App
