import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import { createBrowserHistory } from 'history'
import { ToastProvider } from 'react-toast-notifications'
import Notifications from './components/Notifications/Notifications'
import StoreProvider from './providers/StoreProvider'
import MagicProvider from './providers/MagicProvider'
import App from './App'

// initialize firebase app
import './services/firebase' // TODO: should call in this file or add file?

import './shared/main.scss'

const history = createBrowserHistory()

const application = (
  <React.StrictMode>
    <ToastProvider components={{ Toast: Notifications }}>
      <MagicProvider>
        <StoreProvider>
          <Router history={history}>
            <App />
          </Router>
        </StoreProvider>
      </MagicProvider>
    </ToastProvider>
  </React.StrictMode>
)

ReactDOM.render(application, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
