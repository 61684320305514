import React from 'react'
import Strings from '../../../constants/Strings'
import Button from '../../../components/Button/Button'

export const InitiativesSection = () => {
  const {
    RESULT: { GENERAL },
  } = Strings

  return (
    <div className='result__general'>
      <div className='result__general-thankyou'>
        <p className='result__general-thankyou-text' style={{ fontWeight: 700 }}>
          {GENERAL.THANK_YOU}
        </p>
        <p className='result__general-thankyou-text'>{GENERAL.CONTACT_SOON}</p>
      </div>

      <p>{GENERAL.KNOW_MORE}</p>
      <Button
        addClass='result__general-button'
        onClick={() => {
          window.open(GENERAL.LINKS.STARTED.URL)
        }}
      >
        {GENERAL.LINKS.STARTED.TEXT}
      </Button>
      <p>
        {GENERAL.CONTACT}
        <a
          href='mailto:sustainability@globant.com'
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: 'white' }}
        >
          {' '}
          sustainability@globant.com{' '}
        </a>{' '}
        .
      </p>
    </div>
  )
}
