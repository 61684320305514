import React from 'react'

const DevicesIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='35'
    strokeWidth='0.5'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path
      d='M35.773 0H2.227A2.23 2.23 0 0 0 0 2.227v22.117a2.23 2.23 0 0 0 2.227 2.226h10.91v5.492H7.125c-.615 0-1.113.499-1.113 1.114s.498 1.113 1.113 1.113h23.75c.615 0 1.113-.498 1.113-1.113s-.498-1.114-1.113-1.114h-6.012V26.57h10.91A2.23 2.23 0 0 0 38 24.344V2.227A2.23 2.23 0 0 0 35.773 0zM22.637 32.063h-7.274V26.57h7.274v5.492zm13.136-7.72H2.227V2.228h33.546v22.117z'
      fill='#fff'
    />
  </svg>
)

export default DevicesIcon
