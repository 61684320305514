import React from 'react'

const CombiIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='70' height='47' viewBox='0 0 70 47'>
    <path
      fillRule='evenodd'
      strokeWidth='0.1'
      d='M67.667 22.197H44.333V2.337H56.63L67.667 15.28v6.916zm0 15.188h-4.76c-.68-4.465-4.845-7.532-9.306-6.852-3.531.54-6.302 3.315-6.841 6.852H23.24c-.68-4.465-4.845-7.532-9.304-6.852-3.533.54-6.304 3.315-6.843 6.852h-4.76V24.534h65.334v12.85zm-12.834 7.01c-3.222 0-5.833-2.615-5.833-5.842 0-3.227 2.611-5.841 5.833-5.841 3.223 0 5.834 2.614 5.834 5.841 0 3.227-2.611 5.841-5.834 5.841zm-39.666-.046c-3.222 0-5.834-2.614-5.834-5.841 0-3.227 2.612-5.841 5.834-5.841 3.22 0 5.833 2.614 5.833 5.841 0 3.227-2.612 5.841-5.833 5.841zM2.333 22.197H21V2.337H2.333v19.86zm21 0H42V2.337H23.333v19.86zM58.053.41C57.832.149 57.507 0 57.167 0H3.5C1.567 0 0 1.569 0 3.505v35.048c0 .645.523 1.168 1.167 1.168h5.926c.68 4.464 4.845 7.532 9.304 6.851 3.533-.539 6.304-3.314 6.843-6.85h23.52c.68 4.463 4.845 7.531 9.305 6.85 3.532-.539 6.303-3.314 6.842-6.85h5.926c.646 0 1.167-.524 1.167-1.169V14.849c0-.278-.1-.548-.28-.76L58.053.41z'
    />
  </svg>
)

export default CombiIcon
