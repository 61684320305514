import React from 'react'
import './Question.scss'
import PropTypes from 'prop-types'

import Heading from '../../Heading/Heading'

const Question = ({ title = '', observation = '', description = '', children }) => {
  return (
    <article className='question'>
      <Heading addClass='question__title' level='h4'>
        {title}
        <span className='question__observation'>{observation}</span>
      </Heading>
      <p className='question__description'>{description}</p>
      <div className='question__options'>{children}</div>
    </article>
  )
}

Question.propTypes = {
  title: PropTypes.string,
  observation: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.element,
}

export default Question
