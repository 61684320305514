import React from 'react'
import {
  BikeIcon,
  BusIcon,
  CombiIcon,
  CarIcon,
  HomeIcon,
  MissingIcon,
  MotorcycleIcon,
  ScooterIcon,
  SubwayIcon,
  WalkingIcon,
  TaxiIcon,
  SadFaceIcon,
  AddressNotFoundIcon,
  DropdownArrow,
  CheckIcon,
  DevicesIcon,
  ColdIcon,
  SunIcon,
  CableIcon,
  ModalResultInfoIcon,
} from '../components/Icons'

const components = {
  basic: CarIcon,
  bike: BikeIcon,
  bus: BusIcon,
  combi: CombiIcon,
  car: CarIcon,
  taxi: TaxiIcon,
  home: HomeIcon,
  missing: MissingIcon,
  motorcycle: MotorcycleIcon,
  scooter: ScooterIcon,
  subway: SubwayIcon,
  walking: WalkingIcon,
  sadface: SadFaceIcon,
  addressnotfound: AddressNotFoundIcon,
  dropdownarrow: DropdownArrow,
  check: CheckIcon,
  devices: DevicesIcon,
  cold: ColdIcon,
  sun: SunIcon,
  cable: CableIcon,
  info: ModalResultInfoIcon,
}

export function IconComponent(type) {
  const TypeComponent =
    components[type.toLowerCase()] !== undefined ? components[type.toLowerCase()] : components['basic']

  return <TypeComponent />
}
