import { holidaysPerCountry } from '../utils/holidaysPerCountry'

const energeticConsumeByCountry = {
  argentina: 0.2287,
  australia: 0.5951,
  belarus: 0.4453,
  brazil: 0.0741,
  bulgaria: 0.4535,
  canada: 0.1160,
  chile: 0.3615,
  china: 0.6608,
  colombia: 0.1769,
  costarica: 0.0005,
  denmark: 0.1396,
  ecuador: 0.1381,
  unitedArabEmirates: 0.4216,
  france: 0.0706,
  germany: 0.3799,
  hongKong: 0.6929,
  india: 0.9335,
  italy: 0.3143,
  luxemburg: 0.0871,
  mexico: 0.4060,
  moldova: 0.4739,
  netherlands: 0.3090,
  panama: 0.1422,
  peru: 0.2007,
  philippines: 0.6825,
  poland: 0.7079,
  portugal: 0.1664,
  romania: 0.2782,
  saudiArabia: 0.5950,
  serbia: 0.6705,
  spain: 0.1811,
  uk: 0.2074,
  ukraine:0.2521,
  uruguay: 0.0756,
  vietnam: 0.4371,
  usa: 0.3746,
  usaCalifornia: 0.2075,
  usaFlorida: 0.3713,
  usaIllinois: 0.2687,
  usaNewYork: 0.2229,
  usaNorthCarolina: 0.2981,
  usaTexas: 0.3728,
  usaWashington: 0.0844,
}

// ac means air conditioning, i means inverter
//consume in kWh

//SHOULD BE FIX THIS
const consumeFactors = {
  aC2200: 1.013,
  aC3500: 1.613,
  aC4500: 2.153,
  aC2200i: 0.658,
  aC3500i: 1.048,
  aC4500i: 1.365,
  windowsAc: 2.153,
  splitAc: 1.9,
  cassetteAc: 1.614,
  otherAc: 1.613,
  gasBoiler: 0.23,
  gasFireplace: 0.2,
  gasHeater: 0.32,
  woodStove: 1.6,
}

const getAcConsumeFactor = (state) => {
  const homeConsumption = state.homeConsumption
  if (state.countrySelected.country === 'India') {
    switch (homeConsumption.houseCooling.india.qTypeAirConditioning) {
      case 'windowAC':
        return consumeFactors['windowsAc']
      case 'splitAC':
        return consumeFactors['splitAc']
      case 'cassetteAC':
        return consumeFactors['cassetteAc']
      case 'other':
        return consumeFactors['otherAc']
      default:
        return 0
    }
  } else {
    switch (homeConsumption.houseCooling.global.qManyFrigories) {
      case 'small':
        return consumeFactors['aC2200']
      case 'medium':
        return consumeFactors['aC3500']
      case 'large':
        return consumeFactors['aC4500']
      case 'other':
        return consumeFactors['otherAc']
      default:
        return 0
    }
  }
}

const getHeatingConsumeFactor = (homeConsumption) => {
  switch (homeConsumption.houseHeating.qHeatingDevice) {
    case 'Wood stove':
      return consumeFactors['woodStove']
    case 'Gas boiler':
      return consumeFactors['gasBoiler']
    case 'Gas cylinder heater':
      return consumeFactors['gasFireplace']
    case 'Gas heater':
      return consumeFactors['gasHeater']
    default:
      return 0
  }
}

const daysDiffByTwoDates = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1)
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export const getCountryConsume = (countryName) => {
  const contryToLowercase = countryName.toLowerCase().replace(' ', '')
  return energeticConsumeByCountry[contryToLowercase]
}

const getRenewableEnergyUsed = (homeConsumption) => {
  let renewableEnergyUsed = 0
  if (homeConsumption.devicesEnergy.qTypeOfRenewableEnergy.includes('Photovoltaic Solar Panel')) {
    renewableEnergyUsed = homeConsumption.devicesEnergy.qEnergyPercentage / 100
  }
  return renewableEnergyUsed
}

const getHoursWorking = (state) => {
  const countryName = state.countrySelected.country.toLowerCase().replace(' ', '')
  const startDate = new Date('1/1/2022')
  const endDate = new Date('1/1/2023')
  const weekendDays = 105
  const daysWorking =
    daysDiffByTwoDates(startDate, endDate) -
    state.homeConsumption.homeOffice.qTimeOffDays -
    holidaysPerCountry[countryName] -
    weekendDays

  let qHoursADayWFH = state.homeConsumption.homeOffice.qHoursADayWFH
  if (!qHoursADayWFH) qHoursADayWFH = 9

  return daysWorking * qHoursADayWFH
}

const emissionQuestion6 = (state) => {
  const homeConsumption = state.homeConsumption
  const hoursWorking = getHoursWorking(state)
  const countryConsume = getCountryConsume(state.countrySelected.country)
  const renewableEnergyUsed = getRenewableEnergyUsed(homeConsumption)

  let grossConsume = 0

  if (renewableEnergyUsed > 0) {
    grossConsume = hoursWorking * 0.022 - hoursWorking * 0.022 * renewableEnergyUsed
  } else {
    grossConsume = hoursWorking * 0.022
  }

  return grossConsume * countryConsume
}

const emissionQuestion8 = (state) => {
  const homeConsumption = state.homeConsumption
  const extraMonitors = homeConsumption.devicesEnergy.qNumberOfScreens
  const renewableEnergyUsed = getRenewableEnergyUsed(homeConsumption)
  const hoursWorking = getHoursWorking(state)
  let grossValue = 0

  if (!extraMonitors) return grossValue

  if (renewableEnergyUsed) {
    grossValue = extraMonitors * 0.022 * hoursWorking - extraMonitors * hoursWorking * 0.022 * renewableEnergyUsed
  } else {
    grossValue = extraMonitors * 0.022 * hoursWorking
  }

  return grossValue * getCountryConsume(state.countrySelected.country)
}

const emissionQuestion13 = (state) => {
  const countryName = state.countrySelected.country.toLowerCase().replace(' ', '')
  const homeConsumption = state.homeConsumption
  const renewableEnergyUsed = getRenewableEnergyUsed(homeConsumption)
  const monthsUsingAlternativeDevice = homeConsumption.houseCooling.global.qManyMonthsUseAnother
  const hoursUsingAlternativeDevice = homeConsumption.houseCooling.global.qManyHoursUseAnother
  let daysWorkingAtWeek = homeConsumption.homeOffice.qDaysWorkFromHome

  if (!daysWorkingAtWeek) daysWorkingAtWeek = 45

  const vacationsDays = homeConsumption.homeOffice.qTimeOffDays + holidaysPerCountry[countryName]
  const hoursUsingDevice =
    (monthsUsingAlternativeDevice * daysWorkingAtWeek * 4 - vacationsDays) * hoursUsingAlternativeDevice

  const electricUse = homeConsumption.electricityUse.qConsumptionAssociated / 100
  let grossValue = 0

  if (renewableEnergyUsed) {
    grossValue = 0.09 * hoursUsingDevice * electricUse - 0.09 * hoursUsingDevice * electricUse * renewableEnergyUsed
  } else {
    grossValue = 0.09 * hoursUsingDevice * electricUse
  }

  return grossValue * getCountryConsume(state.countrySelected.country)
}

const emissionQuestion14 = (state) => {
  const homeConsumption = state.homeConsumption
  const hoursAtDayUssingAC = !!parseInt(homeConsumption.houseCooling.global.qManyHoursUse) || 0
  const monthsAtDayUssingAC = !!parseInt(homeConsumption.houseCooling.global.qManyMonthsUse) || 0
  const totalHoursUsingAC = hoursAtDayUssingAC * parseInt(monthsAtDayUssingAC * 30 - monthsAtDayUssingAC * 4 * 2)
  const renewableEnergyUsed = getRenewableEnergyUsed(homeConsumption)
  const AcEnergyConsume = getAcConsumeFactor(state)

  const electricUse = homeConsumption.electricityUse.qConsumptionAssociated / 100

  let grossValue = 0

  if (renewableEnergyUsed) {
    grossValue =
      AcEnergyConsume * totalHoursUsingAC * electricUse -
      AcEnergyConsume * totalHoursUsingAC * electricUse * renewableEnergyUsed
  } else {
    grossValue = AcEnergyConsume * totalHoursUsingAC * electricUse
  }

  return grossValue * getCountryConsume(state.countrySelected.country)
}

const emissionQuestion19 = (state) => {
  const countryName = state.countrySelected.country.toLowerCase().replace(' ', '')

  const homeConsumption = state.homeConsumption
  const vacationsDays = homeConsumption.homeOffice.qTimeOffDays + holidaysPerCountry[countryName]
  const monthsUsingAlternativeDevice = homeConsumption.houseCooling.global.qManyMonthsUseAnother
  const hoursAtDayUsingAlternativeDevice = homeConsumption.houseCooling.global.qManyHoursUseAnother
  const hoursUsingAlternativeDevice =
    (monthsUsingAlternativeDevice * 25 - vacationsDays) * hoursAtDayUsingAlternativeDevice
  const renewableEnergyUsed = getRenewableEnergyUsed(homeConsumption)
  const AcEnergyConsume = getAcConsumeFactor(state)
  let grossValue = 0

  if (renewableEnergyUsed) {
    grossValue =
      hoursUsingAlternativeDevice * AcEnergyConsume -
      hoursUsingAlternativeDevice * AcEnergyConsume * renewableEnergyUsed
  } else {
    grossValue = hoursUsingAlternativeDevice * AcEnergyConsume
  }

  return grossValue * getCountryConsume(state.countrySelected.country)
}

const emissionQuestionHouseHeating = (state) => {
  const homeConsumption = state.homeConsumption
  const electricConsume = getHeatingConsumeFactor(homeConsumption)
  const monthsUsingDevice = homeConsumption.houseHeating.qMonthsUsingDevice
  const hoursUsingDevice = homeConsumption.houseHeating.qhoursAtDayUsingDevice
  return 0.002 * electricConsume * monthsUsingDevice * 25 * hoursUsingDevice * 1000
}

export const getTotalEmission = (state) => {
  const homeConsumption = state.homeConsumption
  const electricUse = homeConsumption.electricityUse.qConsumptionAssociated / 100

  if (state.countrySelected.country === 'India') {
    return (
      emissionQuestion6(state) +
      emissionQuestion8(state) +
      emissionQuestion13(state) * electricUse +
      emissionQuestion19(state) * electricUse +
      emissionQuestionHouseHeating(state) * electricUse
    )
  } else {
    return (
      emissionQuestion6(state) +
      emissionQuestion8(state) +
      emissionQuestion13(state) * electricUse +
      emissionQuestion14(state) * electricUse +
      emissionQuestionHouseHeating(state) * electricUse
    )
  }
}
