import React from 'react'

const AddressNotFoundIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'>
    <g fill='#FFF'>
      <path d='M64 62.968c0-.001 0-.001 0 0V25.806c0-.277-.115-.536-.306-.726l.004-.003-10.02-10.02V9.29c0-.274-.109-.536-.303-.73L45.117.303C44.924.108 44.662 0 44.387 0H11.355c-.571 0-1.032.461-1.032 1.032v14.025L.303 25.077l.003.003c-.191.19-.306.449-.306.726v37.162c0 .107.017.215.052.322.139.423.533.71.98.71h61.936c.447 0 .841-.287.98-.71.035-.107.052-.215.052-.322zM45.42 3.524l4.733 4.734H45.42V3.524zm16.515 24.297v33.11l-22.78-16.707 22.78-16.403zm-.555-2.143l-7.703 5.546V17.976l7.703 7.702zM43.355 2.065V9.29c0 .571.461 1.033 1.032 1.033h7.226V32.71l-14.21 10.23-4.792-3.514c-.365-.266-.857-.266-1.222 0l-4.793 3.515-14.209-10.23V2.064h30.968zm-18.51 42.159L2.065 60.93V27.821l22.78 16.403zm-14.522-13L2.62 25.678l7.703-7.702v13.248zM4.186 61.935L32 41.538l27.814 20.397H4.186z' />
      <path d='M32.5 34C38.29 34 43 29.29 43 23.5S38.29 13 32.5 13 22 17.71 22 23.5 26.71 34 32.5 34zm0-18.9c4.632 0 8.4 3.768 8.4 8.4 0 4.632-3.768 8.4-8.4 8.4-4.632 0-8.4-3.768-8.4-8.4 0-4.632 3.768-8.4 8.4-8.4z' />
      <path d='M29.823 24.018C30.03 25.167 30.945 26 32 26s1.97-.833 2.177-1.982l.769-4.27c.035-.202.054-.41.054-.616C35 17.406 33.735 16 32.181 16h-.361C30.265 16 29 17.406 29 19.132c0 .207.019.414.055.616l.768 4.27zm1.996-5.796h.361c.453 0 .82.408.82.91 0 .061-.005.12-.016.18l-.769 4.27c-.041.227-.391.227-.432 0l-.769-4.27c-.009-.059-.014-.119-.014-.18 0-.502.367-.91.819-.91z' />
      <circle cx='32' cy='29' r='2' />
    </g>
  </svg>
)

export default AddressNotFoundIcon
