import React from 'react'

/**
 * @see https://www.iconfinder.com/icons/4213446/faq_help_mark_question_icon
 */
/* eslint-disable react/prop-types */
const MissingIcon = () => (
  <svg viewBox='0 0 130 130' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path d='M64.9,28.9c-5.2-0.2-10.1,1.6-13.9,5.2c-3.8,3.6-5.8,8.4-5.8,13.6h7.7c0-3.1,1.2-5.9,3.5-8.1c2.2-2.1,5.1-3.2,8.2-3.1   c5.7,0.3,10.3,4.9,10.6,10.6c0.3,6-5.7,11.5-8.3,13.6l-7.7,6.8v7.7h7.7V71l4.9-4.3c4.3-3.5,11.5-10.8,11.1-19.9   C82.4,37.2,74.5,29.3,64.9,28.9z' />
      <rect height='8' width='7.7' x='59.2' y='83.3' />
      <path d='M1,127h126V1H1V127z M9,9h110v110H9V9z' />
    </g>
  </svg>
)

export default MissingIcon
