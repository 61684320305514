export const ecoList = ['scooter', 'bike', 'walking', 'home']
export const daysOfTheWeek = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const equivalenceKmToMiles = 0.621371
export const homeConsumptionSections = ['home', 'devices', 'cold', 'sun', 'cable']
export const emptyValue = null

export const initialState = {
  countrySelected: {
    city: null,
    country: null,
  },
  vehiclesSelected: [],
  totalGHGEmissions: 0,
  totalWFHEmissions: 0,
  equivalences: {
    emailsSent: '',
    zoomHours: '',
    netflixHours: '',
  },
  vehiclesSelectedData: [],
  homeConsumption: {
    currentSection: 0,
    homeOffice: {
      qWorkingFullTime: '',
      qDaysWorkFromHome: '',
      qHoursADayWFH: '',
      qTimeOffDays: 0,
    },
    devicesEnergy: {
      qNumberOfScreens: '',
      qHaveRenewableEnergy: '',
      qTypeOfRenewableEnergy: [],
      qEnergyPercentage: 0,
    },
    houseCooling: {
      global: {
        qHaveAirConditioning: '',
        qManyFrigories: '',
        qManyMonthsUse: '',
        qManyHoursUse: '',
        qUseAnotherDevice: '',
        qManyMonthsUseAnother: '',
        qManyHoursUseAnother: '',
      },
      india: {
        qTypeAirConditioning: '',
        qCapacityAirConditioning: '',
      },
    },
    houseHeating: {
      qHeatingDevice: '',
      qMonthsUsingDevice: '',
      qhoursAtDayUsingDevice: '',
    },
    electricityUse: {
      qConsumptionAssociated: 0,
      qTyC: '',
    },
  },
  requestStatus: {
    sending: false,
    success: false,
    error: false,
  },
}
export const magicState = {
  isAuthenticated: false,
  isSurveyComplete: false,
}

export const delay = (time = 1500) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

export const formatNumberWithK = (number) => {
  if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'k'
  } else {
    return number.toString()
  }
}
