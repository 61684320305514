import React from 'react'
import classNames from 'classnames'
import Button from '../Button/Button'
import Strings from '../../constants/Strings'

import './Footer.scss'

const defaultBackButton = {
  hide: false,
  disable: false,
  text: Strings.BACK_STEP,
  handleOnClick: () => {},
}
const defaultNextButton = {
  hide: false,
  disable: false,
  text: Strings.CONTINUE_BUTTON,
  handleOnClick: () => {},
  loading: false,
}

const Footer = ({
  hasAdditionalInformation = false,
  hasTermsAndConditions = false,
  hideActions = false,
  backButton = defaultBackButton,
  nextButton = defaultNextButton,
  hideMap = false,
  addClass = '',
}) => {
  const leftButton = { ...defaultBackButton, ...backButton }
  const rightButton = { ...defaultNextButton, ...nextButton }

  const footerClass = classNames('footer', {
    [addClass]: addClass,
  })

  const renderTermsAndConditions = (
    <div className='footer--terms'>
      <p>
        {Strings.TERMS_AND_CONDITIONS}{' '}
        <a href='mailto:privacy@globant.com' target='_blank' rel='noopener noreferrer'>
          privacy@globant.com
        </a>
        .
      </p>
    </div>
  )

  const renderAdditionalInformation = (
    <>
      <p>
        {Strings.RESULT_NOTE_PART_1}{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://sites.google.com/globant.com/sustainability/be-kind '
        >
          {Strings.RESULT_NOTE_CLICK_HERE_TEXT}
        </a>
      </p>
      <p>
        {Strings.RESULT_NOTE_PART_2}{' '}
        <a href='https://www.footprintcalculator.org' target='_blank' rel='noopener noreferrer'>
          {Strings.RESULT_NOTE_SURVEY_TEXT}
        </a>
      </p>
    </>
  )

  const renderCenterContent = (
    <div className='footer--center'>
      {hasTermsAndConditions ? renderTermsAndConditions : renderAdditionalInformation}
    </div>
  )

  const renderActions = !hideActions && (
    <>
      <div className='footer--left'>
        {!leftButton.hide && (
          <Button disabled={leftButton.disable} onClick={leftButton.handleOnClick} addClass='footer--left-button'>
            {leftButton.text}
          </Button>
        )}
      </div>
      <div className='footer--right'>
        {!rightButton.hide && (
          <Button disabled={rightButton.disable} onClick={rightButton.handleOnClick} loading={rightButton.loading}>
            {rightButton.text}
          </Button>
        )}
      </div>
    </>
  )

  return (
    <>
      <footer className={footerClass}>{hasAdditionalInformation ? renderCenterContent : renderActions}</footer>
      {!hideMap && <span className='footer--map' />}
    </>
  )
}

export default Footer
