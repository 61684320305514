import React, { useState } from 'react'
import classNames from 'classnames'
import { IconComponent } from '../../utils/iconType'

import './IconButton.scss'

const IconButton = ({
  iconName,
  label = 'default text',
  onPress = () => {},
  isSelected = false,
  isDisabled = false,
  ...props
}) => {
  const [selected, setSelected] = useState(isSelected)

  const handleOnPress = () => {
    setSelected(!selected)
    onPress(iconName, !selected)
  }

  const className = classNames('icon-button', {
    'icon-button__selected': selected,
    'icon-button__disabled': isDisabled,
  })

  const classNameLabel = classNames('icon-button--label', {
    'icon-button--selected': selected,
    'icon-button--disabled': isDisabled,
  })

  return (
    <button disabled={isDisabled} onClick={handleOnPress} className={className} {...props}>
      <div className='icon-button--center'>
        <div className='icon-button--box'>
          <div className='icon-button--icon'>{IconComponent(iconName)}</div>
          <span data-testid='icon-label' className={classNameLabel}>
            {label}
          </span>
        </div>
      </div>
    </button>
  )
}

IconButton.defaultProps = {
  iconName: 'basic',
  label: 'default text',
  isSelected: false,
  isDisabled: false,
  'data-testid': 'icon-button',
}

export default IconButton
