import { initial, toNumber, flatten, sum  } from 'lodash'
import { getVehicleEmissionFactorByKm } from '../utils/vehiclePerformance'
import defaultTrips from '../utils/defaultTrips'
import { getCountryConsume } from '../utils/carbonEmission'
import { getHolidayPerCountry } from '../utils/holidaysPerCountry'
import Strings from '../constants/Strings'
import Number from '../constants/Number'

const blackList = ['car', 'motorcycle']

function updateNextVehicle(vehiclesSelectedData, vehicleIndex, tripIndex, vehicleData) {
  const { value, disableDays } = vehicleData

  const weeklyJourneysMade = {
    ...vehiclesSelectedData[vehicleIndex].trips[tripIndex].weeklyJourneysMade,
    disableDays: toNumber(value) + disableDays - 1,
  }

  const trip = {
    ...vehiclesSelectedData[vehicleIndex].trips[tripIndex],
    weeklyJourneysMade,
  }

  const trips = [trip]

  const vehicle = {
    ...vehiclesSelectedData[vehicleIndex],
    trips,
  }

  const updateVehiclesSelectedData = [
    ...vehiclesSelectedData.slice(0, vehicleIndex),
    vehicle,
    ...vehiclesSelectedData.slice(vehicleIndex + 1),
  ]

  return updateVehiclesSelectedData
}

export const setVehiclesSelectedData = (vehicles) =>
  vehicles.map((vehicleType) => {
    return {
      vehicle: vehicleType,
      trips: [defaultTrips(vehicleType)],
      secondTrip: false,
    }
  })

export const addDefaultSecondTrip = (vehicleType, vehiclesSelectedData) => {
  const vehicleSelected = vehiclesSelectedData.map((vehicleSelected) => {
    const { vehicle, trips, secondTrip } = vehicleSelected

    if (vehicle === vehicleType) {
      return {
        vehicle,
        trips: [...trips, defaultTrips(vehicleType)],
        secondTrip: true,
      }
    }

    return {
      vehicle,
      trips,
      secondTrip,
    }
  })

  return vehicleSelected
}

export const removeSecondTrip = (vehicleType, vehiclesSelectedData) => {
  const vehicleSelected = vehiclesSelectedData.map((vehicleSelected) => {
    const { vehicle, trips, secondTrip } = vehicleSelected

    if (vehicle === vehicleType) {
      const removeLastTrip = initial(trips)

      return {
        vehicle,
        trips: removeLastTrip,
        secondTrip: false,
      }
    }

    return {
      vehicle,
      trips,
      secondTrip,
    }
  })

  return vehicleSelected
}

export const updateWeeklyJourneysMade = (payload, vehiclesSelectedData) => {
  const { disabledDays } = payload
  const weeklyJourneysMade = {
    ...vehiclesSelectedData[0].trips[0].weeklyJourneysMade,
    disableDays: disabledDays,
  }
  const trip = {
    ...vehiclesSelectedData[0].trips[0],
    weeklyJourneysMade,
  }

  const trips = [trip]

  const vehicle = {
    ...vehiclesSelectedData[0],
    trips,
  }

  const updateVehiclesSelectedData = [
    ...vehiclesSelectedData.slice(0, 0),
    vehicle,
    ...vehiclesSelectedData.slice(0 + 1),
  ]

  return updateVehiclesSelectedData
}

export const tripOptionsChange = ({ tripIndex, vehicleIndex, optionData: { type, ...rest } }, vehiclesSelectedData) => {
  let vehicleData = vehiclesSelectedData[vehicleIndex]
  let selectedVehiclesData = [...vehiclesSelectedData]

  const trips = [...vehicleData.trips]

  trips.splice(tripIndex, 1, {
    ...defaultTrips(vehicleData.vehicle),
    ...vehicleData.trips[tripIndex],
    [type]: {
      ...vehicleData.trips[tripIndex][type],
      ...rest,
    },
  })

  selectedVehiclesData.splice(vehicleIndex, 1, {
    ...vehicleData,
    trips,
  })

  if (type === 'weeklyJourneysMade' && selectedVehiclesData.length - 1 !== vehicleIndex) {
    const disableDays = trips[tripIndex].weeklyJourneysMade.disableDays

    selectedVehiclesData = updateNextVehicle(selectedVehiclesData, vehicleIndex + 1, tripIndex, {
      ...rest,
      disableDays,
    })
  }

  return selectedVehiclesData
}

export const estimateCarbonFootprint = (data, countrySelected) => {
  const vehiclesSelectedData = [...data];
  return {
    vehiclesSelectedData,
    totalGHGEmissions: getTotalCarbonFootprint(vehiclesSelectedData, countrySelected),
  }
}

export const getTotalCarbonFootprint = (data, countrySelected) => {

  if (!data.length) {
    return 0
  }

  const vehiclesSelected = [...data];
  const { country } = countrySelected;

  const distancesByTrip = calculateCarbonFootprint(vehiclesSelected, country)
  const flattenValues = flatten(distancesByTrip)
  const transformToKg = sum(flattenValues)

  return toNumber(transformToKg)
}

/**
 * Calculate the total GHG Emission created by a vehicle for a specific km travelled based on the following variables:
 *
 * * DC EF (kg CO2e/100km) ( Emission factor per passenger and per 100 km traveled )
 * * Distance ( km )
 * * Commuting days ( Commuting Days = Labor Days * Weekly Round trips / 5  )
 * * N.of passenger
 * * National Power Grid EF (kg CO2e/kWh) ( Only for electric vehicle )
 *
 *  An example of formula for a diesel car ( DC ) is:
 *  DC GHG emissions (kg CO2e) = ( DC EF (kg CO2e/100km) * Distance (km) * Commuting days) ) / (N° Passengers * 100)
 *
 *
 * @param {*} trips the list of trips made with a specific vehicle
 * @param {*} country selected country where the globers works from
 * @returns array of GHG emission
 */

const calculateCarbonFootprint = (trips, country) => {
  const vehiclesSelected = [...trips];
  const annualBusinessDays = getLabourDays(country);
  
  const GHGEmission = vehiclesSelected.map((data) => {
    const { trips, vehicle } = data
    
    return trips.map((trip) =>  {
      if (trip.ecoFriendly) return

      const { travelDistance, weeklyJourneysMade, passengersTravelInThisVehicle, engineType } = trip
  
      const isVehicleElectric = engineType.value === Strings.VEHICLE_ENGINE_TYPE_EP;
      const emissionFactor = getVehicleEmissionFactorByKm(vehicle, engineType.value)
      const kmTraveled = toNumber(travelDistance.value) * toNumber(weeklyJourneysMade.value)
      const commutingDays = (annualBusinessDays * toNumber(weeklyJourneysMade.value)) / 5

      let consumption = emissionFactor * kmTraveled * commutingDays
  
      if ( isVehicleElectric && blackList.includes(vehicle) ) {
        consumption = getCountryConsume(country) * consumption
      }
  
      const tripEmission = consumption / ( passengersTravelInThisVehicle.value * 100 )
      return tripEmission
    })
    
  })

  return GHGEmission
}

/**
 * Get the labour days based on the following formula:
 * 
 ** Labor days = 261 days- Holidays per country
 * @param {*} country 
 * @returns 
 */
export const getLabourDays = (country) =>  {
  return Number.LABOUR_DAYS - getHolidayPerCountry(country)
}