import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './RadioButtonsGroup.scss'

const RadioButtonsGroup = ({ group, addClass, onChange, children, index, value }) => {
  const wrapProps = classNames('radio-buttons-group', {
    [addClass]: addClass,
  })

  const childProps = {
    group,
    onChange,
    index,
  }

  return (
    <div className={wrapProps}>
      {React.Children.map(children, (element) =>
        React.cloneElement(element, {
          ...childProps,
          isChecked: element.props.value === value,
        })
      )}
    </div>
  )
}

RadioButtonsGroup.propTypes = {
  group: PropTypes.string.isRequired,
  addClass: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  index: PropTypes.number,
}

RadioButtonsGroup.defaultProps = {
  addClass: '',
  onChange: () => {},
  index: 0,
}

export default RadioButtonsGroup
