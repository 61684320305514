import React, { useState, useEffect, useContext } from 'react'
import Strings from '../../../../constants/Strings/index'

import Heading from '../../../../components/Heading/Heading'
import LabeledSlider from '../../../../components/RangeSlider/LabeledSlider'
import ButtonList from '../../../../components/new-components/ButtonList/ButtonList'
import Question from '../../../../components/new-components/Question/Question'
import OptionButtons from '../../../../components/new-components/OptionButtons/OptionButtons'
import StoreContext from '../../../../providers/StoreContext'
import { homeOfficeAnswersChanged } from '../../../../store/actions'
import { emptyValue } from '../../../../utils/generalHelper'
import debounce from 'lodash.debounce'

const HomeOfficeSection = ({ setDisableButton }) => {
  const {
    state: {
      homeConsumption: { homeOffice },
    },
    dispatch,
  } = useContext(StoreContext)
  const { HOME_CONSUMPTION } = Strings
  const HOME_OFFICE = HOME_CONSUMPTION.SECTIONS.HOME_OFFICE
  const { qWorkingFullTime = '', qDaysWorkFromHome = '', qHoursADayWFH = '', qTimeOffDays = '' } = homeOffice || {}
  const [answersState, setAnswersState] = useState({
    qWorkingFullTime,
    qDaysWorkFromHome,
    qHoursADayWFH,
    qTimeOffDays,
  })
  const [threshold, setThreshold] = useState(30)
  const [step, setStep] = useState(1)

  const qAOptions = [
    { ...HOME_OFFICE.QUESTIONS.WORKING_FULL_TIME.OPTIONS.YES },
    { ...HOME_OFFICE.QUESTIONS.WORKING_FULL_TIME.OPTIONS.NO },
  ]

  useEffect(() => {
    const element = document.querySelector('.home-office-section__content')
    element.classList.add('home-office-section__fade-in')
  }, [])

  useEffect(() => {
    disabledNextButton()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersState])

  useEffect(() => {
    if (answersState.qWorkingFullTime === HOME_OFFICE.QUESTIONS.WORKING_FULL_TIME.OPTIONS.YES.value) {
      setAnswersState({
        ...answersState,
        qDaysWorkFromHome: emptyValue,
        qHoursADayWFH: emptyValue,
      })
      homeOfficeAnswersChanged(
        {
          qDaysWorkFromHome: emptyValue,
          qHoursADayWFH: emptyValue,
        },
        dispatch
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersState.qWorkingFullTime])

  const handlerChange = (e) => {
    const { name, value } = e.currentTarget
    setAnswersState({
      ...answersState,
      [name]: value,
    })
    homeOfficeAnswersChanged(
      {
        [name]: value,
      },
      dispatch
    )
  }

  const handlerQB = () => (value) => {
    if (value > 0 && value <= 29) {
      setThreshold(30)
      setStep(1)
    }
    if (value >= 30 && value <= 89) {
      value = Math.ceil(value / 5) * 5
      setThreshold(1)
      setStep(5)
    }
    if (value >= 90 && value <= 365) {
      value = Math.ceil(value / 30) * 30
      setThreshold(1)
      setStep(30)
    }
    setAnswersState({
      ...answersState,
      qTimeOffDays: value,
    })
    homeOfficeAnswersChanged({ qTimeOffDays: value }, dispatch)
  }

  const disabledNextButton = () => {
    let disabled = false
    for (let answer of Object.values(answersState)) {
      if (!answer) {
        disabled = true
      }
    }

    if (answersState.qWorkingFullTime === 'yes') {
      localStorage.setItem(
        'userResponse',
        JSON.stringify({
          homeConsumption: {
            homeOffice: {
              qWorkingFullTime: 'yes',
            },
          },
        })
      )
      disabled = false
    }

    if (answersState.qWorkingFullTime === 'no' && answersState.qDaysWorkFromHome === '0') {
      disabled = false
    }

    if (
      answersState.qWorkingFullTime === 'no' &&
      answersState.qDaysWorkFromHome !== '0' &&
      answersState.qHoursADayWFH
    ) {
      disabled = false
    }

    setDisableButton(disabled)
  }

  const ContentNo = () => {
    return (
      <div className='home-office-section__sub-content'>
        <Question title={HOME_OFFICE.QUESTIONS.AA.TITLE} description={HOME_OFFICE.QUESTIONS.AA.DESCRIPTION}>
          <ButtonList
            name='qDaysWorkFromHome'
            counter={HOME_OFFICE.QUESTIONS.AA.OPTIONS.COUNTER_OPTIONS}
            initText={HOME_OFFICE.QUESTIONS.AA.OPTIONS.NO_DAYS}
            valueSelected={answersState.qDaysWorkFromHome}
            handlerValueSelected={handlerChange}
          ></ButtonList>
        </Question>
        {answersState.qDaysWorkFromHome === '0' ? null : (
          <Question title={HOME_OFFICE.QUESTIONS.AB.TITLE} description={HOME_OFFICE.QUESTIONS.AB.DESCRIPTION}>
            <ButtonList
              name='qHoursADayWFH'
              counter={HOME_OFFICE.QUESTIONS.AB.OPTIONS.COUNTER_OPTIONS}
              initValue={1}
              valueSelected={answersState.qHoursADayWFH}
              handlerValueSelected={handlerChange}
            ></ButtonList>
          </Question>
        )}
      </div>
    )
  }

  const ThumbnailHomeOffice = (props) => {
    const days = props['aria-valuenow']
    return (
      <span {...props} className={`${props.className} home-office-section__thumb`}>
        <span className='slider-value home-office-section__thumb-value'>{`${days} ${props['aria-valuetext']}`}</span>
        <img className='home-office-section__thumb-img' src='/assets/flip-flops.svg' alt='days of time off' />
      </span>
    )
  }

  return (
    <div className='home-office-section__content'>
      <Heading addClass='home-office-section__title' level='h1'>
        {HOME_OFFICE.TITLE}
      </Heading>

      <form id='homeOfficeForm' className='home-office-section__form'>
        <Question
          title={HOME_OFFICE.QUESTIONS.WORKING_FULL_TIME.TITLE}
          description={HOME_OFFICE.QUESTIONS.WORKING_FULL_TIME.DESCRIPTION}
        >
          <OptionButtons
            name='qWorkingFullTime'
            row
            value={answersState.qWorkingFullTime}
            handlerOnChange={handlerChange}
            options={qAOptions}
          />
        </Question>

        {answersState.qWorkingFullTime === HOME_OFFICE.QUESTIONS.WORKING_FULL_TIME.OPTIONS.NO.value && <ContentNo />}
        {(answersState.qWorkingFullTime === HOME_OFFICE.QUESTIONS.WORKING_FULL_TIME.OPTIONS.YES.value ||
          answersState.qWorkingFullTime === HOME_OFFICE.QUESTIONS.WORKING_FULL_TIME.OPTIONS.NO.value) && (
          <div className='home-office-section__sub-content'>
            <Question title={HOME_OFFICE.QUESTIONS.B.TITLE}>
              <LabeledSlider
                value={answersState.qTimeOffDays}
                defaultValue={0}
                min={0}
                max={365}
                labeled
                suffix='days'
                stepThreshold={{
                  threshold,
                  step,
                }}
                ThumbComponent={ThumbnailHomeOffice}
                onChange={debounce(handlerQB(), 10)}
              />
            </Question>
          </div>
        )}
      </form>
    </div>
  )
}

export default HomeOfficeSection
