const calculateEmailsSent = (totalGloberEmissionKgCO2) => {
  const sequesterKgCO2 = 0.0003
  const countEmail = 1
  return Math.ceil((totalGloberEmissionKgCO2 * countEmail) / sequesterKgCO2)
}

const calculateZoomHours = (totalGloberEmissionKgCO2) => {
  const reducedKgCO2 = 0.01
  const countZoomHours = 1
  return Math.ceil((totalGloberEmissionKgCO2 * countZoomHours) / reducedKgCO2)
}

const calculateNetflixHours = (totalGloberEmissionKgCO2) => {
  const equivalentKgCO2 = 0.055
  const countNetflixHours = 1
  return Math.ceil((totalGloberEmissionKgCO2 * countNetflixHours) / equivalentKgCO2)
}

const calculateCarbonFootprintEquivalences = (totalGloberEmissionKgCO2) => {
  const emailsSent = calculateEmailsSent(totalGloberEmissionKgCO2)
  const zoomHours = calculateZoomHours(totalGloberEmissionKgCO2)
  const netflixHours = calculateNetflixHours(totalGloberEmissionKgCO2)
  return { emailsSent, zoomHours, netflixHours }
}

export default calculateCarbonFootprintEquivalences
