import React from 'react'
import styles from './CheckedButtons.module.scss'
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core'

const CheckedButtons = ({ row = false, selectedFields: { fields, setFields }, labels = [], limitedWidth = false }) => {
  const toggle = (event, option) => {
    if (event.currentTarget.checked) {
      setFields((field) => [...field, option])
    } else {
      setFields(fields.filter((field) => field !== option))
    }
  }

  return (
    <FormControl component='fieldset'>
      <FormGroup row={row}>
        {labels.map((label) => (
          <FormControlLabel
            className={limitedWidth ? styles.optionLimitedWidth : styles.option}
            label={label}
            key={label}
            control={<StyledCheck checked={fields.includes(label)} onChange={(e) => toggle(e, label)} name={label} />}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

function StyledCheck(props) {
  return (
    <Checkbox
      checkedIcon={<span className={styles.checkedIcon} />}
      icon={<span className={styles.icon} />}
      {...props}
    />
  )
}

export default CheckedButtons
