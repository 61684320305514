import { isEmpty, find as _find, indexOf } from 'lodash'
import React, { createRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import Checkbox from '../Checkbox/Checkbox'
import Strings from '../../constants/Strings'

import './CheckboxGroup.scss'

export default function CheckboxGroup({ items = [], onChange = () => {}, type = '', index = 0 }) {
  let textInputRef = createRef()
  const [inputValue, setInputValue] = useState('')
  const otherInputValue = _find(items, ['name', 'otherText'])
  const otherInputTextIndex = indexOf(items, otherInputValue)

  useEffect(() => {
    const inputValue = isEmpty(otherInputValue) ? '' : otherInputValue.value

    setInputValue(inputValue)
  }, [items, otherInputValue])

  const handleCheckboxButton = (value, status) => {
    if (value === 'Other' && status) textInputRef.current.focus()

    const actualInputTextIndex = indexOf(items, otherInputValue)

    if (actualInputTextIndex > -1 && value === 'Other' && status !== true) items.splice(actualInputTextIndex, 1)

    const selectedItems = items.includes(value) ? items.filter((item) => item !== value) : [...items, value]

    const data = isEmpty(selectedItems) ? undefined : selectedItems

    onChange(data, type, index)
  }

  const handleInputChange = (event) => {
    const characterLimit = 50
    const value = event.target.value
    if (value.length > characterLimit) return

    const updateOtherText = () => {
      const updateItems = [
        ...items.slice(0, otherInputTextIndex),
        { name: 'otherText', value },
        ...items.slice(otherInputTextIndex + 1),
      ]

      return updateItems
    }

    const selectedItems = otherInputValue ? updateOtherText() : [...items, { name: 'otherText', value }]
    setInputValue(value)
    onChange(selectedItems, type, index)
  }

  const classNameOther = classNames('checkbox-group--other', {
    'checkbox-group--other__selected': items.includes('Other'),
  })

  return (
    <ul className='checkbox-group'>
      <li className='checkbox-group--item'>
        <Checkbox
          name='comfort'
          checked={items.includes('comfort')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_1}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='speed'
          checked={items.includes('speed')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_2}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='cost'
          checked={items.includes('cost')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_3}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='convenience'
          checked={items.includes('convenience')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_4}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='Personal security'
          checked={items.includes('Personal security')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_5}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='Lower risk of accident'
          checked={items.includes('Lower risk of accident')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_6}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='No access to public transportation'
          checked={items.includes('No access to public transportation')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_7}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='Health'
          checked={items.includes('Health')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_8}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='Physical disability'
          checked={items.includes('Physical disability')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_9}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='Commitments'
          checked={items.includes('Commitments')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_10}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='Environmental reasons'
          checked={items.includes('Environmental reasons')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_11}
        />
      </li>
      <li className='checkbox-group--item'>
        <Checkbox
          name='Other'
          checked={items.includes('Other')}
          onPress={handleCheckboxButton}
          label={Strings.VEHICLE_CHOOSING_12}
        />
        <div className={classNameOther}>
          <span>:</span>
          <input
            className='checkbox-group--other-input'
            type='text'
            name='Other'
            value={inputValue}
            onChange={handleInputChange}
            ref={textInputRef}
          />
        </div>
      </li>
    </ul>
  )
}
