import React from 'react'

const ArrowUp = ({ fill }) => (
  <svg color='#ffff00' width='19' height='22' viewBox='0 0 19 22' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.8419 18.8437L9.84191 2.8181M9.84191 2.8181L16.6406 9.76655M9.84191 2.8181L3.04319 9.76655'
      stroke={fill || '#FF9696'}
      strokeWidth='4.40468'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ArrowUp
