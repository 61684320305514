import { isEmpty } from 'lodash'
import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Jumbotron from '../../components/Jumbotron/Jumbotron'
import Dropdown from '../../components/Dropdown/Dropdown'
import Centering from '../../layout/Centering/Centering'
import Strings from '../../constants/Strings'
import StoreContext from '../../providers/StoreContext'
import { WelcomeModal } from '../../components/Modal/Modal'

import { cityChanged, countryChanged, resetCountrySelected } from '../../store/actions'
import { countriesCities } from './countriesCities'

import './CountrySelection.scss'

const getCitiesByCountry = (country) => {
  if (isEmpty(country)) return []

  const filterCountrySelected = countriesCities.filter((data) => data.country === country)

  return filterCountrySelected[0].cities
}

const CountrySelection = () => {
  const {
    state,
    state: { countrySelected },
    dispatch,
  } = useContext(StoreContext)
  const { city, country } = countrySelected
  const [cityState, setCityState] = useState(city)
  const [countryState, setCountryState] = useState(country)
  const [listOfCities, setListOfCities] = useState(getCitiesByCountry(country))
  const history = useHistory()
  const userData = JSON.parse(localStorage.getItem('userResponse'))
  const sortedOptions = (data) => data.sort((a, b) => a.localeCompare(b))

  const handleOptions = () => {
    const filterByCountry = countriesCities.map((data) => data['country'])
    const sortOptions = sortedOptions(filterByCountry)

    return sortOptions
  }

  const resetState = () => {
    setListOfCities(undefined)
    setCountryState(null)
    setCityState(null)
    resetCountrySelected(dispatch)
  }

  const handleCountrySelected = (_evt, location) => {
    if (isEmpty(location)) return resetState()

    const getCities = getCitiesByCountry(location)

    setListOfCities(getCities)
    setCountryState(location)
    countryChanged(location, dispatch)
  }

  const handleCitySelected = (_evt, location) => {
    setCityState(location)
    cityChanged(location, dispatch)
  }

  const handleNextButton = () => {
    const newState = {
      ...state,
      displayName: userData.displayName,
      totalWFHEmissions: userData.WFHEmission,
      currentSection: 0,
    }
    localStorage.setItem('userResponse', JSON.stringify(newState))
    history.push('/home-consumption')
  }

  const validateNextButton = !isEmpty(country) && !isEmpty(city)

  useEffect(() => {
    const element = document.querySelector('.country-selection--wrapper')
    element.classList.add('country-selection__fade-in')
  }, [])

  return (
    <div>
      <WelcomeModal />

      <Centering
        backFooterButton={{ hide: true }}
        nextFooterButton={{
          handleOnClick: handleNextButton,
          disable: !validateNextButton,
        }}
      >
        <div className='country-selection--wrapper'>
          <Jumbotron addClass='country-selection--jumbotron' title={Strings.SELECTION_TITLE_CITY} />
          <div className='country-selection--dropdown'>
            <Dropdown
              options={handleOptions()}
              placeholder={Strings.SELECTION_PLACEHOLDER_COUNTRY}
              onChange={handleCountrySelected}
              value={countryState}
            />
          </div>
          <div className='country-selection--dropdown'>
            <Dropdown
              options={listOfCities}
              placeholder={Strings.SELECTION_PLACEHOLDER_CITY}
              onChange={handleCitySelected}
              value={cityState}
            />
          </div>
        </div>
      </Centering>
    </div>
  )
}

export default CountrySelection
