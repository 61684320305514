import React from 'react'
import Heading from '../../components/Heading/Heading'
import Centering from '../../layout/Centering/Centering'
import Strings from '../../constants/Strings'
import Lottie from 'react-lottie'

import './Loading.scss'
import withAuthentication from '../../hoc/withEnableDisableWeeklyDays/withAuthentication'

const Loading = () => {
  const animationData = require('../../assets/static/loadingData.json')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    renderer: 'svg',
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Centering hideHeaderMenu hideFooterActions hideFooterMap>
      <Lottie options={defaultOptions} width={150} height={40} />

      <Heading level='h4' addClass='loading--title'>
        {Strings.LOADING_TEXT}
      </Heading>
    </Centering>
  )
}

export default withAuthentication(Loading)
