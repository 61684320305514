import React from 'react'

const WalkingIcon = () => (
  <svg width='32' height='72' viewBox='0 0 32 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill='none'
      strokeWidth='2'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.5751 2C19.9931 2 23.5751 5.582 23.5751 10C23.5751 13.088 21.8251 15.768 19.2621 17.101C21.7681 18.523 23.4811 21.181 23.5711 24.245L23.5751 24.5L23.5741 31.278L29.1651 33.645C30.3551 34.149 30.9131 35.522 30.4091 36.713C30.3341 36.89 30.2381 37.057 30.1221 37.21L29.7271 37.734C28.8981 38.834 27.4271 39.228 26.1601 38.69L23.5741 37.593V39.5C23.5741 41.516 22.8731 43.368 21.7001 44.826L29.4981 66.25C30.0651 67.807 29.2621 69.529 27.7051 70.095C26.1481 70.662 24.4271 69.859 23.8601 68.302L16.4311 47.892C16.2081 47.928 15.9811 47.955 15.7511 47.973L14.6281 58.683L8.27615 68.843C7.56915 69.973 6.20715 70.496 4.93615 70.146L4.75615 70.09L3.81315 69.765C3.62015 69.699 3.43515 69.61 3.26115 69.502C2.05415 68.745 1.65415 67.187 2.32115 65.95L2.41115 65.797L8.21315 56.533L9.35015 45.783C7.64515 44.23 6.57515 41.99 6.57515 39.5V24.5C6.57515 21.119 8.54914 18.198 11.4081 16.83C9.10914 15.424 7.57515 12.891 7.57515 10C7.57515 5.582 11.1561 2 15.5751 2Z'
    />
    <path
      fill='none'
      strokeWidth='2'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.4491 23.302L21.0531 30.212L22.6911 30.905L29.1651 33.645C30.3551 34.149 30.9131 35.522 30.4091 36.713C30.3341 36.89 30.2381 37.057 30.1221 37.21L29.7271 37.734C28.8981 38.834 27.4271 39.228 26.1601 38.69L22.6481 37.2L17.4061 34.976L11.4231 27.586C10.5561 26.515 10.5301 24.992 11.3591 23.892L11.7541 23.368C12.5341 22.333 14.0041 22.127 15.0391 22.906C15.1911 23.021 15.3291 23.154 15.4491 23.302V23.302Z'
    />
  </svg>
)

export default WalkingIcon
