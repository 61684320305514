import Car from './components/Car/Car'
import PublicTransport from './components/PublicTransport/PublicTransport'
import EcoFriendly from './components/EcoFriendly/EcoFriendly'

import Strings from '../../constants/Strings'

const vehicleClassification = {
  car: {
    component: Car,
    label: Strings.SELECTION_SECTION_CAR,
    type: 'car',
    engineTypes: [
      { type: 'gasoline', text: Strings.VEHICLE_TYPE_1 },
      { type: 'diesel', text: Strings.VEHICLE_TYPE_2 },
      { type: 'naturalGas', text: Strings.VEHICLE_TYPE_3 },
      { type: 'hybrid', text: Strings.VEHICLE_TYPE_4 },
      { type: 'electricPower', text: Strings.VEHICLE_TYPE_5 },

    ],
  },
  taxi: {
    component: PublicTransport,
    label: Strings.SELECTION_SECTION_TAXI,
    type: 'publicTransport',
  },
  bus: {
    component: PublicTransport,
    label: Strings.SELECTION_SECTION_BUS,
    type: 'publicTransport',
  },
  combi: {
    component: PublicTransport,
    label: Strings.SELECTION_SECTION_COMBI,
    type: 'publicTransport',
  },
  subway: {
    component: PublicTransport,
    label: Strings.SELECTION_SECTION_TRAIN,
    type: 'publicTransport',
  },
  motorcycle: {
    component: Car,
    label: Strings.SELECTION_SECTION_MOTORCYCLE,
    type: 'motocycle',
    engineTypes: [{ type: 'gasoline', text: Strings.VEHICLE_TYPE_1 }],
  },
  scooter: {
    component: EcoFriendly,
    label: Strings.SELECTION_SECTION_SCOOTER,
    type: 'ecoFriendly',
  },
  bike: {
    component: EcoFriendly,
    label: Strings.SELECTION_SECTION_BICYCLE,
    type: 'ecoFriendly',
  },
  walking: {
    component: EcoFriendly,
    label: Strings.SELECTION_SECTION_WALKING,
    type: 'ecoFriendly',
  },
  home: {
    component: EcoFriendly,
    label: Strings.SELECTION_SECTION_WFO,
    type: 'ecoFriendly',
  },
}

export default vehicleClassification
