import React, { useContext, useEffect } from 'react'
import Strings from '../../constants/Strings'
import Centering from '../../layout/Centering/Centering'
import StoreContext from '../../providers/StoreContext'
import MagicContext from '../../providers/MagicContext'
import { resetStore, resetMagicStore } from '../../store/actions'
import { logOut } from '../../auth/auth'

import { Summary } from './components/Summary'
import { InitiativesSection } from './components/InitiativesSection'
import { InfoModal } from './components/InfoIcon'
import { Equivalence } from '../WelcomeBack/components/Equivalence/Equivalence'

import './Result.scss'

const Result = () => {
  const {
    dispatch,
    state: { totalGHGEmissions, totalWFHEmissions },
  } = useContext(StoreContext)
  const { dispatch: magicDispatch } = useContext(MagicContext)
  const {
    RESULT: { EMISSIONS },
  } = Strings

  const userData = JSON.parse(localStorage.getItem('userResponse'))
  const yourEmissions = totalGHGEmissions + totalWFHEmissions

  let firstName = ''
  if (userData.displayName) {
    firstName = userData.displayName.split(' ')[0] || 'Glober'
  } else {
    firstName = 'Glober'
  }
  useEffect(() => { (async () => {
      await logOut()
      resetStore(dispatch)
      resetMagicStore(magicDispatch)
    })();
  }, [dispatch, magicDispatch])

  // fade-in effect when load the page
  useEffect(() => {
    const element = document.querySelector('.result__content')
    if (!element) window.location.reload()
    const timer = setTimeout(() => {
      element.classList.add('result__fade-in')
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <Centering addClass='result' hideFooterActions>
      <div className='result__content'>
        <div className='result__heading-container'>
          <h1 className='result__title'>
            {EMISSIONS.D_TITLE} {firstName}!
          </h1>
          <h4 className='result__subtitle'>
            {EMISSIONS.D_SUBTITLE}
            <InfoModal className='result__modal' />
          </h4>
        </div>
        <Summary />
        <Equivalence totalEmissions={yourEmissions} />
        <InitiativesSection />
      </div>
    </Centering>
  )
}

export default Result
