import React from 'react'
import PropTypes from 'prop-types'
import BreadcrumbItem from './component/BreadcrumbItem'
import classNames from 'classnames'

import './Breadcrumb.scss'

const Breadcrumb = ({ addClass, vehicles, activeVehicle }) => {
  const className = classNames('breadcrumb', {
    [addClass]: addClass,
  })

  return (
    <ol className={className}>
      {vehicles.map((item, index) => {
        return <BreadcrumbItem key={index} isActive={item === activeVehicle} addClass={addClass} type={item} />
      })}
    </ol>
  )
}

Breadcrumb.propTypes = {
  addClass: PropTypes.string,
  vehicles: PropTypes.array.isRequired,
  activeVehicle: PropTypes.string,
}

Breadcrumb.defaultProps = {
  addClass: '',
  vehicles: [],
  activeVehicle: '',
}

export default Breadcrumb
