import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { IconComponent } from '../../../utils/iconType'

import './BreadcrumbItem.scss'

const BreadcrumbItem = ({ isActive, type, addClass }) => {
  const className = classNames('breadcrumb-item', {
    'breadcrumb-item--active': isActive,
    [addClass]: addClass,
  })

  return <li className={className}>{IconComponent(type)}</li>
}

BreadcrumbItem.propTypes = {
  addClass: PropTypes.string,
  isActive: PropTypes.bool,
  type: PropTypes.string,
}

BreadcrumbItem.defaultProps = {
  addClass: '',
  isActive: false,
  type: '',
}

export default BreadcrumbItem
