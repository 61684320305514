import { every } from 'lodash'
import { useToasts } from 'react-toast-notifications'
import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom'
import classNames from 'classnames'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import Heading from '../../components/Heading/Heading'
import Strings from '../../constants/Strings'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import StoreContext from '../../providers/StoreContext'
import vehicleClassification from './vehicleClassification'
import {
  vehiclesDataChanged,
  carbonFootprintChange,
  errorRequest,
  workCarbonFootprintEquivalencesChanged,
} from '../../store/actions'
import { createProject } from '../../auth/auth'
import { mad, bug, flame } from '../../utils/prettyMessages'

import './Trips.scss'
import Loading from '../Loading/Loading'
import { ecoList } from '../../utils/generalHelper'
import calculateCarbonFootprintEquivalences from '../../utils/workCarbonFootprintEquivalences'
import OptionButtons from '../../components/new-components/OptionButtons/OptionButtons'
import Question from '../../components/new-components/Question/Question'
import { auth } from '../../services/firebase'

export const Trips = () => {
  const { addToast } = useToasts()
  const history = useHistory()
  const {
    path,
    params: { vehicle },
  } = useRouteMatch()
  const [isSecondTripEnable, setIsSecondTripEnable] = useState(false)
  const [isLoadingNextButton, setIsLoadingNextButton] = useState(false)
  const [isTyCEnable, setTyCEnable] = useState(false)

  const {
    state,
    state: { totalGHGEmissions, totalWFHEmissions },
    dispatch,
  } = useContext(StoreContext)
  const { vehiclesSelected, vehiclesSelectedData, requestStatus, countrySelected } = state
  const { sending } = requestStatus

  const user = auth().currentUser
  const userData = JSON.parse(localStorage.getItem('userResponse'))
  const { displayName } = user || userData

  const className = classNames('trips', {
    trips__ecoFriendly: vehicleClassification[vehicle].type === 'ecoFriendly',
  })

  const getContextVehicle = () => vehiclesSelectedData.filter((data) => data.vehicle === vehicle)

  const validateNextButton = () => {
    const getContextData = getContextVehicle()
    const { trips } = getContextData[0]

    const tripsValues = trips.map((currentTrip) => {
      const questions = Object.values(currentTrip)
      const values = questions.map((question) => question.value)
      return values
    })

    const isEnable = every(...tripsValues, Boolean) && isTyCEnable

    return !isEnable
  }
  const {
    HOME_CONSUMPTION: {
      SECTIONS: {
        ELECTRICITY_USE: { QUESTIONS },
      },
    },
  } = Strings
  const { TyC } = QUESTIONS
  const TyCOptions = [...Object.values(TyC.OPTIONS)]

  useEffect(() => {
    const getContextData = vehiclesSelectedData.filter((data) => data.vehicle === vehicle)

    setIsSecondTripEnable(getContextData[0].secondTrip)

    if (sending) {
      let totalGloberEmissions = totalGHGEmissions + totalWFHEmissions
      const e = calculateCarbonFootprintEquivalences(totalGloberEmissions)
      workCarbonFootprintEquivalencesChanged(e, dispatch)
      const savingCarbonFootprintData = async () => {
        try {
          const newState = {
            ...state,
            countrySelected: userData.countrySelected ? userData.countrySelected : countrySelected,
            displayName: userData.displayName ? userData.displayName : displayName,
          }
          window.scrollTo(0, 0)
          // console.log("newState", newState)
          createProject(newState)
          localStorage.setItem('userResponse', JSON.stringify(newState))
          history.replace('/result')
        } catch (err) {
          setIsLoadingNextButton(false)
          errorRequest(dispatch)
          console.error(`${mad} ${mad} ${bug} ${flame} ${flame} ${flame} ${flame} SEND INFORMATION: `, err)
          addToast('Could you please try again, something happened', {
            appearance: 'error',
            autoDismiss: true,
          })
          history.replace('/selection')
        }
      }

      savingCarbonFootprintData()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [vehicle, vehiclesSelectedData, requestStatus, state, sending, addToast, history, dispatch])

  const handleBackButtonClick = () => history.goBack()

  const handleNextButtonClick = () => {
    const activeVehicleIndex = vehiclesSelected.indexOf(vehicle)
    const nextIndex = activeVehicleIndex + 1

    if (nextIndex === vehiclesSelected.length) {
      setIsLoadingNextButton(true)

      return carbonFootprintChange(dispatch)
    }

    history.push(
      generatePath(path, {
        vehicle: vehiclesSelected[nextIndex],
      })
    )
  }

  const handleVehicleChanges = (tripIndex, optionData) => {
    const activeVehicleIndex = vehiclesSelected.indexOf(vehicle)

    vehiclesDataChanged(tripIndex, activeVehicleIndex, optionData, dispatch)
  }

  const displayNextButtonText = () => {
    const copyOfVehiclesSelected = [...vehiclesSelected]
    const lastItem = copyOfVehiclesSelected.pop()

    return lastItem === vehicle ? Strings.CARBON_FOOTPRINT_BUTTON : Strings.CONTINUE_BUTTON
  }

  const renderTitle = () => {
    if (ecoList.includes(vehicle))
      return (
        <Heading addClass='trips--ecoHeading' level='h1'>
          {vehicleClassification[vehicle].label}
        </Heading>
      )

    return (
      <Heading addClass='trips--heading' level='h1'>
        {vehicleClassification[vehicle].label}
      </Heading>
    )
  }

  const renderBreadcrumb = (
    <Breadcrumb addClass='trips--breadcrumb' vehicles={vehiclesSelected} activeVehicle={vehicle} />
  )

  const renderTypeOfVehicle = (index) => {
    const { component: Vehicle, engineTypes, type } =
      vehicleClassification[vehicle] || vehicleClassification['home'].component
    const getContextData = getContextVehicle()
    const { trips } = getContextData[0]

    return (
      <Vehicle
        tripsData={trips[index]}
        onOptionsChange={handleVehicleChanges}
        index={index}
        engineTypes={engineTypes}
        type={type}
      />
    )
  }

  const renderFooter = () => {
    return (
      <Footer
        addClass={isSecondTripEnable && 'trips--second-trip-footer'}
        backButton={{
          handleOnClick: handleBackButtonClick,
        }}
        nextButton={{
          handleOnClick: handleNextButtonClick,
          disable: validateNextButton(),
          text: displayNextButtonText(),
          loading: isLoadingNextButton,
        }}
      />
    )
  }

  if (sending) return <Loading />

  const handlerChange = () => {
    setTyCEnable(!isTyCEnable)
  }

  return (
    <div className={className}>
      <Header />
      <div className='trips--holder'>
        <main className='trips--section'>
          {renderBreadcrumb}
          {renderTitle()}
          {renderTypeOfVehicle(0)}
          <Question description={TyC.DESCRIPTION}>
            <OptionButtons name='qTyC' row options={TyCOptions} handlerOnChange={handlerChange} />
          </Question>
        </main>
      </div>
      {renderFooter()}
    </div>
  )
}

export default Trips
