import React from 'react'
import classNames from 'classnames'

import './Button.scss'

const Button = ({ disabled = false, onClick = () => {}, addClass = undefined, children, loading = false }) => {
  const className = classNames('button', {
    'button--disabled': disabled || loading,
    [addClass]: addClass,
  })

  const renderSpinner = loading && (
    <div className='spinner'>
      <div className='bounce1'></div>
      <div className='bounce2'></div>
      <div className='bounce3'></div>
    </div>
  )

  return (
    <button onClick={onClick} className={className} disabled={disabled}>
      {children}
      {renderSpinner}
    </button>
  )
}

export default Button
