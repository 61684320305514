import React from 'react'

const TaxiIcon = () => (
  <svg width='79' height='54' viewBox='0 0 79 54' fill='none' strokeWidth='0.1' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M76.007 44H68.947C68.374 39.063 63.907 35.524 58.97 36.098C54.82 36.579 51.548 39.852 51.067 44H22.947C22.374 39.063 17.907 35.524 12.969 36.098C8.82101 36.579 5.54901 39.852 5.06701 44H2.00701V31.12L7.21701 10.27C7.55201 8.936 8.75101 8 10.127 8H12.727L8.28701 25.76C8.21101 26.06 8.27801 26.376 8.46701 26.62C8.65801 26.861 8.94901 27.001 9.25701 27H67.747L76.007 31.59V44ZM60.007 52C56.141 52 53.007 48.866 53.007 45C53.007 41.134 56.141 38 60.007 38C63.873 38 67.007 41.134 67.007 45C67.007 48.866 63.873 52 60.007 52ZM14.007 52C10.141 52 7.00701 48.866 7.00701 45C7.00701 41.134 10.141 38 14.007 38C17.873 38 21.007 41.134 21.007 45C21.007 48.866 17.873 52 14.007 52ZM36.377 8V25H10.537L14.787 8H36.377ZM38.377 8H51.067C51.987 7.998 52.857 8.418 53.427 9.14L65.947 25H38.377V8ZM36.5 0.5C36.878 0.5 37.22 0.713 37.39 1.044L37.44 1.158L39.2 6H51.067C52.517 5.995 53.894 6.62 54.844 7.704L55.007 7.9L68.667 25.22L77.497 30.13C77.812 30.307 78.007 30.64 78.007 31V45C78.007 45.552 77.56 46 77.007 46H68.947C68.467 50.148 65.194 53.421 61.044 53.902C56.107 54.476 51.64 50.937 51.067 46H22.947C22.465 50.148 19.193 53.421 15.045 53.902C10.107 54.476 5.64001 50.937 5.06701 46H1.00701C0.455012 46 0.00701172 45.552 0.00701172 45V31C-0.00298828 30.92 -0.00298828 30.84 0.00701172 30.76L5.27701 9.79C5.83201 7.563 7.83201 6 10.127 6H28.8L30.561 1.158C30.691 0.803 31.008 0.554 31.376 0.508L31.5 0.5H36.5ZM35.8 2.5H32.199L30.927 6H37.072L35.8 2.5Z'
    />
    <path d='M27.5 31H24.5C24.2239 31 24 31.2239 24 31.5V34.5C24 34.7761 24.2239 35 24.5 35H27.5C27.7761 35 28 34.7761 28 34.5V31.5C28 31.2239 27.7761 31 27.5 31Z' />
    <path d='M31.5 35H28.5C28.2239 35 28 35.2239 28 35.5V38.5C28 38.7761 28.2239 39 28.5 39H31.5C31.7761 39 32 38.7761 32 38.5V35.5C32 35.2239 31.7761 35 31.5 35Z' />
    <path d='M35.5 31H32.5C32.2239 31 32 31.2239 32 31.5V34.5C32 34.7761 32.2239 35 32.5 35H35.5C35.7761 35 36 34.7761 36 34.5V31.5C36 31.2239 35.7761 31 35.5 31Z' />
    <path d='M39.5 35H36.5C36.2239 35 36 35.2239 36 35.5V38.5C36 38.7761 36.2239 39 36.5 39H39.5C39.7761 39 40 38.7761 40 38.5V35.5C40 35.2239 39.7761 35 39.5 35Z' />
    <path d='M47.5 35H44.5C44.2239 35 44 35.2239 44 35.5V38.5C44 38.7761 44.2239 39 44.5 39H47.5C47.7761 39 48 38.7761 48 38.5V35.5C48 35.2239 47.7761 35 47.5 35Z' />
    <path d='M43.5 31H40.5C40.2239 31 40 31.2239 40 31.5V34.5C40 34.7761 40.2239 35 40.5 35H43.5C43.7761 35 44 34.7761 44 34.5V31.5C44 31.2239 43.7761 31 43.5 31Z' />
    <path d='M51.5 31H48.5C48.2239 31 48 31.2239 48 31.5V34.5C48 34.7761 48.2239 35 48.5 35H51.5C51.7761 35 52 34.7761 52 34.5V31.5C52 31.2239 51.7761 31 51.5 31Z' />
  </svg>
)

export default TaxiIcon
