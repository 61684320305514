import React, { useCallback, useContext, useEffect, createRef, Fragment, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import Centering from '../../layout/Centering/Centering'
import Strings from '../../constants/Strings'
import Jumbotron from '../../components/Jumbotron/Jumbotron'
import Button from '../../components/Button/Button'
import { signInWithGoogle, sessionPersistence, validateAccountCheck, logOut } from '../../auth/auth'
import { authenticationChange } from '../../store/actions'
import MagicContext from '../../providers/MagicContext'
import { mad, bug, flame } from '../../utils/prettyMessages'
import CircularProgress from '@material-ui/core/CircularProgress'
import './Login.scss'
import withPublicAuthenticated from '../../hoc/withEnableDisableWeeklyDays/withPublicAuthenticated'

const { REACT_APP_HOSTED_DOMAIN } = process.env

const LoginPage = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const { dispatch: magicDispatch } = useContext(MagicContext)
  const [isLoading, setIsLoading] = useState(false)

  const centeringLayoutRef = createRef()

  const handleGoogleLogin = useCallback(async () => {
    try {
      let redirectTo
      setIsLoading(true)

      if (REACT_APP_HOSTED_DOMAIN === 'localhost') {
        authenticationChange(true, magicDispatch)
      }

      await sessionPersistence()
      await signInWithGoogle()
      const restrictAuth = await validateAccountCheck()

      if (restrictAuth) {
        authenticationChange(true, magicDispatch)
      } else {
        authenticationChange(false, magicDispatch)
        setIsLoading(false)
        await logOut()
        redirectTo = '/address-not-found'
      }

      return history.push(redirectTo)
    } catch (err) {
      console.error(`${mad} ${bug} ${flame} ${flame} LOGIN ERROR: `, err)
      addToast(err.message, { appearance: 'error', autoDismiss: true })
      setIsLoading(false)
    }
  }, [history, magicDispatch, addToast])

  useEffect(() => {
    const el = centeringLayoutRef.current
    el.classList.add('login--centering__fade-in')
  }, [centeringLayoutRef])

  return (
    <Centering
      hasFooterAdditionalInformation
      hasFooterTermsAndConditions
      hideFooterActions
      hideHeaderMenu
      ref={centeringLayoutRef}
      addClass='login--centering'
    >
      {isLoading && <CircularProgress style={{ color: '#ffffff' }} />}
      {!isLoading && (
        <Fragment>
          <h1 className='login__title'>Work Carbon Footprint</h1>
          <Jumbotron addClass='login--jumbotron'>{Strings.LOGIN_DESCRIPTION_TEXT}</Jumbotron>
          <Button onClick={handleGoogleLogin}>{Strings.LOGIN_START_SESSION_BUTTON}</Button>
        </Fragment>
      )}
    </Centering>
  )
}

export default withPublicAuthenticated(LoginPage)
