const emissionFactorByKm = {
  car: {
    gasoline: 0.2434,
    diesel: 0.2821,
    naturalGas: 0.1731,
    electricPower: 0.15,
    hybrid: 0.000128,
  },
  taxi: {
    diesel: 0.1492,
  },
  bus: {
    diesel: 0.1041,
  },
  combi: {
    diesel: 0.1041,
  },
  subway: {
    electricPower: 0.00041
  },
  motorcycle: {
    gasoline: 0.1114,
  },
}

export function getVehicleEmissionFactorByKm(vehicle, fuelUsed) {
  return emissionFactorByKm[vehicle][fuelUsed]
}
