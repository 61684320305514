import React from 'react'
import styles from './OptionButtons.module.scss'
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import PropTypes from 'prop-types'

const OptionButtons = ({ name, row = false, value, handlerOnChange, options = [], limitedWidth = false }) => {
  return (
    <FormControl component='fieldset'>
      <RadioGroup name={name} row={row} value={value} onChange={handlerOnChange}>
        {options.map((option, index) => (
          <FormControlLabel
            className={limitedWidth ? styles.optionLimitedWidth : styles.option}
            label={option.label}
            value={option.value}
            key={index}
            control={<StyledRadio />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

function StyledRadio(props) {
  return (
    <Radio checkedIcon={<span className={styles.checkedIcon} />} icon={<span className={styles.icon} />} {...props} />
  )
}

OptionButtons.propTypes = {
  name: PropTypes.string.isRequired,
  row: PropTypes.bool,
  // value: PropTypes.string.isRequired,
  handlerOnChange: PropTypes.func.isRequired,
  options: PropTypes.array,
}

export default OptionButtons
