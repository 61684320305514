import React from 'react'

const ArrowDown = ({ fill }) => (
  <svg width='19' height='22' viewBox='0 0 19 22' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.12341 2.81812L9.1234 18.8438M9.1234 18.8438L2.3125 11.8953M9.1234 18.8438L15.9343 11.8953'
      stroke={fill || '#86ffbd'}
      strokeWidth='4.40468'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ArrowDown
