import React from 'react'

const SubwayIcon = () => (
  <svg viewBox='0 0 78 46' width='78' height='46' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        strokeWidth='0.1'
        d='M2 34.019h64.73c3.312.014 6.373-1.765 8-4.65 1.151-1.985 1.542-4.32 1.1-6.57-2.321.51-4.693.748-7.07.71-4.358-.048-8.698-.554-12.95-1.51-12.54-2.69-12.28-15.94-11.89-20H2v5h11c.552 0 1 .448 1 1v8c0 .552-.448 1-1 1H2v17.02zm0-19h10v-6H2v6zm54.21 5c10.56 2.26 16.78 1.35 19 .83-.151-.364-.324-.718-.52-1.06-6.005-10.376-16.831-17.033-28.8-17.71-.39 3.44-.95 15.55 10.32 17.96v-.02zM0 35.019v-34c0-.552.448-1 1-1h43.83c.122-.025.248-.025.37 0h.06c12.936.473 24.718 7.573 31.18 18.79.419.744.754 1.532 1 2.35v.07c.949 3.04.586 6.338-1 9.1-1.981 3.502-5.697 5.662-9.72 5.65H1c-.537 0-.979-.424-1-.96zm20-20h16v-6H20v6zm18-7v8c0 .552-.448 1-1 1H19c-.552 0-1-.448-1-1v-8c0-.552.448-1 1-1h18c.552 0 1 .448 1 1zm-37 35h6v-3c0-.553.448-1 1-1s1 .447 1 1v3h6v-3c0-.553.448-1 1-1s1 .447 1 1v3h6v-3c0-.553.448-1 1-1s1 .447 1 1v3h6v-3c0-.553.448-1 1-1s1 .447 1 1v3h6v-3c0-.553.448-1 1-1 .553 0 1 .447 1 1v3h6v-3c0-.553.447-1 1-1 .553 0 1 .447 1 1v3h6v-3c0-.553.447-1 1-1 .553 0 1 .447 1 1v3h6v-3c0-.553.447-1 1-1 .553 0 1 .447 1 1v3h6c.553 0 1 .447 1 1 0 .552-.447 1-1 1H1c-.552 0-1-.448-1-1 0-.553.448-1 1-1z'
      />
    </g>
  </svg>
)

export default SubwayIcon
