import React, { forwardRef } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import classNames from 'classnames'

import './Centering.scss'

const Centering = (
  {
    children = null,
    addClass,
    hideHeaderMenu,
    hideFooterActions,
    hasFooterAdditionalInformation,
    hasFooterTermsAndConditions,
    backFooterButton,
    nextFooterButton,
    renderAdditionalComponent = () => {},
    /* eslint-disable no-unused-vars */
    userIsLoggedIn = true,
    hideFooterMap = false,
  },
  ref
) => {
  const className = classNames('centering', {
    [addClass]: addClass,
  })

  return (
    <div className={className} ref={ref}>
      <Header hideMenu={hideHeaderMenu} />
      {renderAdditionalComponent()}
      <main className='centering--box'>{children}</main>
      <Footer
        hideActions={hideFooterActions}
        hasAdditionalInformation={hasFooterAdditionalInformation}
        hasTermsAndConditions={hasFooterTermsAndConditions}
        backButton={backFooterButton}
        nextButton={nextFooterButton}
        hideMap={hideFooterMap}
      />
    </div>
  )
}

Centering.displayName = 'Centering'

export default forwardRef(Centering)
