import React from 'react'

const DropdownArrow = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='22'
    height='12'
    viewBox='0 0 22 12'
  >
    <defs>
      <path
        id='prefix__a'
        d='M804.827 331.526l-.35-.351c-.232-.233-.607-.233-.839 0l-9.635 9.684-9.64-9.684c-.233-.233-.607-.233-.84 0l-.35.351c-.23.233-.23.61 0 .842l10.406 10.457c.231.233.606.233.838 0l10.405-10.457c.237-.232.237-.609.005-.842z'
      />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(-783 -331)'>
      <use fill='#FFF' fillRule='nonzero' xlinkHref='#prefix__a' />
    </g>
  </svg>
)

export default DropdownArrow
