import {
  SELECTION_VEHICLES,
  SELECTION_CITY,
  AUTHENTICATED,
  VEHICLE_TRIP_DATA,
  ADD_SECOND_TRIP,
  REMOVE_SECOND_TRIP,
  ESTIMATE_CARBON_FOOTPRINT,
  SURVEY_COMPLETED,
  WEEKLY_JOURNEYS_MADE_DISABLED,
  RESET_STORE,
  INITIAL_STATE,
  RESET_MAGIC_STORE,
  SENDING_REQUEST,
  ERROR_REQUEST,
  SELECTION_COUNTRY,
  RESET_COUNTRY,
  HOME_CONSUMPTION_CURRENT_SECTION,
  HOME_OFFICE_ANSWERS,
  DEVICES_ENERGY_ANSWERS,
  HOME_HEATING_ANSWERS,
  HOUSE_COOLING_GLOBAL_ANSWERS,
  HOUSE_COOLING_INDIA_ANSWERS,
  ESTIMATE_WORK_CARBON_FOOTPRINT,
  WORK_CARBON_FOOTPRINT_EQUIVALENCES,
  ELECTRICITY_USE_ANSWERS,
} from './types'

export const collectionOfVehiclesChange = (vehicles, dispatch) => {
  dispatch({
    type: SELECTION_VEHICLES,
    payload: vehicles,
  })
}

export const vehiclesDataChanged = (tripIndex, vehicleIndex, optionData, dispatch) => {
  dispatch({
    type: VEHICLE_TRIP_DATA,
    payload: { tripIndex, vehicleIndex, optionData },
  })
}

export const carbonFootprintChange = (dispatch) => {
  dispatch({
    type: ESTIMATE_CARBON_FOOTPRINT,
  })
}

export const resetStore = (dispatch) => {
  dispatch({
    type: RESET_STORE,
  })
}

export const resetMagicStore = (dispatch) => {
  dispatch({
    type: RESET_MAGIC_STORE,
  })
}

export const initialStateChange = (dispatch) => {
  dispatch({
    type: INITIAL_STATE,
  })
}

export const resetCountrySelected = (dispatch) => {
  dispatch({
    type: RESET_COUNTRY,
  })
}

export const countryChanged = (country, dispatch) => {
  dispatch({
    type: SELECTION_COUNTRY,
    payload: country,
  })
}

export const cityChanged = (city, dispatch) => {
  dispatch({
    type: SELECTION_CITY,
    payload: city,
  })
}

export const authenticationChange = (isAuthenticated, dispatch) => {
  dispatch({
    type: AUTHENTICATED,
    payload: isAuthenticated,
  })
}

export const surveyCompletedChange = (surveyStatus, dispatch) => {
  dispatch({
    type: SURVEY_COMPLETED,
    payload: surveyStatus,
  })
}

export const addSecondTripOnCollectionOfVehicles = (vehicleType, dispatch) => {
  dispatch({
    type: ADD_SECOND_TRIP,
    payload: vehicleType,
  })
}

export const removeSecondTripOnCollectionOfVehicles = (vehicleType, dispatch) => {
  dispatch({
    type: REMOVE_SECOND_TRIP,
    payload: vehicleType,
  })
}

export const weeklyJourneysMadeDisabled = (disabledDays, vehicleToUpdate, dispatch) => {
  dispatch({
    type: WEEKLY_JOURNEYS_MADE_DISABLED,
    payload: {
      disabledDays,
      vehicleToUpdate,
    },
  })
}

export const sendingRequest = (dispatch) => {
  dispatch({
    type: SENDING_REQUEST,
  })
}

export const errorRequest = (dispatch) => {
  dispatch({
    type: ERROR_REQUEST,
  })
}

export const homeConsumptionCurrentSection = (answer, dispatch) => {
  dispatch({
    type: HOME_CONSUMPTION_CURRENT_SECTION,
    payload: answer,
  })
}

export const homeOfficeAnswersChanged = (answer, dispatch) => {
  dispatch({
    type: HOME_OFFICE_ANSWERS,
    payload: answer,
  })
}

export const devicesEnergyAnswersChanged = (answer, dispatch) => {
  dispatch({
    type: DEVICES_ENERGY_ANSWERS,
    payload: answer,
  })
}

export const homeHeatingAnswesChanged = (answer, dispatch) => {
  dispatch({
    type: HOME_HEATING_ANSWERS,
    payload: answer,
  })
}

export const houseCoolingGlobalAnswersChanged = (answer, dispatch) => {
  dispatch({
    type: HOUSE_COOLING_GLOBAL_ANSWERS,
    payload: answer,
  })
}

export const houseCoolingIndiaAnswersChanged = (answer, dispatch) => {
  dispatch({
    type: HOUSE_COOLING_INDIA_ANSWERS,
    payload: answer,
  })
}

export const estimateWorkCarbonFootprint = (answer, dispatch) => {
  dispatch({
    type: ESTIMATE_WORK_CARBON_FOOTPRINT,
    payload: answer,
  })
}

export const workCarbonFootprintEquivalencesChanged = (answer, dispatch) => {
  dispatch({
    type: WORK_CARBON_FOOTPRINT_EQUIVALENCES,
    payload: answer,
  })
}

export const electricityUseAnswersChanged = (answer, dispatch) => {
  dispatch({
    type: ELECTRICITY_USE_ANSWERS,
    payload: answer,
  })
}
