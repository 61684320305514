import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Strings from '../../constants/Strings'
import './Measure.scss'
import { ArrowDown, ArrowUp } from '../Icons'

const {
  RESULT: { EMISSIONS },
} = Strings

function Measure({
  addClass,
  emissionsDifference,
  totalGloberPreviousEmissionKgCO2,
  totalGloberCurrentEmissionKgCO2,
  previousResultDate,
  showBigMeasure,
}) {
  const userData = JSON.parse(localStorage.getItem('userResponse'))
  const userTotalEmissions = parseFloat(localStorage.getItem('userTotalEmissions'))
  const previousEmissions = userTotalEmissions && userTotalEmissions > 0
      ? userTotalEmissions
      : userData?.totalGHGEmissions + userData?.totalWFHEmissions
  const hasIncreasedCarbonFoorptint = parseInt(emissionsDifference) > 0
  const porcentualEmissionsDiference = useRef(
    (emissionsDifference * 100) / (previousEmissions > 0 ? previousEmissions : 1)
  )

  const textClassName = classNames('measure__label', {
    [addClass]: addClass,
  })

  const totalEmissions = userTotalEmissions ? userTotalEmissions : totalGloberCurrentEmissionKgCO2 || 0

  return (
    <>
      {!showBigMeasure ? (
        <div className='measure__summary'>
          {userData && (
            <div
              className='measure__emissions-change'
              style={hasIncreasedCarbonFoorptint ? { backgroundColor: '#FF9696' } : {}}
            >
              {hasIncreasedCarbonFoorptint ? <ArrowUp fill={'#CB2121'} /> : <ArrowDown fill={'var(--green-dark)'} />}
              <p className='measure__change-percentage-text'>
                {`${hasIncreasedCarbonFoorptint ? '+' : ''}${porcentualEmissionsDiference.current.toFixed(0)}%`}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className='measure__summaryContainer'>
          <div className='measure__summary'>
            <div
              className={`bubble-container ${
                hasIncreasedCarbonFoorptint && totalGloberPreviousEmissionKgCO2 ? 'bubble-container__red-border' : ''
              }`}
            >
              {totalGloberPreviousEmissionKgCO2 && (
                <div className='measure__emissions-change'>
                  {hasIncreasedCarbonFoorptint ? (
                    <ArrowUp fill={'var(--red)'} />
                  ) : (
                    <ArrowDown fill={'var(--green-yellow)'} />
                  )}
                  <p className='measure__change-percentage-text'>
                    {`${hasIncreasedCarbonFoorptint ? '+' : ''}${
                      porcentualEmissionsDiference.current ? porcentualEmissionsDiference.current.toFixed(2) : 0
                    }%`}
                  </p>
                </div>
              )}
              <div
                className={`text-container__${
                  totalGloberPreviousEmissionKgCO2 ? 'relative-position' : 'inherit-position'
                }`}
              >
                <span
                  className={totalEmissions.toFixed(0).toString().length > 3 ? 'measure__valueLarge' : 'measure__value'}
                >
                  {totalEmissions ? totalEmissions.toFixed(0) : 0}
                </span>
                <span className='measure--kg-co2'>
                  kg CO<span className='measure--superscript'>₂</span>e
                </span>
              </div>
            </div>
            <div className={textClassName}>
              <div>{EMISSIONS.EMISSIONLABEL_WELCOME_BACK}</div>
              {totalGloberPreviousEmissionKgCO2 && previousResultDate ? (
                <>
                  <div className='result__emissions-label2'>
                    {EMISSIONS.EMISSIONLABEL2}
                    {totalGloberPreviousEmissionKgCO2} kg CO₂e
                  </div>
                  <div className='result__emissions-label3'>
                    {EMISSIONS.EMISSIONLABEL3} {previousResultDate}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

Measure.propTypes = {
  addClass: PropTypes.string,
  value: PropTypes.number,
}

Measure.defaultProps = {
  addClass: '',
  value: 0,
}

export default Measure
