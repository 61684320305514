import React from 'react'
import './Card.scss'

export const Card = ({ urlImage = '', altImage = '', title = '', subtitle = '' }) => {
  return (
    <div className='card'>
      <img className='card__img' src={urlImage} alt={altImage} />
      <h4 className='card__title'>{title}</h4>
      <p className='card__subtitle'>{subtitle}</p>
    </div>
  )
}
