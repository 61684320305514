import React from 'react'
import { isEmpty } from 'lodash'
import { NavLink, useRouteMatch } from 'react-router-dom'

import './Menu.scss'

const MenuContext = React.createContext()
MenuContext.displayName = 'MenuContext'

function Menu(props) {
  const { params, path } = useRouteMatch()

  return <MenuContext.Provider value={[params, path]} {...props} />
}

function MenuContents({ children, ...props }) {
  return (
    <ul className='menu' {...props}>
      {children}
    </ul>
  )
}

function MenuItem({ children }) {
  return <li className='menu-item'>{children}</li>
}

function MenuTitle({ children }) {
  return <span className='menu-link'>{children}</span>
}

function MenuAvailableLink({ children, ...props }) {
  const [, path] = React.useContext(MenuContext)
  const { to } = props

  if (path.includes(to)) {
    return (
      <NavLink className='menu-link' {...props}>
        {children}
      </NavLink>
    )
  }

  return <MenuTitle>{children}</MenuTitle>
}

function MenuAvailableParameters({ parameter, children, ...props }) {
  const [params] = React.useContext(MenuContext)
  const param = params[parameter]
  const { to } = props

  if (!isEmpty(param)) {
    return (
      <NavLink className='menu-link' {...props} to={`${to}/${param}`}>
        {children}
      </NavLink>
    )
  }

  return <MenuTitle>{children}</MenuTitle>
}

export { Menu, MenuContents, MenuItem, MenuTitle, MenuAvailableLink, MenuAvailableParameters }
