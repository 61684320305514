import React from 'react'
import classNames from 'classnames'

import './Checkbox.scss'
import { IconComponent } from '../../utils/iconType'

export default function Checkbox({ checked = false, onPress = () => {}, label = '', name = '' }) {
  const className = classNames('checkbox', {
    checkbox__selected: checked,
  })

  const handleOnPress = () => {
    onPress(name, !checked)
  }

  return (
    <label className={className}>
      <button className='checkbox--button' onClick={handleOnPress}>
        <div className='ccenter'>
          <div className='checkbox--box'>
            <div className='checkbox--icon'></div>
            <span className='checkbox--checked'>{IconComponent('check')}</span>
          </div>
        </div>
      </button>
      <span className='checkbox--label'>{label}</span>
    </label>
  )
}
