import React, { useState, useEffect, useContext } from 'react'
import Strings from '../../../../constants/Strings/index'
import StoreContext from '../../../../providers/StoreContext'
import { electricityUseAnswersChanged } from '../../../../store/actions'

import debounce from 'lodash.debounce'
import Heading from '../../../../components/Heading/Heading'
import Question from '../../../../components/new-components/Question/Question'
import LabeledSlider from '../../../../components/RangeSlider/LabeledSlider'
import OptionButtons from '../../../../components/new-components/OptionButtons/OptionButtons'

const ElectricityUseSection = ({ setDisableButton }) => {
  const {
    state: {
      homeConsumption: { electricityUse, homeOffice },
    },
    dispatch,
  } = useContext(StoreContext)

  const { qWorkingFullTime } = homeOffice

  const [answersState, setAnswersState] = useState({ ...electricityUse })

  const {
    HOME_CONSUMPTION: {
      SECTIONS: {
        HOME_OFFICE,
        ELECTRICITY_USE: { TITLE, QUESTIONS },
      },
    },
  } = Strings
  const { CONSUMPTION_ASSOCIATED, TyC } = QUESTIONS
  const TyCOptions = [...Object.values(TyC.OPTIONS)]

  const {
    QUESTIONS: {
      WORKING_FULL_TIME: {
        OPTIONS: { YES },
      },
    },
  } = HOME_OFFICE

  const ThumbnailElectricityUse = (props) => {
    const percentage = props['aria-valuenow']
    return (
      <span {...props} className={`${props.className} electricity-use-section__thumb`}>
        <span className='slider-value electricity-use-section__thumb-value'>
          <b>{`${percentage} ${props['aria-valuetext']}`}</b>
        </span>
        <img className='electricity-use-section__thumb-img' src='/assets/electricity-icon.svg' alt='percentage' />
      </span>
    )
  }

  const disabledNextButton = () => {
    let disabled = false
    for (let answer of Object.values(answersState)) {
      if (answer === '' && qWorkingFullTime === YES.value) {
        disabled = true
      }
    }
    setDisableButton(disabled)
  }

  useEffect(() => {
    const element = document.querySelector('.electricity-use-section__content')
    element.classList.add('electricity-use-section__fade-in')
  }, [])

  useEffect(() => {
    disabledNextButton()
    /* setDisableButton(false); */
    electricityUseAnswersChanged(answersState, dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersState])

  const handlerChange = (e) => {
    const { name, value } = e.currentTarget
    setAnswersState({
      ...answersState,
      [name]: value,
    })
  }

  const handlerSlider = () => (value) => {
    setAnswersState({
      ...answersState,
      qConsumptionAssociated: value,
    })
  }

  return (
    <div className='electricity-use-section__content'>
      <Heading addClass='electricity-use-section__title' level='h1'>
        {TITLE}
      </Heading>

      <form id='electricityUseForm' className='electricity-use-section__form'>
        <Question title={CONSUMPTION_ASSOCIATED.TITLE} description={CONSUMPTION_ASSOCIATED.DESCRIPTION}>
          <LabeledSlider
            value={answersState.qConsumptionAssociated}
            defaultValue={0}
            min={0}
            max={100}
            percentages
            suffix='%'
            ThumbComponent={ThumbnailElectricityUse}
            onChange={debounce(handlerSlider(), 100)}
          />
        </Question>
        {qWorkingFullTime === YES.value && (
          <Question description={TyC.DESCRIPTION}>
            <OptionButtons
              name='qTyC'
              row
              value={answersState.qTyC}
              handlerOnChange={handlerChange}
              options={TyCOptions}
            />
          </Question>
        )}
      </form>
    </div>
  )
}

export default ElectricityUseSection
