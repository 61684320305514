import React from 'react'
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'
import Lottie from 'react-lottie'
import { initialStateChange } from '../../store/actions'
import StoreContext from '../../providers/StoreContext'
import withPublicAuthenticated from '../../hoc/withEnableDisableWeeklyDays/withPublicAuthenticated'

import './SplashScreen.scss'

const SplashScreen = () => {
  const { dispatch } = useContext(StoreContext)

  const history = useHistory()

  const animationData = require('../../assets/static/animationData.json')

  const defaultOptions = {
    loop: false,
    renderer: 'svg',
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div className='splash-screen'>
      <div className='splash-screen--holder'>
        <Lottie
          options={defaultOptions}
          width={500}
          height={500}
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => {
                initialStateChange(dispatch)
                history.push('/login')
              },
            },
          ]}
        />
      </div>
    </div>
  )
}

export default withPublicAuthenticated(SplashScreen)
