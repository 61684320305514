/// TODO Refactor   unir componente q esta en sumary para encapsular y abstraer
import React from 'react'
import './DonutChart.scss'

import { Chart } from 'react-google-charts'

const DonutChart = ({ data, width, height }) => {
  const chartData = [['Label', 'Value', { role: 'style' }], ...data.map((item) => [item.label, item.value, item.color])]
  const chartStyle = {
    width: width || '365px',
    height: height || '305px',
    borderWidth: 1,
  }

  return (
    <Chart
      chartType='PieChart'
      data={chartData}
      style={chartStyle}
      options={{
        backgroundColor: 'transparent',
        colors: data.map((item) => item.color || '#3366cc'),
        pieSliceBorderColor: 'transparent',
        pieSliceTextStyle: {
          color: 'black',
          fontSize: 10,
          fontName: 'Lato',
        },
        chartArea: {
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        },
        legend: {
          position: 'right',
          alignment: 'center',
          textStyle: {
            color: 'white',
            fontSize: 14,
            fontName: 'Lato',
          },
        },

        enableInteractivity: true,
        // pieStartAngle:20,
      }}
    />
  )
}

export default DonutChart
