import React, { useContext } from 'react'
import MagicContext from '../../providers/MagicContext'
import { Redirect } from 'react-router-dom'
/* eslint-disable-next-line react/display-name */
const withAuthentication = (Component) => (props) => {
  const { state: magicState } = useContext(MagicContext)
  const { isAuthenticated, isSurveyComplete } = magicState

  if (!isAuthenticated) return <Redirect to='/login' />
  if (isSurveyComplete) return <Redirect to='/result' />

  return <Component {...props} hasAuthenticated={isAuthenticated} hasSurveyComplete={isSurveyComplete} />
}

export default withAuthentication
