import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Heading.scss'

function Heading({ addClass, children, level }) {
  const validLevels = ['h1', 'h2', 'h3', 'h4']
  const Title = validLevels.includes(level) ? level : 'h1'

  const className = classNames(`heading--${Title}`, {
    [addClass]: addClass,
  })

  return <Title className={className}>{children}</Title>
}

Heading.propTypes = {
  addClass: PropTypes.string,
  children: PropTypes.node,
  level: PropTypes.string,
}

Heading.defaultProps = {
  addClass: '',
  children: 'Default value',
  level: 'h1',
}

export default Heading
