const { default: Strings } = require('../constants/Strings')

function defaultTrips(vehicle) {
  switch (vehicle) {
    case 'car':
      return {
        engineType: {},
        travelDistance: {},
        weeklyJourneysMade: {
          disableDays: 0,
        },
        passengersTravelInThisVehicle: {},
        currentModeOfTransportationToWork: {},
      }
    case 'motorcycle':
      return {
        engineType: {},
        travelDistance: {},
        weeklyJourneysMade: {
          disableDays: 0,
        },
        passengersTravelInThisVehicle: {
          question: Strings.TRIPS_QUESTIONS_4,
          value: 1,
        },
        currentModeOfTransportationToWork: {},
      }
    case 'taxi':
      return {
        engineType: {
          question: Strings.TRIPS_QUESTIONS_1,
          value: 'diesel',
        },
        travelDistance: {},
        weeklyJourneysMade: {
          disableDays: 0,
        },
        passengersTravelInThisVehicle: {
          question: Strings.TRIPS_QUESTIONS_4,
          value: 1,
        },
        currentModeOfTransportationToWork: {},
      }
    case 'bus':
      return {
        engineType: {
          question: Strings.TRIPS_QUESTIONS_1,
          value: 'diesel',
        },
        travelDistance: {},
        weeklyJourneysMade: {
          disableDays: 0,
        },
        passengersTravelInThisVehicle: {
          question: Strings.TRIPS_QUESTIONS_4,
          value: 50,
        },
        currentModeOfTransportationToWork: {},
      }
    case 'combi':
      return {
        engineType: {
          question: Strings.TRIPS_QUESTIONS_1,
          value: 'diesel',
        },
        travelDistance: {},
        weeklyJourneysMade: {
          disableDays: 0,
        },
        passengersTravelInThisVehicle: {
          question: Strings.TRIPS_QUESTIONS_4,
          value: 15,
        },
        currentModeOfTransportationToWork: {},
      }
    case 'subway':
      return {
        engineType: {
          question: Strings.TRIPS_QUESTIONS_1,
          value: 'electricPower',
        },
        travelDistance: {},
        weeklyJourneysMade: {
          disableDays: 0,
        },
        passengersTravelInThisVehicle: {
          question: Strings.TRIPS_QUESTIONS_4,
          value: 650,
        },
        currentModeOfTransportationToWork: {},
      }
    default:
      return {
        ecoFriendly: {
          value: vehicle,
        },
        weeklyJourneysMade: {
          disableDays: 0,
          value: 1,
        },
        currentModeOfTransportationToWork: {},
      }
  }
}

export default defaultTrips
