import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Centering from '../../layout/Centering/Centering'
import Jumbotron from '../../components/Jumbotron/Jumbotron'
import Button from '../../components/Button/Button'

import Strings from '../../constants/Strings'
import { IconComponent } from '../../utils/iconType'

import './Page404.scss'

const Page404 = () => {
  const history = useHistory()
  const location = useLocation()

  const handleNextButton = () => {
    history.push('/')
  }

  /*
    avoid rendering the page404 if on trips
    this is because trips is the only page that can be scrolled,
    which makes possible the visibility of this page
    because page404 is displayed after being down enough
  */
  return (
    !location.pathname.includes('/trips') && (
      <Centering hideFooterActions hideHeaderMenu>
        <div className='page404--icon'>{IconComponent('sadFace')}</div>
        <Jumbotron title={Strings.PAGE404_TITLE} addClass='page404--content'>
          {Strings.PAGE404_DESCRIPTION}
        </Jumbotron>
        <Button onClick={handleNextButton}>{Strings.PAGE404_BACK_BUTTON}</Button>
      </Centering>
    )
  )
}

export default Page404
