import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Slider } from '@material-ui/core'
import './LabeledSlider.scss'

import { equivalenceKmToMiles } from '../../utils/generalHelper'

const ThumbComponentDefault = (props) => {
  const kilometers = props['aria-valuenow']
  const milesByKilometers = (kilometers * equivalenceKmToMiles).toFixed(2)

  const value = `${kilometers} ${props['aria-valuetext']} / ${milesByKilometers} mi`

  return (
    <span {...props}>
      <span className='slider-value'>{value}</span>
      <img src='/assets/home-icon.svg' alt='Slidable icon' />
    </span>
  )
}

const LabeledSlider = ({
  min,
  max,
  value = 0,
  /* eslint-disable-next-line no-unused-vars */
  defaultValue,
  suffix,
  labeled,
  percentages,
  ThumbComponent,
  type,
  index,
  onChange = () => {},
  stepThreshold: { threshold, step },
  showMarks = false,
  ...props
}) => {
  const [valueSelected, setValueSelected] = useState(value)

  useEffect(() => {
    setValueSelected(value)
  }, [value])

  const handleChange = (_event, newValue) => {
    setValueSelected(newValue)
    onChange(newValue, type, index)
  }

  const slider = (
    <Slider
      {...props}
      min={min}
      max={max}
      value={valueSelected}
      aria-valuetext={suffix}
      onChange={handleChange}
      ThumbComponent={ThumbComponent}
      step={value < threshold ? 1 : step}
      marks={showMarks}
    />
  )

  if (labeled) {
    return (
      <div className='slider-wrapper'>
        <div className='slider-divider'>
          <div className='slider-icon'>
            <img src='/assets/globant-logo.svg' alt='Globant Logo'></img>
          </div>
          <div className='slider-container'>{slider}</div>
        </div>
      </div>
    )
  }

  if (percentages) {
    return (
      <div className='slider-wrapper'>
        <div className='slider-divider'>
          <div className='slider-percentages slider-percentages-start'>0%</div>
          <div className='slider-container'>{slider}</div>
          <div className='slider-percentages slider-percentages-end'>100%</div>
        </div>
      </div>
    )
  }

  return slider
}

LabeledSlider.propTypes = {
  suffix: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  defaultValue: PropTypes.number,
  stepThreshold: PropTypes.object,
  'aria-label': PropTypes.string,
  'aria-valuetext': PropTypes.string,
  index: PropTypes.number,
}

LabeledSlider.defaultProps = {
  suffix: '',
  min: 0,
  max: 50,
  step: 1,
  defaultValue: 5,
  stepThreshold: {},
  'aria-label': 'Unit selection slider',
  'aria-valuetext': 'units',
  ThumbComponent: ThumbComponentDefault,
  index: 0,
}

export default LabeledSlider
