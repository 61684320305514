export const holidaysPerCountry = {
  argentina: 14,
  australia: 9,
  belarus:11,
  brazil: 11.5,
  canada: 14,
  chile: 13,
  colombia: 16,
  costarica: 9,
  denmark: 10,
  ecuador: 11,
  france: 11,
  germany: 9,
  india: 9,
  italy: 14,
  luxemburg: 9,
  moldova: 7,
  netherlands: 7,
  mexico: 11,
  peru: 13,
  poland:10,
  portugal: 9,
  panama:10,
  romania: 10,
  spain: 9,
  saudiArabia:8,
  uk: 8,
  unitedArabEmirates: 10,
  uruguay: 7,
  usa: 11,
  vietnam: 13
}

export const getHolidayPerCountry = (country) => {
  return holidaysPerCountry[country.toLowerCase().replace(' ', '')]
}