import React from 'react'
import Heading from '../../../../components/Heading/Heading'
import CheckboxGroup from '../../../../components/CheckboxGroup/CheckboxGroup'
import Strings from '../../../../constants/Strings'

import './EcoFriendly.scss'

const EcoFriendly = ({
  onOptionsChange = () => {},
  /* eslint-disable no-unused-vars */
  index,
  tripsData = {
    ecoFriendly: {},
    weeklyJourneysMade: {
      value: 1,
    },
    currentModeOfTransportationToWork: {},
  },
}) => {
  const { currentModeOfTransportationToWork } = tripsData

  const handleOnChange = (question) => (value, type, index) => {
    const formatType = type.replace(/-.*/g, '')

    onOptionsChange(index, { type: formatType, value, question })
  }

  return (
    <div className='eco-friendly'>
      <Heading addClass='eco-friendly--heading' level='h3'>
        {Strings.TRIPS_NO_EMISSIONS}
      </Heading>
      <div className='eco-friendly--current-mode-transportation'>
        <Heading addClass='public-transport--heading' level='h4'>
          {Strings.TRIPS_QUESTIONS_5}
        </Heading>
        <CheckboxGroup
          onChange={handleOnChange(Strings.TRIPS_QUESTIONS_5)}
          items={currentModeOfTransportationToWork.value}
          type={`currentModeOfTransportationToWork`}
          index={0}
        />
      </div>
    </div>
  )
}

export default EcoFriendly
