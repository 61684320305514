import React from 'react'
import './ButtonList.scss'
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

const ButtonList = ({
  name,
  counter,
  initValue = 0,
  initText = '',
  showInitValue = true,
  valueSelected = null,
  handlerValueSelected,
}) => {
  const getToggleButtons = () => {
    let content = []
    for (let i = 0; i < parseInt(counter); i++) {
      let num = i + parseInt(initValue)
      content.push(
        <ToggleButton name={name} value={`${num}`} key={i}>
          {num !== 0 && num}
          {num === 0 && initText === '' && showInitValue && num}
          {num === 0 && initText !== '' && showInitValue && `${num}${initText}`}
          {num === 0 && initText !== '' && !showInitValue && `${initText}`}
        </ToggleButton>
      )
    }
    return content
  }

  return (
    <div className='button-list'>
      <ToggleButtonGroup exclusive value={valueSelected} onChange={handlerValueSelected}>
        {getToggleButtons()}
      </ToggleButtonGroup>
    </div>
  )
}

ButtonList.propTypes = {
  name: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired,
  initValue: PropTypes.number,
  initText: PropTypes.string,
  showInitValue: PropTypes.bool,
}

export default ButtonList
