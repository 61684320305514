import React from 'react'

import { Menu, MenuContents, MenuItem, MenuAvailableLink } from '../Menu/Menu'
import { BeKindLogo } from '../Icons'
import myFootprint from '../../assets/images/myFootprint.png'
import Strings from '../../constants/Strings'

import './Header.scss'

const { MENU_YOUR_OFFICE, MENU_HOME_CONSUMPTION, MENU_YOUR_TRIPS, MENU_RESULTS } = Strings

const Header = ({ hideMenu }) => {
  const renderMenu = !hideMenu && (
    <div className='header__menu'>
      <Menu>
        <MenuContents>
          <MenuItem>
            <MenuAvailableLink to='/country-selection' exact>
              {MENU_YOUR_OFFICE}
            </MenuAvailableLink>
          </MenuItem>
          <MenuItem>
            <MenuAvailableLink to='/home-consumption' exact>
              {MENU_HOME_CONSUMPTION}
            </MenuAvailableLink>
          </MenuItem>
          <MenuItem>
            <MenuAvailableLink to='/trips'>{MENU_YOUR_TRIPS}</MenuAvailableLink>
          </MenuItem>
          <MenuItem>
            <MenuAvailableLink to='/result' exact>
              {MENU_RESULTS}
            </MenuAvailableLink>
          </MenuItem>
        </MenuContents>
      </Menu>
    </div>
  )

  return (
    <header className='header'>
      <div className='header__left'>
        <img className='header__myfootprint' src={myFootprint} alt='my Footprint logo' />
        {renderMenu}
      </div>
      <div className='header__right'>
        <BeKindLogo />
      </div>
    </header>
  )
}

Header.propTypes = {}
Header.defaultProps = {}

export default Header
