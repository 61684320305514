import React from 'react'

const CheckIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 12 10'>
    <path
      fill='none'
      fillRule='evenodd'
      stroke='#0D0D0D'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      d='M6 11.306L8.877 14.496 15.315 7'
      transform='translate(-5 -6)'
    />
  </svg>
)

export default CheckIcon
