import React from 'react'

const HomeIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='41' height='42'>
    <path
      d='M2 20.32L20.5 2 39 20.32M6.381 22.117V40h10.277V28.186h8.164V40h10.447V22.117M28.382 3.599h6.202v6.087'
      stroke='#fff'
      fill='none'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default HomeIcon
