import React, { Fragment, useContext, useEffect, useState } from 'react'
import Strings from '../../../../constants/Strings/index'

import Heading from '../../../../components/Heading/Heading'
import Question from '../../../../components/new-components/Question/Question'
import ButtonList from '../../../../components/new-components/ButtonList/ButtonList'
import OptionButtons from '../../../../components/new-components/OptionButtons/OptionButtons'
import StoreContext from '../../../../providers/StoreContext'
import { houseCoolingGlobalAnswersChanged, houseCoolingIndiaAnswersChanged } from '../../../../store/actions'

const HouseCoolingSection = ({ setDisableButton }) => {
  const {
    state: {
      homeConsumption: { houseCooling },
      countrySelected: { country },
    },
    dispatch,
  } = useContext(StoreContext)

  const {
    HOME_CONSUMPTION: {
      SECTIONS: { HOUSE_COOLING },
    },
  } = Strings
  const GLOBAL_Q = HOUSE_COOLING.QUESTIONS.GLOBAL
  const INDIA_Q = HOUSE_COOLING.QUESTIONS.INDIA

  const [answersState, setAnswersState] = useState({
    ...houseCooling.global,
    ...houseCooling.india,
  })

  const [radioChange, setRadioChange] = useState('')

  const globalHaveAirConditioningOptions = [...Object.values(GLOBAL_Q.HAVE_AIR_CONDITIONING.OPTIONS)]
  const globalManyFrigoriesOptions = [...Object.values(GLOBAL_Q.MANY_FRIGORIES.OPTIONS)]
  const globalUseAnotherDeviceOptions = [...Object.values(GLOBAL_Q.USE_ANOTHER_DEVICE.OPTIONS)]
  const indiaAAOptions = [...Object.values(INDIA_Q.TYPE_AIR_CONDITIONING.OPTIONS)]
  const indiaABOptions = [...Object.values(INDIA_Q.CAPACITY_AIR_CONDITIONING.OPTIONS)]

  const setContext = (globalAnswers = {}, indiaAnswers = {}) => {
    setAnswersState({
      ...answersState,
      ...globalAnswers,
      ...indiaAnswers,
    })
    houseCoolingGlobalAnswersChanged(
      {
        ...globalAnswers,
      },
      dispatch
    )
    houseCoolingIndiaAnswersChanged(
      {
        ...indiaAnswers,
      },
      dispatch
    )
  }

  useEffect(() => {
    const element = document.querySelector('.house-cooling-section__content')
    element.classList.add('house-cooling-section__fade-in')
  }, [])

  useEffect(() => {
    disabledNextButton()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersState])

  useEffect(() => {
    if (radioChange === 'qHaveAirConditioning') {
      setContext(
        {
          qManyFrigories: '',
          qManyMonthsUse: '',
          qManyHoursUse: '',
        },
        {
          qTypeAirConditioning: '',
          qCapacityAirConditioning: '',
        }
      )
    }

    if (radioChange === 'qUseAnotherDevice') {
      setContext({
        qManyMonthsUseAnother: '',
        qManyHoursUseAnother: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioChange])

  const handlerChange = (e) => {
    const { name, value } = e.currentTarget
    if (name !== 'qTypeAirConditioning' && name !== 'qCapacityAirConditioning') {
      setRadioChange(name)
      setContext({ [name]: value })
    } else {
      setContext({}, { [name]: value })
    }
  }

  const disabledNextButton = () => {
    let disabled = false
    if (answersState.qHaveAirConditioning !== GLOBAL_Q.HAVE_AIR_CONDITIONING.OPTIONS.NO.value) {
      if (country !== 'India') {
        if (answersState.qManyFrigories === '') {
          disabled = true
        }
      } else {
        if (answersState.qTypeAirConditioning === '' || answersState === '') {
          disabled = true
        }
      }
      if (answersState.qManyMonthsUse === '' || answersState.qManyHoursUse === '') {
        disabled = true
      }
    }
    if (answersState.qUseAnotherDevice !== GLOBAL_Q.USE_ANOTHER_DEVICE.OPTIONS.NO.value) {
      if (answersState.qManyMonthsUseAnother === '' || answersState.qManyHoursUseAnother === '') {
        disabled = true
      }
    }
    setDisableButton(disabled)
  }

  const ContentQHaveAirConditioningYes = () => {
    return (
      <div className='house-cooling-section__sub-content'>
        {country === 'India' ? (
          <Fragment>
            <Question title={INDIA_Q.TYPE_AIR_CONDITIONING.TITLE}>
              <OptionButtons
                name='qTypeAirConditioning'
                value={answersState.qTypeAirConditioning}
                handlerOnChange={handlerChange}
                options={indiaAAOptions}
                row
              />
            </Question>
            <Question title={INDIA_Q.CAPACITY_AIR_CONDITIONING.TITLE}>
              <OptionButtons
                name='qCapacityAirConditioning'
                value={answersState.qCapacityAirConditioning}
                handlerOnChange={handlerChange}
                options={indiaABOptions}
                row
              />
            </Question>
          </Fragment>
        ) : (
          <Question title={GLOBAL_Q.MANY_FRIGORIES.TITLE}>
            <OptionButtons
              name='qManyFrigories'
              value={answersState.qManyFrigories}
              handlerOnChange={handlerChange}
              options={globalManyFrigoriesOptions}
            />
          </Question>
        )}
        <Question title={GLOBAL_Q.MANY_MONTHS_USE.TITLE}>
          <ButtonList
            name='qManyMonthsUse'
            counter={GLOBAL_Q.MANY_MONTHS_USE.OPTIONS.COUNTER}
            initValue={GLOBAL_Q.MANY_MONTHS_USE.OPTIONS.INIT}
            valueSelected={answersState.qManyMonthsUse}
            handlerValueSelected={handlerChange}
          />
        </Question>
        <Question title={GLOBAL_Q.MANY_HOURS_USE.TITLE}>
          <ButtonList
            name='qManyHoursUse'
            counter={GLOBAL_Q.MANY_HOURS_USE.OPTIONS.COUNTER}
            initValue={GLOBAL_Q.MANY_HOURS_USE.OPTIONS.INIT}
            valueSelected={answersState.qManyHoursUse}
            handlerValueSelected={handlerChange}
          />
        </Question>
      </div>
    )
  }

  const ContentQUseAnotherDeviceYes = () => {
    return (
      <div className='house-cooling-section__sub-content'>
        <Question title={GLOBAL_Q.MANY_MONTHS_USE_ANOTHER.TITLE}>
          <ButtonList
            name='qManyMonthsUseAnother'
            counter={GLOBAL_Q.MANY_MONTHS_USE_ANOTHER.OPTIONS.COUNTER}
            initValue={GLOBAL_Q.MANY_MONTHS_USE_ANOTHER.OPTIONS.INIT}
            valueSelected={answersState.qManyMonthsUseAnother}
            handlerValueSelected={handlerChange}
          />
        </Question>
        <Question title={GLOBAL_Q.MANY_HOURS_USE_ANOTHER.TITLE}>
          <ButtonList
            name='qManyHoursUseAnother'
            counter={GLOBAL_Q.MANY_HOURS_USE_ANOTHER.OPTIONS.COUNTER}
            initValue={GLOBAL_Q.MANY_HOURS_USE_ANOTHER.OPTIONS.INIT}
            valueSelected={answersState.qManyHoursUseAnother}
            handlerValueSelected={handlerChange}
          />
        </Question>
      </div>
    )
  }

  return (
    <div className='house-cooling-section__content'>
      <Heading addClass='house-cooling-section__title' level='h1'>
        {HOUSE_COOLING.TITLE}
      </Heading>
      <p className='house-cooling-section__description'>{HOUSE_COOLING.DESCRIPTION}</p>

      <form id='houseCoolingForm' className='house-cooling-section__form'>
        <Question title={GLOBAL_Q.HAVE_AIR_CONDITIONING.TITLE}>
          <OptionButtons
            name='qHaveAirConditioning'
            row
            value={answersState.qHaveAirConditioning}
            handlerOnChange={handlerChange}
            options={globalHaveAirConditioningOptions}
          />
        </Question>
        {answersState.qHaveAirConditioning === GLOBAL_Q.HAVE_AIR_CONDITIONING.OPTIONS.YES.value && (
          <ContentQHaveAirConditioningYes />
        )}
        <Question title={GLOBAL_Q.USE_ANOTHER_DEVICE.TITLE} observation={GLOBAL_Q.USE_ANOTHER_DEVICE.OBSERVATION}>
          <OptionButtons
            name='qUseAnotherDevice'
            row
            value={answersState.qUseAnotherDevice}
            handlerOnChange={handlerChange}
            options={globalUseAnotherDeviceOptions}
          />
        </Question>
        {answersState.qUseAnotherDevice === GLOBAL_Q.USE_ANOTHER_DEVICE.OPTIONS.YES.value && (
          <ContentQUseAnotherDeviceYes />
        )}
      </form>
    </div>
  )
}

export default HouseCoolingSection
