import React from 'react'
import { subtract, range } from 'lodash'
import { daysOfTheWeek } from '../../utils/generalHelper'
/* eslint-disable-next-line react/display-name */
const withEnableDisableWeeklyDays = (Component) => (props) => {
  const { tripsData } = props
  const { weeklyJourneysMade } = tripsData

  const getEnableDisableWeeklyDays = () => {
    const enableDaysValue = subtract(daysOfTheWeek.length, weeklyJourneysMade.disableDays)
    const enableDaysArray = range(0, enableDaysValue)
    const disableDaysArray = range(enableDaysValue, daysOfTheWeek.length)

    const enableDaysData = enableDaysArray.map((day) => ({
      key: day,
      value: day + 1,
      disabled: false,
    }))
    const disableDaysData = disableDaysArray.map((day) => ({
      key: day,
      value: day + 1,
      disabled: true,
    }))

    return [...enableDaysData, ...disableDaysData]
  }

  return (
    <Component
      {...props}
      {...{
        getEnableDisableWeeklyDays,
      }}
    />
  )
}

export default withEnableDisableWeeklyDays
