import React, { useContext, useEffect } from 'react'
import MagicContext from '../../providers/MagicContext'
import { useHistory } from 'react-router-dom'

/* eslint-disable-next-line react/display-name */
const withPublicAuthenticated = (Component) => (props) => {
  const { state: magicState } = useContext(MagicContext)
  const { isAuthenticated } = magicState
  const history = useHistory()

  useEffect(() => {
    const userResponse = localStorage.getItem('userResponse') ? JSON.parse(localStorage.getItem('userResponse')) : null

    if (isAuthenticated && userResponse) {
      /* eslint-disable-next-line no-unused-vars */
      const { photoURL, email, displayName, ...initialState } = userResponse
      const beKindWithThisWorld = localStorage.getItem('beKindWithThisWorld')

      if (JSON.stringify(initialState) !== beKindWithThisWorld) {
        history.push('/welcome-back')
        return
      }
    }

    if (isAuthenticated === true) {
      history.push('/country-selection')
    }
  }, [magicState])

  return <Component {...props} />
}

export default withPublicAuthenticated
