import { debounce } from 'lodash'
import React, { useState } from 'react'
import Heading from '../../../../components/Heading/Heading'
import Strings from '../../../../constants/Strings'
import RadioButtonsGroup from '../../../../components/RadioButtonsGroup/RadioButtonsGroup'
import RadioButton from '../../../../components/RadioButton/RadioButton'
import LabeledSlider from '../../../../components/RangeSlider/LabeledSlider'
import withEnableDisableWeeklyDays from '../../../../hoc/withEnableDisableWeeklyDays/withEnableDisableWeeklyDays'
import CheckboxGroup from '../../../../components/CheckboxGroup/CheckboxGroup'

import './PublicTransport.scss'

const PublicTransport = ({
  getEnableDisableWeeklyDays,
  onOptionsChange = () => {},
  index,
  tripsData = {
    travelDistance: {},
    weeklyJourneysMade: {
      disableDays: 0,
    },
    currentModeOfTransportationToWork: {},
  },
}) => {
  const { travelDistance, weeklyJourneysMade, currentModeOfTransportationToWork } = tripsData
  const [questionsType, setQuestionsType] = useState({
    travelDistance,
    weeklyJourneysMade,
    currentModeOfTransportationToWork,
  })

  const handleOnChange = (question) => (value, type, index) => {
    const formatType = type.replace(/-.*/g, '')

    setQuestionsType({
      ...questionsType,
      [formatType]: {
        value,
        question,
      },
    })
    onOptionsChange(index, { type: formatType, value, question })
  }

  const renderRadioButton = () => {
    return getEnableDisableWeeklyDays().map((day) => (
      <RadioButton key={day.key} value={String(day.value)} disabled={day.disabled}>
        {day.value}
      </RadioButton>
    ))
  }

  return (
    <section className='public-transport'>
      <ul className='public-transport--emissions-config'>
        <li className='public-transport--emissions-item'>
          <div className='public-transport--distance-heading'>
            <Heading addClass='public-transport--heading' level='h4'>
              {Strings.TRIPS_QUESTIONS_2}
              <span className='public-transport--distance-heading-subtitle'>{Strings.TRIPS_QUESTIONS_SUB_2}</span>
            </Heading>
          </div>
          <LabeledSlider
            value={travelDistance.value}
            defaultValue={0}
            min={0}
            max={250}
            labeled
            suffix='km'
            type={`travelDistance-${index}`}
            onChange={debounce(handleOnChange(Strings.TRIPS_QUESTIONS_2), 100)}
            index={index}
          />
        </li>
        <li className='public-transport--emissions-item'>
          <Heading addClass='public-transport--heading' level='h4'>
            {Strings.TRIPS_QUESTIONS_3}
          </Heading>
          <RadioButtonsGroup
            value={weeklyJourneysMade.value}
            group={`weeklyJourneysMade-${index}`}
            onChange={handleOnChange(Strings.TRIPS_QUESTIONS_3)}
            index={index}
          >
            {renderRadioButton()}
          </RadioButtonsGroup>
        </li>
        <li className='public-transport--emissions-item'>
          <Heading addClass='public-transport--heading' level='h4'>
            {Strings.TRIPS_QUESTIONS_5}
          </Heading>
          <CheckboxGroup
            onChange={handleOnChange(Strings.TRIPS_QUESTIONS_5)}
            items={currentModeOfTransportationToWork.value}
            type={`currentModeOfTransportationToWork-${index}`}
            index={index}
          />
        </li>
      </ul>
    </section>
  )
}

export default withEnableDisableWeeklyDays(PublicTransport)
