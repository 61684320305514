export const countriesCities = [
  {
    country: 'Belarus',
    cities: ['Minsk', 'Other'],
  },
  {
    country: 'Brazil',
    cities: ['Sao Paulo', 'Other'],
  },
  {
    country: 'Chile',
    cities: ['Santiago de Chile', 'Other'],
  },
  {
    country: 'Colombia',
    cities: ['Bogotá', 'Medellín', 'Other'],
  },
  {
    country: 'USA',
    cities: ['Chicago', 'Dallas', 'Miami', 'New York', 'Raleigh', 'San Francisco', 'Seattle', 'Winston-Salem', 'Other'],
  },
  {
    country: 'France',
    cities: ['Paris', 'Other'],
  },
  {
    country: 'India',
    cities: ['Bengaluru', 'Pune', 'Other'],
  },
  {
    country: 'Mexico',
    cities: ['Ciudad de México', 'Guadalajara', 'Monterrey', 'Other'],
  },
  {
    country: 'Peru',
    cities: ['Lima', 'Other'],
  },
  {
    country: 'Romania',
    cities: ['Cluj-Napoca', 'Other'],
  },
  {
    country: 'Spain',
    cities: ['Madrid', 'Other'],
  },
  {
    country: 'UK',
    cities: ['London', 'Other'],
  },
  {
    country: 'Uruguay',
    cities: ['Montevideo', 'Other'],
  },
  {
    country: 'Argentina',
    cities: [
      'Bahía Blanca',
      'Buenos Aires (CABA)',
      'Córdoba',
      'La Plata',
      'Mar del Plata',
      'Mendoza',
      'Resistencia',
      'Rosario',
      'Tandil',
      'Tucumán',
      'Other',
    ],
  },
  {
    country: 'Costa Rica',
    cities: ['San José', 'Other'],
  },
  {
    country: 'Ecuador',
    cities: ['Quito', 'Guayaquil', 'Other'],
  },
  {
    country: 'Canada',
    cities: ['Toronto', 'Montreal', 'Vancouver', 'Other'],
  },
  {
    country: 'Germany',
    cities: ['Berlin', 'Hamburg', 'Múnich', 'Other'],
  },
  {
    country: 'Luxenburg',
    cities: ['Luxenburg'],
  },
  {
    country: 'Bulgary',
    cities: ['Bulgary'],
  },
  {
    country: 'China',
    cities: ['China'],
  },
  {
    country: 'Australia',
    cities: ['Sidney'],
  },
  {
    country: 'Denmark',
    cities: ['Denmark'],
  },
  {
    country: 'Hong Kong',
    cities: ['Hong Kong'],
  },
  {
    country:'Panamá',
    cities:['Panamá']
  },
  {
    country: 'Polonia',
    cities: ['Polonia'],
  },
  {
    country: 'Saudi Arabia',
    cities: ['Saudi Arabia'],
  },
  {
    country: 'Italy',
    cities: ['Rome'],
  },
  {
    country:'Vietnam',
    cities:['Vietnam']
  },
  {
    country:'United Arab Emirates',
    cities:['United Arab Emirates']
  },
  {
    country:'Netherlands',
    cities:['Amsterdam', 'Other']
  },
  {
    country:'Moldova',
    cities:['Other']
  },
  {
    country: 'Serbia',
    cities: ['Serbia'],
  },
  {
    country: 'Portugal',
    cities: ['Lisbon', 'Other']
  }
]
