import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './RadioButton.scss'

const RadioButton = ({ isShrink, legend, value, group, disabled, onChange, addClass, children, index, isChecked }) => {
  const associateElements = `${group}-${value}`

  const wrapProps = classNames('radio-button', {
    'radio-button--shrink': isShrink,
    'radio-button--legend': legend,
    [addClass]: addClass,
  })

  const handleChange = () => {
    onChange(value, group, index)
  }

  const disableButton = (disabled || legend) && true

  return (
    <div className={wrapProps}>
      <input
        onChange={handleChange}
        className='radio-button--input'
        type='radio'
        value={value}
        name={group}
        id={associateElements}
        disabled={disableButton}
        checked={isChecked}
      />
      <label className='radio-button--label' htmlFor={associateElements} disabled={disableButton}>
        {children}
      </label>
    </div>
  )
}

RadioButton.propTypes = {
  addClass: PropTypes.string,
  isShrink: PropTypes.bool,
  legend: PropTypes.bool,
  value: PropTypes.string,
  group: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  index: PropTypes.number,
  isChecked: PropTypes.bool,
}

RadioButton.defaultProps = {
  addClass: '',
  isShrink: false,
  legend: false,
  value: 'default-value',
  group: 'default-group',
  disabled: false,
  onChange: () => {},
  index: 0,
  isChecked: false,
}

export default RadioButton
