import { debounce } from 'lodash'
import React, { useState } from 'react'
import Heading from '../../../../components/Heading/Heading'
import Strings from '../../../../constants/Strings'
import RadioButtonsGroup from '../../../../components/RadioButtonsGroup/RadioButtonsGroup'
import RadioButton from '../../../../components/RadioButton/RadioButton'
import LabeledSlider from '../../../../components/RangeSlider/LabeledSlider'
import CheckboxGroup from '../../../../components/CheckboxGroup/CheckboxGroup'
import withEnableDisableWeeklyDays from '../../../../hoc/withEnableDisableWeeklyDays/withEnableDisableWeeklyDays'

import './Car.scss'

const Car = ({
  getEnableDisableWeeklyDays,
  onOptionsChange = () => {},
  index,
  engineTypes = [],
  type = '',
  tripsData = {
    engineType: {},
    travelDistance: {},
    weeklyJourneysMade: {
      disableDays: 0,
    },
    passengersTravelInThisVehicle: {},
    currentModeOfTransportationToWork: {},
  },
}) => {
  const {
    engineType,
    travelDistance,
    weeklyJourneysMade,
    passengersTravelInThisVehicle,
    currentModeOfTransportationToWork,
  } = tripsData
  const [questionsType, setQuestionsType] = useState({
    engineType,
    travelDistance,
    weeklyJourneysMade,
    passengersTravelInThisVehicle,
    currentModeOfTransportationToWork,
  })


  const handleOnChange = (question) => (value, type) => {
    const formatType = type.replace(/-.*/g, '')

    setQuestionsType({
      ...questionsType,
      [formatType]: {
        value,
        question,
      },
    })
    onOptionsChange(index, { type: formatType, value, question })
  }

  const renderRadioButton = () => {
    return getEnableDisableWeeklyDays().map((day) => (
      <RadioButton key={day.key} value={String(day.value)} disabled={day.disabled}>
        {day.value}
      </RadioButton>
    ))
  }

  const renderPassengersTravelWithYou = (
    <li className='car--emissions-item'>
      <Heading addClass='car--heading' level='h4'>
        {Strings.TRIPS_QUESTIONS_4}
      </Heading>
      <RadioButtonsGroup
        value={passengersTravelInThisVehicle.value}
        group={`passengersTravelInThisVehicle-${index}`}
        onChange={handleOnChange(Strings.TRIPS_QUESTIONS_4)}
        index={index}
      >
        <RadioButton isShrink value='1'>
          {Strings.TRIPS_TRAVEL_ALONE}
        </RadioButton>
        <RadioButton value='2'>1</RadioButton>
        <RadioButton value='3'>2</RadioButton>
        <RadioButton value='4'>3</RadioButton>
        <RadioButton value='5'>4</RadioButton>
      </RadioButtonsGroup>
    </li>
  )

  const renderAdditionalContent = () => {
    return (
      <ul className='car--emissions-config'>
        <li className='car--emissions-item'>
          <div className='car--distance-heading'>
            <Heading addClass='car--heading' level='h4'>
              {Strings.TRIPS_QUESTIONS_2}
              <span className='car--distance-heading-subtitle'>{Strings.TRIPS_QUESTIONS_SUB_2}</span>
            </Heading>
          </div>
          <LabeledSlider
            value={travelDistance.value}
            defaultValue={0}
            min={0}
            max={250}
            labeled
            suffix='km'
            type={`travelDistance-${index}`}
            onChange={debounce(handleOnChange(Strings.TRIPS_QUESTIONS_2), 100)}
            index={index}
          />
        </li>
        <li className='car--emissions-item'>
          <Heading addClass='car--heading' level='h4'>
            {Strings.TRIPS_QUESTIONS_3}
          </Heading>
          <RadioButtonsGroup
            value={weeklyJourneysMade.value}
            group={`weeklyJourneysMade-${index}`}
            onChange={handleOnChange(Strings.TRIPS_QUESTIONS_3)}
            index={index}
          >
            {renderRadioButton()}
          </RadioButtonsGroup>
        </li>
        {type !== 'motocycle' && renderPassengersTravelWithYou}
        <li className='public-transport--emissions-item'>
          <Heading addClass='public-transport--heading' level='h4'>
            {Strings.TRIPS_QUESTIONS_5}
          </Heading>
          <CheckboxGroup
            onChange={handleOnChange(Strings.TRIPS_QUESTIONS_5)}
            items={currentModeOfTransportationToWork.value}
            type={`currentModeOfTransportationToWork-${index}`}
            index={index}
          />
        </li>
      </ul>
    )
  }

 
  const renderEngineTypeButtons = engineTypes.map((engineType) => {
    const { type, text } = engineType
    return (
      <RadioButton key={`index-${type}`} isShrink value={type}>
        {text}
      </RadioButton>
    )
  })

  return (
    <section className='car'>
      <Heading addClass='car--heading' level='h4'>
        {Strings.TRIPS_QUESTIONS_1}
      </Heading>
      <RadioButtonsGroup
        value={engineType.value}
        addClass='car--vehicle-type'
        group={`engineType-${index}`}
        onChange={handleOnChange(Strings.TRIPS_QUESTIONS_1)}
        index={index}
      >
        {renderEngineTypeButtons}
      </RadioButtonsGroup>
       { renderAdditionalContent() }
    </section>
  )
}

export default withEnableDisableWeeklyDays(Car)
