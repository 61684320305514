export const SELECTION_VEHICLES = 'SELECTION_VEHICLES'
export const AUTHENTICATED = 'AUTHENTICATED'
export const SELECTION_CITY = 'SELECTION_CITY'
export const RESET_COUNTRY = 'RESET_COUNTRY'
export const SELECTION_COUNTRY = 'SELECTION_COUNTRY'
export const VEHICLE_TRIP_DATA = 'VEHICLE_TRIP_DATA'
export const ADD_SECOND_TRIP = 'ADD_SECOND_TRIP'
export const REMOVE_SECOND_TRIP = 'REMOVE_SECOND_TRIP'
export const ESTIMATE_CARBON_FOOTPRINT = 'ESTIMATE_CARBON_FOOTPRINT'
export const SURVEY_COMPLETED = 'SURVEY_COMPLETED'
export const WEEKLY_JOURNEYS_MADE_DISABLED = 'WEEKLY_JOURNEYS_MADE_DISABLED'
export const RESET_STORE = 'RESET_STORE'
export const INITIAL_STATE = 'INITIAL_STATE'
export const RESET_MAGIC_STORE = 'RESET_MAGIC_STORE'

export const SENDING_REQUEST = 'SENDING_REQUEST'
export const SUCCESS_REQUEST = 'SUCCESS_REQUEST'
export const ERROR_REQUEST = 'ERROR_REQUEST'

export const HOME_CONSUMPTION_CURRENT_SECTION = 'HOME_CONSUMPTION_CURRENT_SECTION'
export const HOME_OFFICE_ANSWERS = 'HOME_OFFICE_ANSWERS'
export const DEVICES_ENERGY_ANSWERS = 'DEVICES_ENERGY_ANSWERS'
export const HOME_HEATING_ANSWERS = 'HOME_HEATING_ANSWERS'
export const HOUSE_COOLING_GLOBAL_ANSWERS = 'HOUSE_COOLING_GLOBAL_ANSWERS'
export const HOUSE_COOLING_INDIA_ANSWERS = 'HOUSE_COOLING_INDIA_ANSWERS'
export const ESTIMATE_WORK_CARBON_FOOTPRINT = 'ESTIMATE_WORK_CARBON_FOOTPRINT'
export const WORK_CARBON_FOOTPRINT_EQUIVALENCES = 'WORK_CARBON_FOOTPRINT_EQUIVALENCES'
export const ELECTRICITY_USE_ANSWERS = 'ELECTRICITY_USE_ANSWERS'
