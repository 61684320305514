import React, { useState, useContext, useEffect } from 'react'
import Strings from '../../../../constants/Strings/index'
import StoreContext from '../../../../providers/StoreContext'
import LabeledSlider from '../../../../components/RangeSlider/LabeledSlider'
import debounce from 'lodash.debounce'
import { devicesEnergyAnswersChanged } from '../../../../store/actions'
import Heading from '../../../../components/Heading/Heading'
import ButtonList from '../../../../components/new-components/ButtonList/ButtonList'
import Question from '../../../../components/new-components/Question/Question'
import OptionButtons from '../../../../components/new-components/OptionButtons/OptionButtons'
import CheckedButtons from '../../../../components/new-components/CheckedButtons/CheckedButtons.js'

const DevicesEnergySection = ({ setDisableButton }) => {
  const {
    state: {
      homeConsumption: { devicesEnergy },
    },
    dispatch,
  } = useContext(StoreContext)

  const {
    HOME_CONSUMPTION: {
      SECTIONS: {
        DEVICES_ENERGY: {
          TITLE,
          SUBTITLE,
          QUESTIONS: { NUMBER_OF_SCREENS, HAVE_RENEWABLE_ENERGY, TYPE_OF_RENEWABLE_ENERGY, ENERGY_PERCENTAGE },
        },
      },
    },
  } = Strings

  const qHaveRenewableEnergyOptions = [...Object.values(HAVE_RENEWABLE_ENERGY.OPTIONS)]
  const qTypeOfRenewableEnergyOptions = TYPE_OF_RENEWABLE_ENERGY.OPTIONS
  const [qTypeOfRenewableEnergyOptionsSelected, setqTypeOfRenewableEnergyOptionsSelected] = useState([
    ...devicesEnergy.qTypeOfRenewableEnergy,
  ])

  const [answersState, setAnswersState] = useState({ ...devicesEnergy })

  useEffect(() => {
    const element = document.querySelector('.devices-energy-section__content')
    element.classList.add('devices-energy-section__fade-in')
  }, [])

  useEffect(() => {
    disabledNextButton()
    devicesEnergyAnswersChanged(answersState, dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersState])

  const disabledNextButton = () => {
    let disabled = false
    if (
      answersState.qNumberOfScreens === '' ||
      answersState.qHaveRenewableEnergy !== HAVE_RENEWABLE_ENERGY.OPTIONS.NO.value
    ) {
      if (!answersState.qTypeOfRenewableEnergy.length) {
        disabled = true
      }

      if (answersState.qEnergyPercentage === 0) {
        disabled = true
      }

      if (answersState.qNumberOfScreens === '') {
        disabled = true
      }
    }
    setDisableButton(disabled)
  }

  useEffect(() => {
    setAnswersState({
      ...answersState,
      qTypeOfRenewableEnergy: qTypeOfRenewableEnergyOptionsSelected,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qTypeOfRenewableEnergyOptionsSelected])

  const handlerSlider = () => (value) => {
    setAnswersState({
      ...answersState,
      qEnergyPercentage: value,
    })
  }

  const handlerOnChange = (e) => {
    const { name, value } = e.currentTarget
    setAnswersState({
      ...answersState,
      [name]: value,
    })
  }

  const ThumbnailDevicesEnergy = (props) => {
    const percentage = props['aria-valuenow']
    return (
      <span {...props} className={`${props.className} devices-energy-section__thumb`}>
        <span className='slider-value devices-energy-section__thumb-value'>
          {`${percentage} ${props['aria-valuetext']}`}
        </span>
        <img className='devices-energy-section__thumb-img' src='/assets/green-energy.svg' alt='days of time off' />
      </span>
    )
  }

  const ContentYes = () => {
    return (
      <div className='devices-energy-section__sub-content'>
        <Question title={TYPE_OF_RENEWABLE_ENERGY.TITLE}>
          <CheckedButtons
            row
            selectedFields={{
              fields: qTypeOfRenewableEnergyOptionsSelected,
              setFields: setqTypeOfRenewableEnergyOptionsSelected,
            }}
            labels={qTypeOfRenewableEnergyOptions}
            limitedWidth={true}
          ></CheckedButtons>
        </Question>
        <Question title={ENERGY_PERCENTAGE.TITLE}>
          <LabeledSlider
            value={answersState.qEnergyPercentage}
            defaultValue={0}
            min={0}
            max={100}
            percentages
            suffix='%'
            stepThreshold={{
              treshold: 1,
              step: 5,
            }}
            ThumbComponent={ThumbnailDevicesEnergy}
            onChange={debounce(handlerSlider(), 100)}
          />
        </Question>
      </div>
    )
  }

  return (
    <div className='devices-energy-section__content'>
      <Heading addClass='devices-energy-section__title' level='h1'>
        {TITLE}
      </Heading>
      <p className='devices-energy-section__description'>{SUBTITLE}</p>

      <form id='devicesEnergyForm' className='devices-energy-section__form'>
        <Question title={NUMBER_OF_SCREENS.TITLE}>
          <ButtonList
            name='qNumberOfScreens'
            counter={NUMBER_OF_SCREENS.OPTIONS.COUNTER_OPTIONS}
            valueSelected={answersState.qNumberOfScreens}
            handlerValueSelected={handlerOnChange}
            showInitValue={false}
            initText={NUMBER_OF_SCREENS.OPTIONS.NO_DAYS}
          ></ButtonList>
        </Question>

        <Question title={HAVE_RENEWABLE_ENERGY.TITLE} description={HAVE_RENEWABLE_ENERGY.DESCRIPTION}>
          <OptionButtons
            name='qHaveRenewableEnergy'
            row
            value={answersState.qHaveRenewableEnergy}
            options={qHaveRenewableEnergyOptions}
            handlerOnChange={handlerOnChange}
          />
        </Question>
        {answersState.qHaveRenewableEnergy === HAVE_RENEWABLE_ENERGY.OPTIONS.YES.value && <ContentYes />}
      </form>
    </div>
  )
}

export default DevicesEnergySection
