import React from 'react'

const WarningIcon = ({ pathFill }) => (
  <svg width='50' height='49' viewBox='0 0 50 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <ellipse cx='25.2066' cy='24.5' rx='24.3199' ry='24.5' fill='#FF9696' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.8441 29.2131C23.7415 29.2131 22.8477 28.3193 22.8477 27.2167V14.9964C22.8477 13.8938 23.7415 13 24.8441 13C25.9467 13 26.8405 13.8938 26.8405 14.9964V27.2167C26.8405 28.3193 25.9467 29.2131 24.8441 29.2131ZM24.8441 36C23.7415 36 22.8477 35.1062 22.8477 34.0036V33.8489C22.8477 32.7463 23.7415 31.8525 24.8441 31.8525C25.9467 31.8525 26.8405 32.7463 26.8405 33.8489V34.0036C26.8405 35.1062 25.9467 36 24.8441 36Z'
      fill={pathFill}
    />
  </svg>
)

WarningIcon.defaultProps = {
  pathFill: 'white',
}

export default WarningIcon
