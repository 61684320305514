/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Modal from 'react-modal'
import './Modal.scss'

Modal.setAppElement('#root')

export const WelcomeModal = ({ title }) => {
  const [modalIsOpen, setIsOpen] = React.useState(true)
  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <div>
      <Modal
        shouldCloseOnOverlayClick={false}
        portalClassName='modal-portal'
        onRequestClose={closeModal}
        isOpen={modalIsOpen}
        className='Content'
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '1000',
          },
        }}
      >
        <div className='whiteBoard'>
          <h1 className='whiteBoard__title'>{title}</h1>
          <div className='whiteBoard__text'>
            The goal of this app is to estimate your home office and commuting carbon footprint. By doing so, you are
            helping to measure Globant's Carbon Footprint.
            <p className='whiteBoard__text--bold'>
              While answering your questions, please bear in mind your most common working scenario.
            </p>
          </div>

          <button className='modal-button' onClick={closeModal}>
            Let's go!
          </button>
          <p className='whiteBoard__text--cursive'>
            If you filled out My Footprint previously, you will find your last answers preloaded.
          </p>
        </div>
      </Modal>
    </div>
  )
}

WelcomeModal.defaultProps = {
  title: 'Let’s measure your carbon footprint!',
}
