import React from 'react'

const BusIcon = () => (
  <svg viewBox='0 0 78 40' width='78' height='40' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      strokeWidth='0.1'
      d='M76 19.026H56V2.003h10.54L76 13.098v5.928zm0 13.018h-4.08c-.583-3.827-4.153-6.456-7.976-5.873-3.027.463-5.402 2.842-5.864 5.873H19.92c-.583-3.827-4.153-6.456-7.975-5.873-3.028.463-5.403 2.842-5.865 5.873H2V21.029h74v11.015zm-11 6.008c-2.762 0-5-2.24-5-5.007 0-2.765 2.238-5.006 5-5.006s5 2.24 5 5.006-2.238 5.007-5 5.007zm-52 0c-2.761 0-5-2.24-5-5.007 0-2.765 2.239-5.006 5-5.006s5 2.24 5 5.006-2.239 5.007-5 5.007zM2 3.004c0-.553.448-1.001 1-1.001h15v17.023H2V3.004zm18 16.022h16V2.003H20v17.023zm18 0h16V2.003H38v17.023zM67.76.35C67.57.128 67.292 0 67 0H3C1.343 0 0 1.345 0 3.004v30.041c0 .553.448 1.002 1 1.002h5.08c.583 3.826 4.153 6.456 7.975 5.872 3.028-.462 5.403-2.841 5.865-5.872h38.16c.583 3.826 4.153 6.456 7.976 5.872 3.027-.462 5.402-2.841 5.864-5.872H77c.553 0 1-.449 1-1.002V12.727c0-.238-.085-.47-.24-.65L67.76.35z'
    />
  </svg>
)

export default BusIcon
