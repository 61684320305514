import React from 'react'

const SadFaceIcon = () => (
  <svg width='60px' height='60px' viewBox='0 0 60 60' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <g id='BeKind_Login-Error' transform='translate(-611.000000, -227.000000)' fill='#FFFFFF' fillRule='nonzero'>
        <g id='Group-11' transform='translate(611.000000, 227.000000)'>
          <path
            d='M30,0 C13.45875,0 0,13.45875 0,30 C0,46.54125 13.45875,60 30,60 C46.54125,60 60,46.54125 60,30 C60,13.45875 46.54125,0 30,0 Z M30,56.25 C15.52875,56.25 3.75,44.475 3.75,30 C3.75,15.525 15.52875,3.75 30,3.75 C44.47125,3.75 56.25,15.525 56.25,30 C56.25,44.475 44.47125,56.25 30,56.25 Z'
            id='Shape'
          ></path>
          <path
            d='M46.125,22 C45.09,22 44.25,22.7168 44.25,23.6 C44.25,25.3632 42.57,26.8 40.5,26.8 C38.43,26.8 36.75,25.3632 36.75,23.6 C36.75,22.7168 35.91,22 34.875,22 C33.84,22 33,22.7168 33,23.6 C33,27.1296 36.36375,30 40.5,30 C44.63625,30 48,27.1296 48,23.6 C48,22.7168 47.16,22 46.125,22 Z'
            id='Path'
          ></path>
          <path
            d='M25.125,22 C24.09,22 23.25,22.7168 23.25,23.6 C23.25,25.3632 21.57,26.8 19.5,26.8 C17.43,26.8 15.75,25.3632 15.75,23.6 C15.75,22.7168 14.91,22 13.875,22 C12.84,22 12,22.7168 12,23.6 C12,27.1296 15.36375,30 19.5,30 C23.63625,30 27,27.1296 27,23.6 C27,22.7168 26.16,22 25.125,22 Z'
            id='Path'
          ></path>
          <path
            d='M40.0833333,42 L20.9166667,42 C19.8586667,42 19,42.672 19,43.5 C19,44.328 19.8586667,45 20.9166667,45 L40.0833333,45 C41.1413333,45 42,44.328 42,43.5 C42,42.672 41.1413333,42 40.0833333,42 Z'
            id='Path'
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default SadFaceIcon
