import React, { useState, useContext, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Jumbotron from '../../components/Jumbotron/Jumbotron'
import SelectionSection from '../../components/SelectionSection/SelectionSection'
import Centering from '../../layout/Centering/Centering'
import Strings from '../../constants/Strings'
import StoreContext from '../../providers/StoreContext'
import { collectionOfVehiclesChange } from '../../store/actions'

import './Selection.scss'

const TOTAL_VEHICLES_ALLOWED = 3

const Selection = () => {
  const {
    state: { vehiclesSelected, vehiclesSelectedData },
    dispatch,
  } = useContext(StoreContext)
  const userResponse = JSON.parse(localStorage.getItem('userResponse'))
  const history = useHistory()
  const [isDisableNextButton, setIsDisableNextButton] = useState(true)
  const [isLimitExceeded, setIsLimitExceeded] = useState(false)

  const handleNextButton = () => {
    // collectionOfVehiclesChange(selection, dispatch)
    const location = {
      index: 0,
      pathname: `/trips/${vehiclesSelected[0]}`,
    }
    // weeklyJourneysMadeDisabled(vehiclesSelected.length - 1, 0, dispatch)
    history.push(location.pathname)
  }

  const handleItemsSelected = (vehiclesSelected) => {
    if (vehiclesSelected.length > 0) {
      setIsDisableNextButton(false)
    }
    collectionOfVehiclesChange(vehiclesSelected, dispatch)
  }

  const handleBackButtonClick = () => history.goBack()

  // useEffect(() => {
  //   const totalOfVehiclesSelected = vehiclesSelected.length

  //   setIsLimitExceeded(totalOfVehiclesSelected === TOTAL_VEHICLES_ALLOWED ? true : false)
  //   setIsDisableNextButton(totalOfVehiclesSelected === 0 ? true : false)
  //   if(vehiclesSelectedData.length === 0) {
  //     collectionOfVehiclesChange(userResponse.vehiclesSelected, dispatch)
  //   }
  // }, [vehiclesSelected])

  useMemo(() => {
    const totalOfVehiclesSelected = vehiclesSelected.length
    setIsLimitExceeded(totalOfVehiclesSelected === TOTAL_VEHICLES_ALLOWED ? true : false)
    setIsDisableNextButton(totalOfVehiclesSelected === 0 ? true : false)
  }, [vehiclesSelected])

  useEffect(() => {
    if (vehiclesSelectedData.length === 0 && userResponse.vehiclesSelected.length > 0) {
      collectionOfVehiclesChange(userResponse.vehiclesSelected, dispatch)
    }
  }, [vehiclesSelectedData])

  return (
    <Centering
      backFooterButton={{
        handleOnClick: handleBackButtonClick,
      }}
      nextFooterButton={{
        handleOnClick: handleNextButton,
        disable: isDisableNextButton,
      }}
    >
      <Jumbotron addClass='selection--jumbotron' title={Strings.SELECTION_TITLE}>
        {Strings.SELECTION_DESCRIPTION_TEXT}
      </Jumbotron>
      <SelectionSection
        onHandleSelection={handleItemsSelected}
        iconsSelected={vehiclesSelected}
        limitExceeded={isLimitExceeded}
      />
    </Centering>
  )
}

export default Selection
