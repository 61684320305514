import React from 'react'
import { DefaultToast } from 'react-toast-notifications'

import './Notifications.scss'

function Notifications({ children, ...props }) {
  return (
    <DefaultToast {...props}>
      <div className='notifications'>{children}</div>
    </DefaultToast>
  )
}

export default Notifications
