import React from 'react'

/**
 * @see https://www.iconfinder.com/icons/4213446/faq_help_mark_question_icon
 */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
const ModalResultInfoIcon = ({ pathFill }) => (
  <svg margin='10' width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9 0C4.02891 0 0 4.02891 0 9C0 13.9711 4.02891 18 9 18C13.9711 18 18 13.9711 18 9C18 4.02891 13.9711 0 9 0ZM9 14.0625C8.36719 14.0625 7.875 13.5703 7.875 12.9375C7.875 12.3047 8.33555 11.8125 9 11.8125C9.60117 11.8125 10.125 12.3047 10.125 12.9375C10.125 13.5703 9.60117 14.0625 9 14.0625ZM11.4293 9.07031L9.84375 10.0547V10.125C9.84375 10.582 9.45703 10.9688 9 10.9688C8.54297 10.9688 8.15625 10.582 8.15625 10.125V9.5625C8.15625 9.28125 8.29688 9 8.57812 8.82422L10.582 7.62891C10.8281 7.48828 10.9688 7.24219 10.9688 6.96094C10.9688 6.53906 10.5855 6.1875 10.1637 6.1875H8.36719C7.91367 6.1875 7.59375 6.53906 7.59375 6.96094C7.59375 7.41797 7.20703 7.80469 6.75 7.80469C6.29297 7.80469 5.90625 7.41797 5.90625 6.96094C5.90625 5.58984 6.99609 4.5 8.33555 4.5H10.132C11.5664 4.5 12.6562 5.58984 12.6562 6.96094C12.6562 7.80469 12.1992 8.61328 11.4293 9.07031Z'
      fill={pathFill}
    />
  </svg>
)

ModalResultInfoIcon.defaultProps = {
  pathFill: '#86FFBD',
}

export default ModalResultInfoIcon
