import React from 'react'

const SunIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='41'
    height='42'
    stroke='#fff'
    fill='none'
    strokeWidth='2.5'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='20.315' cy='21.296' r='8.942' fill='none' />
    <path d='M20.315 40v-5.055m0-27.89V2M6.88 34.435l3.574-3.574m19.722-19.722l3.574-3.574M1.315 21H6.37m27.89 0h5.055M6.88 7.565l3.574 3.574m19.722 19.722l3.574 3.574' />
  </svg>
)

export default SunIcon
